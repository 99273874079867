import * as React from 'react';
import "./instagramItem.scss";

interface IInstagramItemProps {
    title: string
    url: string
    mediaType: string
    mods ?: string
    isActive?: boolean
};

export const InstagramItem: React.FC<IInstagramItemProps> = ({ title, mediaType, url, mods, isActive }) => {

    const video = React.useRef(null);
    const isPlaying = React.useRef(false);

    React.useEffect(() => {

        if(video.current != null && isActive) {
            isPlaying.current = true;
            video.current.play();

        }else if(video.current != null && !isActive) {
            isPlaying.current = false;
            video.current.pause();
        }

    }, [isActive]);

    const checkMediaType = () => {

        if(mediaType == "VIDEO"){
            return outputVideo();
        }else {
            return outputImg();
        }

    }

    const outputVideo = () => {
        return <React.Fragment>
            <video ref={video} muted playsInline loop><source src={url} type="video/mp4" /></video>
            <span className="sr-only">{title}</span>
        </React.Fragment>
    }

    const outputImg = () => {
        return <span className="sr-only">{title}</span>
    }

    if(!url || !title) return <React.Fragment></React.Fragment>
    return <div className={"instagram-item" + (mods ? ` ${mods}` : "")}>
        <a
            className="instagram-item__inner"
            style={{ backgroundImage: `url(${url})` }}
            data-type={mediaType}
            href={url ? url : "#"}
            tabIndex={isActive ? 0 : -1}
        >
            {checkMediaType()}
        </a>
    </div>

}