import * as React from 'react';
import "./featureLogoRow.scss";
import { Icon, Icons } from "../icon/icon";
import { SiteDataContext } from "../../contexts/siteData";

export const FeatureLogoRow: React.FC<{
    contentItems: {
        sectionIntro: {
            title: string
            content: string
        }
    }[]
}> = ({ contentItems }) => {

    if(!contentItems) return <React.Fragment></React.Fragment>;
    if(contentItems.length == 0) return <React.Fragment></React.Fragment>;

    const { state } = React.useContext(SiteDataContext);

    return <div className="feature-logo-row">
        <div className="feature-logo-row__wrapper">
            <div className="feature-logo-row__inner">
                <h3 >{contentItems[0].sectionIntro.title}</h3>
                <p>{contentItems[0].sectionIntro.content}</p>
                <div className="feature-logo-row__logo">
                    <Icon icon={Icons[`UNHR${state.language.initials}`]} />
                </div>
            </div>
        </div>
    </div>

}