import * as React from 'react';

interface IHeaderBtnProps {
    id: string
    aria: {
        controls: string,
        popup: string,
        label: string
    }
    classList: string
    clickCallback: () => void
    children?: React.ReactNode
}

export const HeaderBtn: React.FC<IHeaderBtnProps> = ({ id, aria, classList, clickCallback, children }) => {

    return <button
        aria-controls={aria.controls}
        id={id}
        role="button"
        aria-haspopup={Boolean(aria.popup)}
        aria-label={aria.label}
        className={classList}
        onClick={() => { clickCallback() }}
    >
        {children ? children : null}
    </button>

}