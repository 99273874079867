import * as React from 'react';
import "./galleryItem.scss";
import { BgImg } from "../bgImg/bgImg";

export interface IGalleryItemProps {
    img: {
        urls: string[]
        title: string
    }
    title: string
    content: string
};

export const GalleryItem: React.FC<IGalleryItemProps> = ({ img, title, content }) => {

    if(!img || !title || !content) return <React.Fragment></React.Fragment>
    return <div className="gallery-item">
        <div className="gallery-item__inner">
            <div className="gallery-item__img">
                <BgImg {...img} mods="bg-img--absolute" />
            </div>
            <div className="gallery-item__content">
                <h4>{title}</h4>
                <p>{content}</p>
            </div>
        </div>
    </div>

}