import * as React from 'react';
import "./imgHero.scss";
import utilsUI from "../../core/utilsUI";
import { BgImg } from '../bgImg/bgImg';

interface IImgHeroProps {
    contentItems: {
        img: {
            urls: string[]
        }
    }[]
};

export const ImageHero: React.FC<IImgHeroProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if (contentItems.length < 0 || contentItems[0]?.img?.urls?.length == 0) return <React.Fragment></React.Fragment>

    var imgSrc = utilsUI.checkImgSrc(contentItems[0].img.urls[0]);
    return (
        // <div className="component-wrapper">
        <div className="img-hero">
            {/* <img
                src={imgSrc}
            /> */}
            <BgImg {...contentItems[0].img} />
        </div>
        // </div>
    );
}