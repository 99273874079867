import gql from "graphql-tag";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const UPDATES_PAGE = gql`
query UpdatesComponents($id: ID, $status: Status){
  pageUpdates(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureArticle {
      contentItems {
        ... imgArticleCardFragment
      }
    }
    updateCardGrid: gridSettings {
      loadMore
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
    
}
}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
`;

export const UPDATES_META_DATA = gql`
query UpdatesMetaData($id: ID, $status: Status){
  page: pageUpdates(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }

 }
}
`;