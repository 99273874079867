import gql from "graphql-tag";
import { FEATURE_IMG_FRAGMENT } from "./feature-img-fragment";
import { IFRAME_FRAGMENT } from "./iframe-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const RIGHT_TO_LOVE_PAGE = gql`
query RightToLoveComponents($id: ID, $status: Status){
  pageRightToLove(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureImg {
      contentItems {
        ... featureImgFragment
      }
    }
    iframe {
      contentItems {
       ... iframeFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${FEATURE_IMG_FRAGMENT}
${IFRAME_FRAGMENT}
`;

export const RIGHT_TO_LOVE_META_DATA = gql`
query RightToLoveMetaData($id: ID, $status: Status){
  page : pageRightToLove(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;

