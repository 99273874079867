import * as React from 'react';
import "./iconBlockRow.scss";
import { IconBlock } from "../iconBlock/iconBlock";
import { SectionIntro, ISectionIntroProps } from "../sectionIntro/sectionIntro";

interface IIconBlockRowProps {
    contentItems: {
        content?: string,
        title: string,
        items: {
            bgImg:  {urls:string[]},
            content: string
        }[]
    }[]
};

export const IconBlockRow: React.FC<IIconBlockRowProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if (!contentItems.length) return <React.Fragment></React.Fragment>

    const obj = contentItems[0];

    return <div className="icon-block-row">
        <div className="icon-block-row__wrapper">
            {obj.title || obj.content ? <SectionIntro title={obj.title} content={obj.content} removeTopSpacing />: null}
            <div className="icon-block-row__inner">
                {obj.items.map((item, index) => {
                    if(item != null) {
                        return <div className="icon-block-row__item" key={index}>
                            <IconBlock
                                content={item.content} bgImg={ item.bgImg?.urls[0]}
                            />
                        </div>
                    }
                })}
            </div>
        </div>
    </div>

}