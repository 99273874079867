export const FooterTitles = {
    english: {
        title: "UN Free & Equal is an initiative of the © United Nations Human Rights Office.",
        logoUrl: "https://www.standup4humanrights.org/en/",
        humanRightsUrl: "https://www.ohchr.org/en/ohchr_homepage"
    },
    russian: {
        title: "СВОБОДНЫЕ И РАВНЫЕ — ИНИЦИАТИВА © УПРАВЛЕНИЯ ООН ПО ПРАВАМ ЧЕЛОВЕКА.",
        logoUrl: "https://www.standup4humanrights.org/ru/",
        humanRightsUrl: "https://www.ohchr.org/ru/ohchr_home"
    },
    chinese: {
        title: "联合国自由与平等运动是©联合国人权办的一项举措。",
        logoUrl: "https://www.standup4humanrights.org/zh/",
        humanRightsUrl: "https://www.ohchr.org/zh/ohchr_home"
    },
    arabic: {
        title: "مبادرة الأمم المتحدة ’’أحرار متساوون‘‘، هي مبادرة لمفوضية © الأمم المتحدة لحقوق الإنسان",
        logoUrl: "https://www.standup4humanrights.org/ar/",
        humanRightsUrl: "https://www.ohchr.org/ar/ohchr_home"
    },
    french: {
        title: "Libres et égaux est une initiative du © Haut-Commissariat des Nations Unies aux droits de l'homme.",
        logoUrl: "https://www.standup4humanrights.org/fr/",
        humanRightsUrl: "https://www.ohchr.org/fr/ohchr_home"
    },
    portuguese: {
        title: "A campanha da ONU Livres & iguais é uma iniciativa do © Escritório do Alto Comissariado das Nações Unidas para os Direitos Humanos. ",
        logoUrl: "https://www.standup4humanrights.org/en/",
        humanRightsUrl: "https://www.ohchr.org/en/ohchr_homepage"
    },
    spanish: {
        title: "Libres e iguales es una iniciativa de la © Oficina de las Naciones Unidas para los derechos humanos.",
        logoUrl: "https://www.standup4humanrights.org/es/",
        humanRightsUrl: "https://www.ohchr.org/es/ohchr_home"
    }
};

exports = {
    FooterTitles
};