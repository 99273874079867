import gql from "graphql-tag";
import { EXTERNAL_LINK_CARD_GROUP_FRAGMENT } from "./external-link-card-group";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const RESOURCES_PAGE = gql`
query ResourcesComponents($id: ID, $status: Status){
  pageResources(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    contentBlocks:items {
      contentItems {
        ... externalLinkCardGroupFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}

${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${EXTERNAL_LINK_CARD_GROUP_FRAGMENT}
`;

export const RESOURCES_META_DATA = gql`
query ResourcesMetaData($id: ID, $status: Status){
  page: pageResources(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;