import { gql } from "@apollo/client";

export const GET_FOOTER_MENU = gql`
  query GetFooterMenu ($langcode: String) {
    menu(name: FOOTER, langcode: $langcode) {
      items {
        title
        url
      }
    }
  }
`;

