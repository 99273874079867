import * as React from 'react';
import "./featureCtaRow.scss";
import { SiteDataContext } from "../../contexts/siteData";

interface IFeatureCtaRowProps {
    displaySubscription?: boolean
};

export const FeatureCtaRow: React.FC<IFeatureCtaRowProps> = ({ displaySubscription }) => {

    if (!displaySubscription) return <React.Fragment></React.Fragment>;

    const { state } = React.useContext(SiteDataContext);

    //TODO!!!
    let data = {
        "subscription": [
            {
                "__typename": "subscription",
                "title": "ابقوا على اطلاع بالحملة واشتركوا في نشرتنا البريدية",
                "blank": true,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPupf9",
                    "text": "اشتركوا "
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "ar"
                }
            },
            {
                "__typename": "subscription",
                "title": "了解运动的最新情况，请订阅我们的简报",
                "blank": true,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPuo6P",
                    "text": "订阅"
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "zh"
                }
            },
            {
                "__typename": "subscription",
                "title": "Acompanhe a campanha e assine nosso boletim informativo",
                "blank": true,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPupoP",
                    "text": "Inscreva-se"
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "pt"
                }
            },
            {
                "__typename": "subscription",
                "title": "Чтобы быть в курсе событий кампании, подпишитесь на нашу рассылку",
                "blank": true,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPupZb",
                    "text": "Подписаться"
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "ru"
                }
            },
            {
                "__typename": "subscription",
                "title": "Mantente al día con la campaña y suscríbete a nuestro boletín",
                "blank": true,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPupIz",
                    "text": "Suscríbete"
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "es"
                }
            },
            {
                "__typename": "subscription",
                "title": "Tenez-vous à jour de la campagne et abonnez-vous à notre newsletter",
                "blank": false,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPupzb",
                    "text": "S'abonner"
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "fr"
                }
            },
            {
                "__typename": "subscription",
                "title": "Stay up to date with the campaign and subscribe to our newsletter",
                "blank": true,
                "externalLink": {
                    "__typename": "LinkField",
                    "url": "http://eepurl.com/cPtBuj",
                    "text": "Subscribe"
                },
                "localization": {
                    "__typename": "LocalizationPart",
                    "culture": "en"
                }
            }
        ]
    };

    const subcription = data?.subscription?.find(s => s.localization?.culture == state.language.initials) ?? data.subscription.find(s => s.localization?.culture == "en");
    if (!subcription) return <React.Fragment></React.Fragment>;

    const popupCenter = (url:string) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
    
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
        const w = Math.max(width * 0.5, 300);
        const h = Math.max(height * 0.7, 600);
    
        const systemZoom = 1;//width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, "", 
          `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )
    
        if (window.focus) newWindow.focus();
    }

    const outputLink = () => {

        return <a className="btn" onClick={() => popupCenter(subcription.externalLink?.url ??"http://eepurl.com/hSE3_T")}>{subcription.externalLink?.text?? "subscribe"}</a>
    }

    return <div className="component-wrapper">
        <div className="component-wrapper__inner">
            <div className="feature-cta-row">
                <div className="feature-cta-row__col">
                    <h3 className="h2">{subcription.title}</h3>
                    <div className="feature-cta-row__btn">
                        {outputLink()}
                    </div>
                </div>
            </div>
        </div>
    </div>
}