import * as React from 'react';
import "./iconBlock.scss";
import utilsUI from "../../core/utilsUI";

export const IconBlock: React.FC<{
    bgImg: string,
    content: string
}> = ({ bgImg, content }) => {

    if(!bgImg || !content) return <React.Fragment></React.Fragment>
    return <div className="icon-block">
        <div className="icon-block__icon" style={{ backgroundImage: `url(${utilsUI.checkImgSrc(bgImg)})`}}>
            <span className="sr-only">Block icon</span>
        </div>
        <div className="icon-block__content">
            <p>{content}</p>
        </div>
    </div>
}