import * as React from 'react';
import "./nav.scss";
import { NavItem } from "../navItem/navItem";
import { SiteDataContext } from "../../contexts/siteData";
import { useLocation } from 'react-router-dom';
import utilsUI from "../../core/utilsUI";

export const Nav: React.FC<{pageMenu: any, langItem: any}> = ({pageMenu, langItem}) => {

    if (!pageMenu) return null;

    const { state } = React.useContext(SiteDataContext);

    const location = useLocation();
    const urlPartitions = utilsUI.getUrlPartitions(location.pathname);

    const regionData = state.language;

    // console.log(pageMenu.pageMenu);

    const nav = pageMenu.map(mi => {
        return {
            "displayText": mi.title,
            "content": {
                "contentItemIds": [
                    (Math.random() + 1).toString(36).substring(7)
                ],
                "contentItems": [
                    {
                        "__typename": "pageWhatWeDo",
                        "path": `/${regionData.initials}${mi.url}`
                    }
                ]
            },
            "subNav": {
                "contentItems":         
                    mi.children?.map(chi => {

                        let url = chi.url.replace(mi.url, "");

                        return {
                            "displayText": chi.title,
                            "content": {
                                "contentItemIds": [
                                    "n/a"
                                ],
                                "contentItems": [
                                    {
                                        "path": `/${regionData.initials}${url}`
                                    }
                                ]
                            }
                        };
                    })
            }
        }
    });

    if(nav){
        return <nav className="nav">
            <ul 
                id="main-navigation" 
                role="menu" 
                aria-labelledby="main-nav-btn"
            >
                {nav.map((item, index) => {
                    let url = utilsUI.cleanPath(item.content?.contentItems[0].path);
                    return <NavItem
                        key={index}
                        id={item.content?.contentItemIds[0]}
                        url={`/${url}`}
                        title={item['displayText']}
                        nav={item.subNav?.contentItems}
                        parent={`/${url}`}
                        isCurrent={urlPartitions[urlPartitions.length - 1] == url || urlPartitions.includes(url) ? true : false}
                    />
                })}
                <li className={"nav-item"} role="none" style={{ width: "auto"}}>
                    {langItem}
                </li>
            </ul>
        </nav>
    }

    return <React.Fragment></React.Fragment>

}