import * as React from 'react';
import { Helmet } from 'react-helmet';
import { SiteDataContext } from "../contexts/siteData";
import { SiteTitles } from "../language/head";
import utilsUI from "../core/utilsUI";

interface ImetaData {
    attributes: {
        content: string
        name: string
        __typename: string
    }
    __typename: string
}

export const Head: React.FC<{ contentItemId?: string, contentType: string, baseURL: string, pageMetaData: DrupalPageMetadata, pageMetaTags: Array<ImetaData>}> = ({ contentItemId, contentType, baseURL, pageMetaData, pageMetaTags }) => {

    const { state } = React.useContext(SiteDataContext);

    let contentQuery = {};
    let metaTitle: string;
    let metaDescription: string;

    contentQuery = {
        "img": {
            "urls": null //???
        },
        "title": pageMetaData?.title ?? "",
        "content": pageMetaData?.description,
        "keywords": null,
        "label": null,
        "url": pageMetaData?.url
    };
    
    if (!contentItemId && !contentType && contentQuery == null) {
        return <Helmet>
            <title>{`${SiteTitles[state.language.value].notFound.title} | ${SiteTitles[state.language.value]['title']}`}</title>
            <meta name="description" content={`${SiteTitles[state.language.value].notFound.content}`} />
            {state.language.initials == "zh" ? <link rel="preload" media="all" href="/assets/css/chinese.css" as="style" /> : null}
            {state.language.initials == "ar" ? <link rel="preload" media="all" href="/assets/css/arabic.css" as="style" /> : null}
            {state.language.initials == "ru" ? <link rel="preload" media="all" href="/assets/css/russian.css" as="style" /> : null}

            {state.language.initials == "zh" ? <link rel="stylesheet" media="all" href="/assets/css/chinese.css" /> : null}
            {state.language.initials == "ar" ? <link rel="stylesheet" media="all" href="/assets/css/arabic.css" /> : null}
            {state.language.initials == "ru" ? <link rel="stylesheet" media="all" href="/assets/css/russian.css" /> : null}

            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" || state.language.initials == "ru" ? <link rel="preconnect" crossorigin href="https://fonts.gstatic.com" /> : null}
            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" || state.language.initials == "ru" ? <link href="https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap" rel="stylesheet" /> : null}
            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" ? <link rel="preload" as="style" href="/assets/css/latin.css" /> : null}
            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" ? <link rel="stylesheet" media="all" href="/assets/css/latin.css" /> : null}
        </Helmet>
    }

    if (contentQuery == null) return null;
    let curPath = utilsUI.cleanPath(window.location.pathname);
    curPath = curPath[0] == "/" ? curPath.substr(1, curPath.length) : curPath;

    if (contentQuery) {
        metaTitle = pageMetaTags?.[0].attributes.content;
        metaDescription = pageMetaTags?.[1].attributes.content;
    }

    return (
        <Helmet>
            <title>{`${contentQuery['title']} | ${metaTitle}`}</title>
            
            <link rel="alternate" hrefLang="x-default" href={`${baseURL}/`} />
            <link rel="alternate" hrefLang="en-US" href={`${baseURL}/${curPath}`} />
            <link rel="alternate" hrefLang="es-ES" href={`${baseURL}/es/${curPath}`} />
            <link rel="alternate" hrefLang="fr-FR" href={`${baseURL}/fr/${curPath}`} />
            <link rel="alternate" hrefLang="pt-PT" href={`${baseURL}/pt/${curPath}`} />
            <link rel="alternate" hrefLang="ru-RU" href={`${baseURL}/ru/${curPath}`} />
            <link rel="alternate" hrefLang="zh-Hans" href={`${baseURL}/zh/${curPath}`} />
            <link rel="alternate" hrefLang="ar" href={`${baseURL}/ar/${curPath}`} />

            {metaDescription ? <meta name="description" content={metaDescription} /> : null}
            {contentQuery['keywords'] ? <meta name="keywords" content={contentQuery['keywords']} /> : null}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${SiteTitles[state.language.value]['title']} | ${metaTitle}`} />
            <meta property="og:description" content={metaDescription} />
            {!!contentQuery['img'] ? <meta property="og:image" content={contentQuery['img']['urls']} /> : null}
            
            {contentQuery['content'] ? <meta name="twitter:card" content={contentQuery['content']} /> : null}
            <meta name="twitter:title" content={`${SiteTitles[state.language.value]['title']} | ${metaTitle}`} />
            {metaDescription ? <meta name="twitter:description" content={metaDescription} /> : null}
            <meta name="twitter:url" content={window.location.href} />
            {!!contentQuery['img'] ? <meta name="twitter:image" content={contentQuery['img']['urls']} /> : null}
            {state.language.initials == "zh" ? <link rel="preload" media="all" as="style" href="/assets/css/chinese.css" /> : null}
            {state.language.initials == "ar" ? <link rel="preload" media="all" as="style" href="/assets/css/arabic.css" /> : null}
            {state.language.initials == "ru" ? <link rel="preload" media="all" as="style" href="/assets/css/russian.css" /> : null}

            {state.language.initials == "zh" ? <link rel="stylesheet" media="all" href="/assets/css/chinese.css" /> : null}
            {state.language.initials == "ar" ? <link rel="stylesheet" media="all" href="/assets/css/arabic.css" /> : null}
            {state.language.initials == "ru" ? <link rel="stylesheet" media="all" href="/assets/css/russian.css" /> : null}

            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" || state.language.initials == "ru" ? <link rel="preconnect" crossorigin href="https://fonts.gstatic.com" /> : null}
            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" || state.language.initials == "ru" ? <link href="https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap" rel="stylesheet" />: null}
            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" ? <link rel="preload" as="style" href="/assets/css/latin.css" /> : null}
            {state.language.initials == "en" || state.language.initials == "fr" || state.language.initials == "pt" || state.language.initials == "es" ? <link rel="stylesheet" media="all" href="/assets/css/latin.css" /> : null}
        </Helmet>
    );
};