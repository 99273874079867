import { Templates } from '../controllers/templates';
import { useQuery } from '@apollo/client';
import { dictionaryManager } from '../core/storageManagers';
import utilsUI from "../core/utilsUI";
import { contentState } from '../contexts/siteData';

export const useContentQuery = (contentItemId, contentType) => {

    let pageData = {};


    if (contentItemId && contentType) {

        let query = Templates[contentType]?.query?.metaData;

        if (!query) {
            console.log("Cannot find the query for the metadata of the page : ", contentType);
            return null;
        }

        const { loading, data } = useQuery(query, { variables: { id: contentItemId ,status: contentState } });
        if (loading || !data) return null;
        if (!data.page || data.page.length < 1) return null;
        
        const card = data.page[0];

        switch (contentType) {
            case "article":
                pageData['img'] = { urls: utilsUI.checkImgSrc(card.thumbnail?.urls[0]), title: card.tilte };
                pageData['title'] = card.title;
                pageData['content'] = card.content;
                pageData['label'] = card.date;
                pageData['keywords'] = card.keywords;
                pageData['url'] = card.path ? card.path : null;
            break;

            case "campaign":
                pageData['url'] = (card.external?.isExternal ? card.external.url :  card.path )?? null;
                pageData['img'] = { urls: utilsUI.checkImgSrc(card.thumbnail?.urls[0]), title: card.tilte };
                pageData['title'] = card.title;
                pageData['keywords'] = card.keywords;
                pageData['content'] = card.description;
                pageData['isExternal'] = card.external?.isExternal;
            break;

            case "factSheet":
                pageData['url'] = card.path ? card.path : null;
                pageData['img'] = { urls: utilsUI.checkImgSrc(card.thumbnail?.urls[0]), title: card.tilte };
                pageData['title'] = card.title;
                pageData['keywords'] = card.keywords;
                pageData['content'] = card.description;
            break;
            
            default:

                pageData['img'] = { urls: utilsUI.checkImgSrc(card.pageMetaData?.thumbnail?.urls[0]), title: card.pageMetaData?.tilte };
                pageData['title'] = card.pageMetaData?.title;
                pageData['content'] = card.pageMetaData?.description;
                pageData['keywords'] = card.pageMetaData?.keywords;
                pageData['label'] = card.pageMetaData?.label;
                pageData['url']= dictionaryManager.getItemById(contentItemId)?.path;
        }

        return pageData;

    }

    return null;

}