import * as React from 'react';
import "./map.scss";
import { Icon, Icons } from "../icon/icon";
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";
import { RegionModal } from "../regionModal/regionModal";
import { useHistory, useLocation } from 'react-router-dom';
import utilsUI from "../../core/utilsUI";

export interface ICountryProps {
    name: string
    subTitle?: string
    content: string
    img: {
        urls: string[]
    }
    locations: {
        termContentItemIds: any[]
    }
};

interface IMapProps {
    contentItems: {
        sectionIntro?: ISectionIntroProps
        popupIconStyle: boolean
        displayRelatedItems: boolean
        regions: {
            contentItems: {
                countries: {
                    contentItems: {
                        content: string
                        country: {
                            contentItems: {
                                locations: {
                                    termContentItemIds: any[]
                                }
                                name: string
                            }[]
                        }
                        img: { urls: string[] }
                        subTitle: string

                    }[]
                }
                region: {
                    contentItems: {
                        name: string
                        value: string
                    }[]
                }
            }[]
        }
    }[]
};

interface IMapBtnProps {
    children?: React.ReactNode
    classItems?: string
    continent: string
    onClickHandler?: () => void
};

const MapBtn: React.FC<IMapBtnProps> = ({ children, classItems, continent, onClickHandler }) => {

    if (!continent) return <React.Fragment></React.Fragment>
    return <button
        role="button"
        className={classItems}
        data-continent={continent}
        onClick={onClickHandler}
    >
        <Icon icon={Icons.Plus} />{children ? children : null}
    </button>
}

export const Map: React.FC<IMapProps> = ({ contentItems }) => {
    const history = useHistory();
    const location = useLocation();
    const regionId = utilsUI.getURLParameter("region");

    const closeModal = () => {
        history.push({ ...history.location, search: null });
    }

    const regionClickHandler = (regionId: string) => {
        history.push({
            pathname: location.pathname,
            search: `?region=${regionId}`
        })
    }

    React.useEffect(() => {

        if (regionId) {
            document.body.classList.add('region-modal-active');
        } else {
            document.body.classList.remove('region-modal-active');
        }

    }, [regionId]);

    if (!contentItems?.length) return null;

    const regions = contentItems[0].regions?.contentItems.map(x => ({
        name: x.region?.contentItems?.[0].name,
        value: x.region?.contentItems?.[0].value,
        countries: x.countries?.contentItems?.map(y => ({
            content: y.content,
            img: y.img,
            subTitle: y.subTitle,
            name: y.country?.contentItems?.[0].name,
            locations: y.country?.contentItems?.[0].locations
        }))
    }));

    if (!regions?.length) return null;

    const selectedRegion = regionId && regions.find(x => x.value === regionId);

    setTimeout(() => {
        var x = document.getElementById("contentBannerId");        
        if (!selectedRegion) {
          x.style.display = "flex";
        } else {
          x.style.display = "none";
        }

        var x = document.getElementById("footerId");        
        if (!selectedRegion) {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }, 100);

    return <div className="map">
        <div className="map__inner">
            {contentItems[0].sectionIntro ? <SectionIntro {...contentItems[0].sectionIntro} removeTopSpacing /> : null}
            <div className="map__wrapper">
                <ul className="map__locations">
                    {regions.map((item, index) => {
                        return (
                            <li key={index}>
                                <MapBtn classItems="map__btn"
                                    onClickHandler={() => !!item.countries?.length && regionClickHandler(item.value)}
                                    continent={item.value}>
                                    {item.name}
                                </MapBtn>
                            </li>
                        );
                    })}
                </ul>
                <div className="map__continents">
                    <Icon icon={Icons.Map} />
                </div>
                <div className="map__info">
                    <Icon icon={Icons.Click} /> Click a region for more information
                </div>
            </div>
        </div>


        {!!selectedRegion && !!selectedRegion.countries?.length && <RegionModal
            id={`TODO-MAP-ID`}
            title={selectedRegion.name}
            countries={selectedRegion.countries}
            modalCallback={closeModal}
            popupIconStyle={contentItems[0].popupIconStyle}
            displayRelatedItems={contentItems[0].displayRelatedItems}
        />}

    </div>

}