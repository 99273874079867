import gql from "graphql-tag";
import { SIMPLE_ACCORDION_GROUP_FRAGMENT } from "./accordion-group-fragment";
import { FEATURE_IMG_FRAGMENT } from "./feature-img-fragment";
import { FIGURES_BLOCK_FRAGMENT } from "./figures-block-fragment";
import { IFRAME_FRAGMENT } from "./iframe-fragment";
import { RELATED_FACT_SHEETS_FRAGMENT } from "./related-fact-sheets-fragment";
import { SIMPLE_CONTENT_BLOCK_FRAGMENT } from "./simple-content-block-fragment";

export const FACT_SHEET_LIST = gql`
query FactSheetList($parameters: String) {
  items: factSheetList(parameters: $parameters) {
    contentItemId
    contentType
    title: displayText
    description
    path
  }
}
`;

export const FACT_SHEET_META_DATA = gql`
query FactSheetMetaData($id: ID, $status: Status) {
  page:factSheet(where: {contentItemId: $id}, status: $status) {
    title: displayText
    description
    path
    keywords
    thumbnail {
      urls
    }
  }
}
`;

export const FACT_SHEET_QUERY = gql`
query FactSheet($slug :String, $status: Status) {
  factSheet(where: {path: $slug}, status: $status){
    contentItemId
    title: displayText
    description
    path
    thumbnail {
      urls
    }
    tags{
      termContentItemIds
    }
    locations {
      termContentItemIds
    }
    toolbar{
      share
      file {
        urls
      }
    }
    htmlContent {
      contentItems {
        ... on content {
          html
        }
      }
    }
    figuresBlock {
      contentItems {
        ... figuresBlockFragment
      }
    }
   
    secondHmlcontent {
      contentItems {
        ... on content {
          html
        }
      }
    }
    
    featureContent {
      contentItems {
        ... simpleContentBlockFragment
      }
    }
    featureImage {
      contentItems {
        ... featureImgFragment
      }
    }
    faqs {
      contentItems {
        ... simpleAccordionGroupFragment
      }
    }
    relatedContent {
      contentItems {
        ... relatedFactSheetsFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
    
  }
}
${IFRAME_FRAGMENT}
${SIMPLE_ACCORDION_GROUP_FRAGMENT}
${SIMPLE_CONTENT_BLOCK_FRAGMENT}
${FIGURES_BLOCK_FRAGMENT}
${FEATURE_IMG_FRAGMENT}
${RELATED_FACT_SHEETS_FRAGMENT}
`;



