import { gql } from "@apollo/client";

export const GET_SOCIAL = gql`
  query GetSocialPlatforms  {
    socialPlatform {
      results {
        nid
      }
    }
  }
`;

