import gql from "graphql-tag";
import { MAP_REGION_FRAGMENT } from "./map-region-fragment";


export const MAP_FRAGMENT = gql`
fragment mapFragment on unfeMap {
  sectionIntro {
    title
    content
  }
  popupIconStyle
  displayRelatedItems
  regions {
    contentItems {
      ...mapRegionFragment
    }
  }
}

${MAP_REGION_FRAGMENT}
`;