import gql from "graphql-tag";
import { CONTENT_INTRO_FRAGMENT } from "./content-intro-fragment";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_GROUP_FRAGMENT } from "./img-group-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { SIMPLE_CONTENT_BLOCK_FRAGMENT } from "./simple-content-block-fragment";

export const HOME_PAGE = gql`
query HomeComponents($id: ID, $status: Status){
  homepage(where: {contentItemId: $id}, status: $status){
    displayText
    contentType
    contentItemId
    homeBanner {
      slides:contentItems {
        ... on Homepagebanner {
          title
        }
      }
    }
    homeFeatureIntro {
      contentItems {
        ... contentIntroFragment
      }
    }
    colourCardRow {
      contentItems {
        ... imgTitleContentGroupFragment       
      }
    }
    homeImgContentCard {
      contentItems {
        ... imgContentCardFragment
        ... imgArticleCardFragment
        ... imgCampaignCardFragment
        ... imgFactSheetCardFragment
      }
    }
    instagramCarousel {
      contentItems {
        ... on instagramContentCarousel {
          account
          title
        }
      }
    }
    subscriptionBlock {
      displaySubscription
    }
  }
}

${IMG_CONTENT_CARD_FRAGMENT}
${IMG_CAMPAIGN_CARD_FRAGMENT}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_FACT_SHEET_CARD_FRAGMENT}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${CONTENT_INTRO_FRAGMENT}
`;

export const HOME_META_DATA = gql`
query HomeMetaData($id: ID, $status: Status){
  page: homepage(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }

 }
}
`;