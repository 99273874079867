import * as React from 'react';
import "./staticEmbed.scss";
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";

interface ISmallCardGridProps {
    contentItems :{
    sectionIntro?: ISectionIntroProps
    embedCode: any,
    desacription?: string
    }[]
};

export const StaticEmbed: React.FC<ISmallCardGridProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length ==0) return <React.Fragment></React.Fragment>
    
    return <div className="component-wrapper">
        {contentItems[0].sectionIntro ? <SectionIntro
            {...contentItems[0].sectionIntro}
            removeTopSpacing
        /> : null}
        <div className="static-embed">
            <div className="static-embed__inner">
                <div className="static-embed__wrapper" dangerouslySetInnerHTML={{ __html: contentItems[0].embedCode }}></div>
            </div>
        </div>
    </div>

}