import gql from "graphql-tag";

export const EXTERNAL_FEATURE_CTA_FRAGMENT = gql`
fragment externalFeatureCtaFragment on externalFeatureCta {
    sectionIntro {
      content
      title
    }
    removeTopSpacing
    removeBottomSpacing
    mods
    link {
      url
      text
    }
    newTab
  }
`;