import gql from "graphql-tag";

export const RELATED_ARTICLES_FRAGMENT = gql `
fragment relatedArticlesFragment on relatedArticles {
  sectionIntro {  
    title
    content
  }
  removeBottomSpacing
  removeTopSpacing
  contentType
  contentItemId
}
`;