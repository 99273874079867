import * as React from 'react';
import "./componentContainer.scss";

export const enum BackgroundColours {
    Blue = "bg-blue",
    Crimson = "bg-red",
    Green = "bg-green",
    Grey = "bg-grey",
    Orange = "bg-orange",
    Purple = "bg-purple",
    Transparent = "bg-transparent",
}

interface IComponentContainerProps {
    children: React.ReactNode
    bg?: BackgroundColours
    removeTopSpacing?: boolean,
    removeBottomSpacing?: boolean,
};

export const ComponentContainer: React.FC<IComponentContainerProps> = ({ children, bg, removeTopSpacing, removeBottomSpacing }) => {
    const spacing = removeBottomSpacing && removeTopSpacing ? "component-container--no-spacing" :
        (removeBottomSpacing ? "component-container--spacing-top" : (removeTopSpacing ? "component-container--spacing-bottom" : ""));

    // const background = bg ? `component-background ${bg}` : "";

    const classNames = ["component-container", spacing
        // , background
    ].filter(x => !!x).join(" ");

    return (
        <div className={classNames}>
            {!!bg ?
                <div className={`component-background ${bg}`}>
                    {children}
                </div>
                :
                children
            }
        </div>
    );
};