import { gql } from "@apollo/client";

export const GET_MAIN_MENU = gql`
  query GetMainMenu ($langcode: String) {
    menu(name: MAIN, langcode: $langcode) {
      id
      items {
        title
        url
        children {
          title
          url
        }
      }
    }
  }
`;

