import { gql } from "@apollo/client";

export const GET_UPDATES = gql`
query GetAllUpdates($langcode: String) {
  allUpdates(filter: {langcode: $langcode}) {
    results {
      image
      date
      tags
      title
      description
      url
    }
  }
}
`;

