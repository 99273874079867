import * as React from 'react';
import { IDownloadCardProps } from "../downloadCard/downloadCard";
import { IImgTitleCardProps } from "../imgTitleContentCard/imgTitleContentCard";
import { ComponentList } from "../../controllers/componentList";
import "./greyContentRow.scss";

interface IGreyContentRowProps {
    contentItems?: {
        sectionIntro?: {
            title: string
            content?: string
        }
        items: {
            contentItems: IDownloadCardProps[] | { page: { contentItems: IImgTitleCardProps[] } }[]
        }
    }[],
};

export const GreyContentRow: React.FC<IGreyContentRowProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length == 0 || !contentItems[0].items?.contentItems) return <React.Fragment></React.Fragment>

    var compName= "imgTitleContentCard";

    const intro = contentItems[0].sectionIntro;
    const items = contentItems[0]?.items?.contentItems?.map(card => {
        if(card.page) 
          return card.page.contentItems[0];
          else{
              compName = "downloadCard";
              return card
          }
    })?.slice(0,2);

    if (!items) return <React.Fragment></React.Fragment>;

    const Comp = ComponentList[compName];
    return <div className={"grey-content-row" + (compName == "imgTitleContentCard" ? " grey-content-row--columns" : "")}>
        <div className="grey-content-row__wrapper">
            <div className="grey-content-row__inner">
                <div className="grey-content-row__left">
                    <h2>{intro?.title}</h2>
                    {intro?.content ? <p>{intro?.content}</p> : null}
                </div>
                <div className="grey-content-row__right">
                    {(items as Array<IDownloadCardProps | IImgTitleCardProps>).map((item, index) => {
                        if(item != null) {
                            return <div className="grey-content-row__item" key={index}>
                                <Comp
                                    {...item}
                                />
                            </div>
                        }
                    })}
                </div>
            </div>
        </div>
    </div>

}
