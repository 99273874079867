import * as React from 'react';
import "./cardGroup.scss";
import { SectionIntro } from "../sectionIntro/sectionIntro";
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

interface ICardGroupProps {
    intro?: {
        title: string
        content?: string
    }
    removeTopSpacing?: boolean,
    removeBottomSpacing?: boolean,
    mods?: BackgroundColours
    children: React.ReactNode[]
    className?: string
};

export const CardGroup: React.FC<ICardGroupProps> = ({ intro, removeTopSpacing, removeBottomSpacing, mods = BackgroundColours.Grey, children }) => {
    if (!children) return null;

    return (
        <ComponentContainer bg={mods} removeTopSpacing={removeTopSpacing} removeBottomSpacing={removeBottomSpacing}>
            <div className="card-group">
                {intro ? <SectionIntro
                    {...intro}
                    removeTopSpacing
                /> : null}
                <div className="card-group__inner">
                    {children.map((child, index) => {
                        if (child != null) return <div className="card-group__item" key={index}>{child}</div>
                    })}
                </div>
            </div>
        </ComponentContainer>
    );

}