import gql from "graphql-tag";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { MAP_FRAGMENT } from "./map-fragment";

export const EQUAL_RIGHTS_PAGE = gql`
query EqualRightsComponents($id: ID, $status: Status){
  pageEqualRights(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    map{
      contentItems{
        ...mapFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${MAP_FRAGMENT}
`;


export const EQUAL_RIGHTS_META_DATA = gql`
query EqualRightsMetaData($id: ID, $status: Status){
  page : pageEqualRights(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;