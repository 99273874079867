import gql from "graphql-tag";

export const EXTERNAL_LINK_CARD_GROUP_FRAGMENT = gql `
fragment externalLinkCardGroupFragment on externalLinkCardGroup {
    title
    content: htmlBody {
      html
    }
    items {
      contentItems {
        ... on contentExternalLinkCard {
          content
          link {
            text
            url
          }
          newTab
        }
      }
    }
  }
`;