import * as React from 'react';
import "./toolbar.scss";
import { Share } from "../share/share";
import { DownloadLink } from "../downloadLink/downloadLink";
import utilsUI from '../../core/utilsUI';

interface IToolbarProps {
        file?: {
            urls: string[]
            text?: string
        }
        share?: boolean
}

export const Toolbar: React.FC<IToolbarProps> = ({ file, share }) => {
    if (!file && !share) return <React.Fragment></React.Fragment>
    return <div className="toolbar">
        {file ? <DownloadLink text={file?.text}  url = {utilsUI.checkImgSrc(file?.urls)}/> : null}
        {share ? <Share /> : null}
    </div>

}