import * as React from 'react';
import "./postCardRow.scss";
import { CampaignCard, ICampaignCardProps, CampaignCardThemes } from "../campaignCard/campaignCard";
import { UpdateCard, IUpdateCardProps } from "../updateCard/updateCard";

interface IPostCardRowProps {
    items: ICampaignCardProps[] | IUpdateCardProps[],
    postType: string
    featureRight?: boolean
    startThemeIndex?: number
};

export const PostCardRow: React.FC<IPostCardRowProps> = ({ items, postType, featureRight, startThemeIndex }) => {

    const themeIndex = React.useRef(startThemeIndex);

    React.useEffect(() => {
        themeIndex.current = startThemeIndex;
    }, [items]);
    
    const updateThemeIndex = () => {

        if((themeIndex.current) + 1 >= CampaignCardThemes.length) {
            themeIndex.current = 0;
        }else {
            themeIndex.current += 1;
        }

    }

    const outputCard = (startIndex: number, stopIndex: number, isFeature: boolean, classList: string) => {

        let cardList = [];

        for (let i = startIndex; i < stopIndex; i++){

            if(items[i]) {
                const PostComponent: any = (postType == "CampaignCard" ? CampaignCard : UpdateCard);

                cardList.push(<PostComponent
                    {...items[i]}
                    key={i}
                    isLarge={isFeature}
                    mods={postType == "CampaignCard" ? CampaignCardThemes[themeIndex.current] : ""}
                />)
                if(!isFeature) updateThemeIndex();
            }
        }

        if(cardList.length) {
            return <div className={classList}>{cardList}</div>;
        }


    }

    return <div className={"post-card-row" + (featureRight ? " post-card-row--right" : "")}>
        {featureRight ? outputCard(0, 6, false, "post-card-row__column") : outputCard(6, items.length, false, "post-card-row__feature-col")}
        {/* {featureRight ? outputCard(4, items.length, true, "post-card-row__feature-col") : outputCard(0, 4, false, "post-card-row__column")} */}
    </div>

}