import * as React from 'react';
import { BackgroundTransition } from '../../components/backgroundTransition/backgroundTransition';
import { HomeBanner } from "../../components/homeBanner/homeBanner";
import { ContentBanner } from "../../components/contentBanner/contentBanner";
import { SiteDataContext } from "../../contexts/siteData";
import { themeAction } from "../../actions/theme";

interface IContentViewProps {
    template: any
    pageData: any
    theme?: string
};

export const ContentViewController: React.FC<IContentViewProps> = ({ template, pageData, theme }) => {

    const [backgroundIsAnimating, toggleBgAnimation] = React.useState(true);
    const { dispatch } = React.useContext(SiteDataContext);

    React.useEffect(() => {
        if (theme) {
            document.body.classList.add(theme);
            dispatch(themeAction(theme));
        }

        return () => {
            document.body.classList.remove(theme);
            dispatch(themeAction(""));
        }
    }, []);

    const toggleTransition = (isPlaying: boolean) => {
        toggleBgAnimation(isPlaying);
    }


    if (!pageData) return <React.Fragment></React.Fragment>

    const pageComponents = Object.entries(pageData).map(([key, value]) => {
        if (key === "homeBanner" || key === "contentBanner") return null;
        const componentType = value?.["componentType"] || key; // Campaigns allow multiple of same component, so the type is not the key for them.
        const component = template.components[componentType];
        if (!component) return null; // Only include the allowed component types for this template.
        if (!value || typeof value !== 'object') return null; // Only include components with data.
        return { component, data: value, orderNo: value["orderNo"] };
    })
    .filter(x => !!x) // Filter out null values
    .sort((a, b) => +(a.orderNo ?? 100) - +(b.orderNo ?? 100));

    const { locations, tags, contentItemId } = pageData;
    const currentPageData = { ...locations, ...tags, contentItemId: contentItemId };

    // console.log(pageComponents);

    return (
        <React.Fragment>

            {pageData["homeBanner"] ?
                <HomeBanner {...pageData["homeBanner"]} clickCallback={toggleTransition} /> :
                pageData["contentBanner"] ? <ContentBanner {...pageData["contentBanner"]} /> :
                    null
            }

            <div id="main-content" className="tab-region-toggle">
                {pageComponents.map((item, index) => {
                    const PageComp = item.component;
                    return <PageComp
                        {...item.data}
                        currentPageData={currentPageData}
                        key={index}
                    />
                })}
            </div>

            {pageData.contentType == "homepage" ? <BackgroundTransition isPlaying={backgroundIsAnimating} /> : null}
        </React.Fragment>
    );

}