import * as React from 'react';
import "./contentCardCarousel.scss";
import { ContentCard } from "../contentCard/contentCard";
import { Carousel } from '../carousel/carousel';
import { BaseContentIntro } from '../featureContentIntro/featureContentIntro';

interface IContentCardCarouselProps {
    contentItems: {
        items : { contentItems : { contentItemId: string , contentType: string}[] }
        label?: string
        title?: string
        content: string
    }[]
}

export const ContentCardCarousel: React.FC<IContentCardCarouselProps> = ({contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if (contentItems.length==0) return <React.Fragment></React.Fragment>

    const cards = contentItems[0].items?.contentItems.filter(item => item != null);
    if (!cards) return <React.Fragment></React.Fragment>
    if (cards.length==0) return <React.Fragment></React.Fragment>

    return <div className="content-card-carousel">
        {!contentItems[0].content || !contentItems[0].title ?null: <BaseContentIntro  label={contentItems[0].label}  title={contentItems[0].title} content={contentItems[0].content}/> }
        
        <Carousel 
            mods="carousel--small"
            startAtIndex={1}
        >
            {cards.map((item, index) => {
                return <ContentCard 
                    queryData={{
                        contentItemId: item.contentItemId,
                        contentType: item.contentType
                    }}
                    key={index} 
                />
            })}
        </Carousel>
    </div>

}