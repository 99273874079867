import * as React from 'react';
import "./arrowBtn.scss";
import { Icon, Icons } from "../icon/icon";

export enum ARROW_DIRECTION {
    PREVIOUS = "prev",
    NEXT = "next"
};

interface IArrowBtnProps {
    onClick: () => void
    ariaLabel: string
    classList ?: string
    includeIcon: boolean
    direction: ARROW_DIRECTION
};

export const ArrowBtn: React.FC<IArrowBtnProps> = ({ onClick, ariaLabel, classList, includeIcon, direction }) => {

    return <button
        role="button"
        onClick={onClick}
        aria-label={ariaLabel}
        className={"arrow-btn" + (classList ? ` ${classList}` : "") + (direction == ARROW_DIRECTION.PREVIOUS ? ` ${ARROW_DIRECTION.PREVIOUS}` : ` ${ARROW_DIRECTION.NEXT}`)}
    >
        {includeIcon ? <Icon icon={Icons.Chevron} /> : null}
    </button>    

}