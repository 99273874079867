import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from "@apollo/client/link/retry";
import { cache } from "../cache";

export function checkToken() {

    let token = localStorage.getItem('access_token'),
    responseData = { valid: false, token: "" };

    if (token) {
        let convertToken = JSON.parse(token);
        responseData['valid'] = true;
        responseData['token'] = convertToken.token;
    }

    return responseData;

}

export function setToken(tokenKey) {
    const tempToken = { token: tokenKey };
    return JSON.stringify(tempToken);
}

export function launchApollo() {

    const client = new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors)
                    graphQLErrors.forEach(({ message, locations, path }) =>
                        console.log(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                        ),
                    );
                    window.location.href = "/error-cms";
                if (networkError) {
                    console.log(`[Network error]: ${networkError}`);
                    window.location.href = "/error-cms";
                }
            }),
            // new RetryLink({
            //     attempts: {
            //         max: 2,
            //         retryIf: (error, _operation) => {
                       
            //             if(error.statusCode == "401") {
            //                 window.location.href="/";
            //                 return !!error;
            //             }
            //           window.location.href = "/error-cms";
            //         }
            //     },
            //     delay: (count, operation, error) => {
            //         return count * 1000 * Math.random();
            //     },
            // }),
            new HttpLink({
                uri: `${process.env.CMS_BASE_URL}/api/graphql/`,
                credentials: 'same-origin',
            })
        ]),
        cache,
    });

    return client;

};


exports = {
    checkToken,
    setToken
};