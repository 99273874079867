import * as React from 'react';
import "./imgGroup.scss";
import { AnimatedElement } from "../../controllers/animated-elements";
import utilsUI from "../../core/utilsUI";

interface IImgGroupProps {
    contentItems: {
        imgs: {
            urls :string[]
        }
    }[]
};

export const ImgGroup: React.FC<IImgGroupProps> = ({ contentItems }) => {

    if(!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length <0  || contentItems[0]?.imgs?.urls?.length !=3 ) return <React.Fragment></React.Fragment>

    return <AnimatedElement>
        <div className="component-wrapper">
            <div className="img-group">
                {contentItems[0].imgs.urls.map((item, index) => {
                    if(item != null) {
                        return <div 
                            className="img-group__item"
                            key={index}
                        >
                            <img 
                                src={utilsUI.checkImgSrc(item)}
                            />
                        </div>
                    }
                })}
            </div>
        </div>
    </AnimatedElement>

}