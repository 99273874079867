import { gql } from "@apollo/client";

export const GET_PAGE_ID = gql`
query GetPageID($path: String!) {
    route(path: $path) {
        ... on RouteInternal {
            url
            internal
            entity {
              __typename
              ... on NodeCampaign {
                id
                langcode {
                  id
                }
                title
                description {
                  value
                }
              }
              ... on NodeCountry {
                id
                langcode {
                  id
                }
                title
                description {
                  value
                }
              }
              ... on NodeFactSheet {
                id
                langcode {
                  id
                }
                title
                description {
                  value
                }
              }
              ... on NodePage {
                id
                langcode {
                  id
                }
                title
                description {
                  value
                }
              }
              ... on NodeStory {
                id
                langcode {
                  id
                }
                title
                description {
                  value
                }
              }
            }
          }
        }
      }
`;

