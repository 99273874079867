import gql from "graphql-tag";
import { FEATURE_IMG_FRAGMENT } from "./feature-img-fragment";
import { FIGURES_BLOCK_FRAGMENT } from "./figures-block-fragment";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const KNOW_THE_FACTS_PAGE = gql`
query KnowTheFactsComponents($id: ID, $status: Status){
  pageKnowTheFacts(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureImg {
      contentItems {
        ... featureImgFragment
      }
    }
    figureBlockCarousel {
      contentItems {
        ... figuresBlockFragment
      }
    }
    greyContentRow: ktfGreyContentRow {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
 
    featureCards {
      contentItems {
        ...imgContentCardFragment
        ... imgArticleCardFragment
        ... imgCampaignCardFragment
        ... imgFactSheetCardFragment
      }
    }
    discoverMore {       
      contentItems {
        ... imgTitleContentGroupFragment
       }
     }
  
    subscriptionBlock {
      displaySubscription
    }
}
}
${FEATURE_IMG_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_CAMPAIGN_CARD_FRAGMENT}
${IMG_FACT_SHEET_CARD_FRAGMENT}
${FIGURES_BLOCK_FRAGMENT}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
`;


export const KNOW_THE_FACTS_META_DATA = gql`
query KnowTheFactsMetaData($id: ID, $status: Status){
  page: pageKnowTheFacts(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;