import gql from "graphql-tag";

export const NEWSLETTER_PAGE = gql`
query NewsletterComponents($id: ID, $status: Status){
  pageNewsletter(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    newsletterForm {
      contentItems {
        ... on newsletter {
          description
          submit
          disclaimer
        }
      }
    }
  }
}

`;  

export const NEWSLETTER_META_DATA = gql`
query NewsletterMetaData($id: ID, $status: Status){
  page: pageNewsletter(where: {contentItemId: $id}, status: $status) {
    contentItemId
    path
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;
