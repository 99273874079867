import { Status } from '../generated/typed-document-nodes';
import * as React from 'react';
import { getLangByInitials } from "../core/langData";

export const languages = {
    english: { label: "English", initials: "en", value: "english", index: 0, htmlCode: "en-US" },
    russian: { label: "Русский", initials: "ru", value: "russian", index: 1, bodyClass: "russian-text", htmlCode: "ru-RU" },
    chinese: { label: "简体中文", initials: "zh", value: "chinese", index: 2, bodyClass: "chinese-text", htmlCode: "zh-Hans" },
    arabic: { label: "العربية", initials: "ar", value: "arabic", index: 3, bodyClass: "arabic-text", htmlCode: "ar" },
    french: { label: "Français", initials: "fr", value: "french", index: 4, htmlCode: "fr-FR" },
    portuguese: { label: "Português", initials: "pt", value: "portuguese", index: 5, htmlCode: "pt-PT" },
    spanish: { label: "Español", initials: "es", value: "spanish", index: 6, htmlCode: "es-ES" },
};

const paths = window.location.pathname;
const locations = paths.split('/').filter((el) => { el != ""; return el });
const region = getLangByInitials(locations[0]);
const defaultRegion = region ? region : languages.english;

export const contentState = process.env.NODE_ENV == "production" ? Status.Published : Status.Latest;

export const initSiteState = {
    language: defaultRegion,
    theme: ""
};

export const SiteDataContext = React.createContext(null);