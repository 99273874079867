import * as React from 'react';
import "./footer.scss";
import { Social } from "../social/social";
import { Icon, Icons } from "../icon/icon";
import { SiteDataContext } from "../../contexts/siteData";
import utilsUI from '../../core/utilsUI';
import { FooterTitles } from "../../language/footer";

export const Footer: React.FC<{pageFooter: any, pageFooterMenu: any, social: any}> = ({pageFooter, pageFooterMenu, social}) => {

    if (!pageFooter || !pageFooterMenu || !social) return null;

    React.useEffect(() => {

        setTimeout(() => {
            hasLoaded(true);
        }, 400);

    }, []);

    const [loaded, hasLoaded] = React.useState(false);
    const { state } = React.useContext(SiteDataContext);

    const getStandUpLogo = () => {
        return <div>
            <Icon icon={Icons[`EqualRights${regionData.initials.toUpperCase()}`]} />
            <span className="sr-only">{pageFooter.logos[1]?.title}</span>
        </div>
    }

    const getHumanRightsLogo = () => {
        return <a href={pageFooter.logos[0]?.url}>
            <Icon icon={Icons[`UNHR${regionData.initials}`]} />
            <span className="sr-only">{pageFooter.logos[0]?.title}</span>
        </a>
    }

    const regionData = state.language;

    let data = {
        "__typename": "footer",
        "localization": {
            "__typename": "LocalizationPart",
            "culture": "en"
        },
        "links": {
            "__typename": "ContentPickerField",
            "contentItems": pageFooterMenu.map(m => {
                return {
                    "pageMetaData": {
                        "title": m.title
                    },
                    "path": `/${regionData.initials}${m.url}`
                };
            })
        },
        "footerText": pageFooter.footerText
    };

    const footer = data;

    return <footer id="footerId" className={"footer tab-region-toggle" + (loaded ? " loaded" : "")}>
        <div className="footer__wrapper">
            <div className="footer__inner">
                <div className="footer__top-left">
                    {footer ? <div className="footer__links">
                        <ul role="menu">
                            {footer?.links.contentItems?.map((item, index) => {
                                if (!item) return null;
                                return <li role="none" key={index}>
                                    <a role="menuitem" href={utilsUI.getUrlWithRegion(state.language.initials, item['path'])}>{item['pageMetaData']['title']}</a>
                                </li>
                            })}
                        </ul>
                    </div> : null}
                    <Social items={social} />
                </div>
                <div className="footer__lower">
                    <div className="footer__copyright">
                        {FooterTitles[state.language.value].title}
                    </div>
                    <div className="footer__logos">
                        <div className="footer__unfe">
                            {regionData ? getHumanRightsLogo() : null}
                        </div>
                        <div className="footer__stand-up">
                            {regionData ? getStandUpLogo() : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}