import * as React from 'react';
import "./featureContentIntro.scss";
import { FeatureContent } from "../featureContent/featureContent";
import { dictionaryManager } from '../../core/storageManagers';

interface IFeatureContentIntroProps {
    contentItems: any
};

interface IBaseContentIntroProps {
    label?: string
    title?: string
    content: string
    links?: {
        url: string
        text: string
    }[]
    isLanding?: boolean
}

export const FeatureContentIntro: React.FC<IFeatureContentIntroProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if (contentItems.length == 0) return <React.Fragment></React.Fragment>

    const isLanding = contentItems[0].isLanding; 
    const links = contentItems[0]?.links?.contentItemIds?.map(id => {
        var obj = dictionaryManager.getItemById(id);
        if (obj) {
            return { url: obj.path, text: obj.title }
        }
    });
    return <BaseContentIntro label={contentItems[0].label}
        title={contentItems[0].title}
        content={contentItems[0].content}
        links={links}
        isLanding={isLanding } />
}


export const BaseContentIntro: React.FC<IBaseContentIntroProps> = ({ label, title, content, links, isLanding = false}) => {

    return <div className="component-wrapper">
        <div className="component-wrapper__inner">
            <div className={"feature-content-intro" + (isLanding ? " feature-content-intro--landing" : "")}>
                <FeatureContent
                    label={label}
                    title={title}
                    content={content}
                    link={links}
                    mods={isLanding ? "feature-content--landing" : ""}
                />
            </div>
        </div>
    </div>

}