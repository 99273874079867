import * as React from 'react';
import "../postCardGrid/postCardGrid.scss";
import { CampaignCardThemes } from "../campaignCard/campaignCard";
import { PostCardRow } from "../postCardRow/postCardRow";
import { SiteDataContext } from '../../contexts/siteData';
import utilsUI from "../../core/utilsUI";
import { ButtonTitles } from "../../language/button";

export const PostCardGridNew: React.FC<{ type: string, contentItems: any[] }> = ({ type, contentItems }) => {

    const { state } = React.useContext(SiteDataContext);
    const [isLoading, setLoadStatus] = React.useState(false);
    const themeIndex = React.useRef(Math.floor(Math.random() * CampaignCardThemes.length));
    const queryRange = React.useRef(0);
    const querySize = 6;

    const tmpChunks = utilsUI.chunkArray(contentItems, 6);

    let [showLoadMore, setShowLoadMore] = React.useState<any>(tmpChunks.length > 1);
    let [chunks, setChunks] = React.useState<any>([tmpChunks[0]]);
    let [page, setPage] = React.useState<any>(0);

    const getMorePosts = async () => {

        queryRange.current += querySize;

        let lchunks = [];
        let cpage = page + 1;
        let showMore = false;

        tmpChunks.forEach((ch, ix) => {
            if (ix <= cpage)
                lchunks.push(ch);
            else
                showMore = true;
        });

        setShowLoadMore(showMore);
        setChunks(lchunks);
        setPage(cpage);
    }

    return <div className={"post-card-grid" + (isLoading ? " loading" : "")}>
        <div className="post-card-grid__inner">
            {chunks.map((item, index) => {
                return <PostCardRow
                    items={item}
                    key={index}
                    postType={type == "campaign" ? "CampaignCard" : "UpdateCard"}
                    featureRight={true}
                    startThemeIndex={themeIndex.current}
                />
            })}

            {showLoadMore ? <div className="post-card-grid__controls">
                <button
                    className="btn"
                    style={{ pointerEvents: (isLoading ? "none" : "all") }}
                    onClick={getMorePosts}
                    disabled={isLoading ? true : false}
                >{!isLoading ? ButtonTitles[state.language.value].load : "Loading..."}</button>
            </div> : null}
        </div>
    </div>

}