import gql from "graphql-tag";
import { EXTERNAL_FEATURE_CTA_FRAGMENT } from "./external-feature-cta-fragment";
import { ICON_BLOCK_ROW_FRAGMENT } from "./icon-block-row-fragment";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_BLOCK_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";

import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { INTERNAL_FEATURE_CTA_FRAGMENT } from "./internal-feature-cta-fragment";

export const DONATE_PAGE = gql`
query DonatePageComponents($id: ID, $status: Status){
  pageDonate(where: {contentItemId: $id}, status: $status){
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureCards {
      contentItems {
        ... imgContentBlockFragment
      }
    }
    iconBlockRow: donateIconBlockRow {
      contentItems {
        ...iconBlockRowFragment
      }
    }
    featureCtaGroup {
      contentItems {
        contentType
        ... internalFeatureCtaFragment
        ... externalFeatureCtaFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${IMG_CONTENT_BLOCK_FRAGMENT}
${ICON_BLOCK_ROW_FRAGMENT}
${INTERNAL_FEATURE_CTA_FRAGMENT}
${EXTERNAL_FEATURE_CTA_FRAGMENT}
`;

export const DONATE_META_DATA = gql`
query DonateMetaData($id: ID, $status: Status){
  page: pageDonate(where: {contentItemId: $id}, status: $status){
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
    
  }

}

`;