import * as React from 'react';
import { Icon, Icons } from "../icon/icon";
import "./imgTitleContentCard.scss";
import { BgImg } from "../bgImg/bgImg";
import { useContentQuery } from "../../hooks/use-content-query";

export interface IImgTitleCardProps {
    contentItemId?: string
    contentType?: string
    contentItem: any
};

export const ImgTitleContentCard: React.FC<IImgTitleCardProps> = ({ contentItemId, contentType, contentItem }) => {

    let contentQuery: any = {};

    if (!contentItemId && contentItem) {
        contentQuery = contentItem;
    } else {
        if (!contentItemId || !contentType) return <React.Fragment></React.Fragment>

        contentQuery = useContentQuery(contentItemId, contentType);
        if (contentQuery == null) return <React.Fragment></React.Fragment>;
    }

    const colour = contentQuery.color?.replace('bg-', 'theme-');

    // console.log(contentQuery);

    return <div className={"img-title-content-card" + (colour ? ` img-title-content-card--${colour}` : "")}>
        <a href={contentQuery["url"]} target={contentQuery["isExternal"] ? "_blank" : "None"}>
            <div className="img-title-content-card__img">
                <BgImg
                    urls={contentQuery.img?.urls}
                    title={contentQuery.img?.title}
                    mods="bg-img--absolute"
                />
            </div>
            { contentQuery.label && contentQuery.date 
                ? <div className='th-label'>{new Date(contentQuery.date).toLocaleDateString('en-GB')} | {contentQuery.label}</div>
                : <div className='th-label'>&nbsp;</div>
                }
            <div className="img-title-content-card__content">
                <h4>{contentQuery['title']}</h4>
                <p>{contentQuery['content']}</p>
                <span className="img-title-content-card__icon">
                    <Icon icon={Icons.Chevron} />
                </span>
            </div>
            <div className="nbr-space"><p>&nbsp;</p></div>
        </a>
    </div>

}