import * as React from 'react';
import "./contentBanner.scss";
import utilsUI from "../../core/utilsUI";

interface IContentBannerProps {
    title: string
    subTitle: string
    label ?: string
    isShort?: boolean
    heroImageUrl?: string
    showHeadings?: boolean
    buttons?: any[]
    langcode: string
}

export const ContentBanner: React.FC<IContentBannerProps> = ({ title, subTitle, label , isShort, heroImageUrl, showHeadings, buttons, langcode }) => {

    function getImage() {
        return  <div className="img-hero" style={{ paddingBottom: "2vh" }}>
                    <div className="bg-img"
                        style={{ backgroundImage: `url(${utilsUI.checkImgSrc(heroImageUrl)})`, display: "flex" }}>
                        <div id="grad" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <div style={{ marginTop: "60vh", marginLeft: "5em", marginBottom: "5vh", marginRight: "3em"}}>
                                <div className="mini-caption">
                                    <h1 style={{ color: "white" }}>{title}</h1>
                                    <p>
                                        {buttons?.map((btn, ix) => {
                                            if (!btn.text) return <React.Fragment key={ix}></React.Fragment>
                                            return  <a  key={ix} 
                                                        className="btn btn-default" 
                                                        href={ btn?.link?.url?.startsWith("http") ? btn?.link?.url : `/${langcode}${btn?.link?.url}` }
                                                        style={{ marginRight: "20px", width: "15em", marginBottom: "10px" }}>
                                                        
                                                        <span className="text">{btn.text}</span>
                                                    </a>
                                        })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
    }

    function getHeadings() {
        if (showHeadings)
            return  <div className="content-banner__inner">
                        
                        {heroImageUrl ? null : <h1 className={isShort ? "h2" : "hero-heading-c2"}><span dangerouslySetInnerHTML={{ __html: title }}></span></h1> }

                        <h2 className="h4"><span>{subTitle}</span></h2>
                        {label ? <h3 className="h6">{utilsUI.formatDateString(label)}</h3> : null}
                    </div>
        else
            return  <React.Fragment></React.Fragment>
    }

    if(!title) return <React.Fragment></React.Fragment>
    
    return  <div id="contentBannerId" className={"content-banner" + (isShort ? ` content-banner--reduced` : "") + " loaded"}
                    style={{ zIndex: (heroImageUrl) ? 2 : 1, position: "relative", paddingTop: (heroImageUrl) ? "100px" : "auto", 
                    paddingBottom: (showHeadings) ? "5vh" : "0px" }}>
                {heroImageUrl ? getImage() : <React.Fragment></React.Fragment>}
                {getHeadings()}
            </div>
}