import * as React from 'react';
import "./colourCardRow.scss";
import { ColourCard } from "../colourCard/colourCard";
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";
import { AnimatedElement } from "../../controllers/animated-elements";

interface IColourCardRowProps {
    contentItems: {
        sectionIntro?: {
            title: string
            content?: string
        },
        items:
        {
            contentItems: {
                page: {
                    contentItems: {
                        contentItemId
                        contentType
                    }[]
                }
            }[]
        }
    }[]
};

export const ColourCardRow: React.FC<IColourCardRowProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length == 0 || !contentItems[0].items?.contentItems) return <React.Fragment></React.Fragment>

    const intro = contentItems[0].sectionIntro;
    const items =contentItems[0]?.items?.contentItems?.map(card => card.page?.contentItems[0]);

    return <AnimatedElement>
        <div className="colour-card-row">
            {intro ?
                <SectionIntro
                    {...intro}
                    removeTopSpacing
                /> : null}
            <div className="colour-card-row__wrapper">
                <div className="colour-card-row__inner">
                    {items.map((item, index) => {
                        return <div className="colour-card-row__item" key={index}>
                            <ColourCard {...item} />
                        </div>
                    })}
                </div>
            </div>
        </div>
    </AnimatedElement>

}