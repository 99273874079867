import gql from "graphql-tag";
import { CONTENT_PAGE_CARDS_FRAGMENT } from "./content-page-cards-fragment";
import { EXTERNAL_LINK_CARD_GROUP_FRAGMENT } from "./external-link-card-group";

export const CONTACT_US_PAGE = gql`
query ContactUsComponents($id: ID, $status: Status){
  pageContactUs(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    smallCardContentGroup {
      contentItems {
        ... on subheader {
          content
          title: displayText
        }
        ... contentPageCardsFragment
      }
    }
    externalLinkContentBlock {
      contentItems {
        ... externalLinkCardGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${EXTERNAL_LINK_CARD_GROUP_FRAGMENT}
${CONTENT_PAGE_CARDS_FRAGMENT}
`;

export const CONTACT_US_META_DATA = gql`
query ContactUsMetaData($id: ID, $status: Status){
  page: pageContactUs(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;