import ACTIONS from "../actions/types";
import { regionManager } from "../core/storageManagers";

export const siteDataReducer = (state, action) => {

    switch(action.type){

        case ACTIONS.LANGUAGE:
            regionManager.setLocalRegion(action.payload.language);
            return { ...state, language: action.payload.language };
        break;

        case ACTIONS.THEME:
            return { ...state, theme: action.payload.theme };
        break;
        
        default:
            return state;
        break;

    }

}