import ACTIONS from "./types";

export const themeAction = (currentTheme: string) => {

    return {
        type: ACTIONS.THEME,
        payload: {
            theme: currentTheme
        }
    }
}