import { gql } from "@apollo/client";

export const GET_FOOTER = gql`
  query GetFooterPage ($langcode: String) {
    route(path: "footer", langcode: $langcode) {
      ... on RouteInternal {
        __typename
        entity {
          ... on NodeFooter {
            id
            body {
              value
            }
            footerText
            logos {
              title
              url
            }
            socialPlatform {
              title
              url
            }
            subscribe {
              title
              url
            }
            langcode {
              id
            }
          }
        }
      }
    }
  }
`;

