import { useLocation } from "react-router-dom";
import utilsUI from "../core/utilsUI";

export const usePageType = (pageType, langcode) => {

    const location = useLocation();
    
    let foundPageData: any;

    if (pageType == "page") {

        const paths = utilsUI.getUrlPartitions(location?.pathname);

        let page: string;

        const lp = paths.length > 0 ? paths[paths.length-1] : "";

        switch(lp) {
            case "factsheets":
                page = "pageChallenges";
                break;
            case "our-campaigns":
                page = "pageOurCampaigns";
                break;
            case "updates":
                page = "pageUpdates";
                break;
            case "":
            case langcode:
                page = "homepage";
                break;
            case "around-the-world":
                page = "pageOurWork";
                break;
            case "what-we-do":
                page = "pageWhatWeDo";
                break;
            case "know-the-facts":
                page = "pageKnowTheFacts";
                break;
            case "history-of-the-right-to-love":
                page = "pageRightToLove";
                break;
            case "faqs":
                page = "pageFaqs";
                break;            
            case "definitions":
                page = "pageDefinitions";
                break;
            case "act-now":
                page = "pageActNow";
                break;
            case "contact-us":
                page = "pageContactUs";
                break;
            case "un-resources":
                page = "pageResources";
                break;
            default:
                page = "pageGeneralContent";
                break;
        }

        // console.log(page);

        foundPageData = {
            "contentItemIds": "n/a",
            "contentType": page
        };

        return foundPageData;
    } else if (pageType == "campaign") {
        foundPageData = {
            "contentItemIds": "n/a",
            "contentType": "campaign",
            "components": {
            }
        };

        return foundPageData;

    } else if (pageType == "factSheet") {
        foundPageData = {
            "contentItemIds": "n/a",
            "contentType": "factSheet",
            "components": {
            }
        };

        return foundPageData;

    } else if (pageType == "article") {
        foundPageData = {
            "contentItemIds": "n/a",
            "contentType": "article",
            "components": {
            }
        };
        return foundPageData;

    }

    return foundPageData;

}