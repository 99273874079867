import * as React from 'react';
import "./imgContentCard.scss";
import { AnimatedElement } from "../../controllers/animated-elements";
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";
import { BgImg } from "../bgImg/bgImg";
import { FeatureContent } from '../featureContent/featureContent';
import { ButtonTitles } from "../../language/button";
import { SiteDataContext } from "../../contexts/siteData";

export interface IImgContentCardProps {
    sectionIntro?: ISectionIntroProps
    mods?: string
    imgAlignment?: string
    displayShadow?: boolean
    page?: {
        contentItems: {
            contentItemId
            contentType,
            contentItem
        }[]
    }

    label?: string,
    content?: string,
    title?: string,
    img?: { urls: string[], title: string }
    link?: { url: string, text?: string }
    newTab? : boolean
};


export const ImgContentCard: React.FC<IImgContentCardProps> = ({ sectionIntro, imgAlignment, displayShadow, page, title, content, img, label, link }) => {
    const { state } = React.useContext(SiteDataContext);

    if (page && page['contentItems'] && page['contentItems'][0]?.contentItem) {
        const itm = page['contentItems'][0]?.contentItem;

        // console.log(itm);

        title = itm.title;
        content = itm.content;
        label = itm.label;
        link = { url: itm.url, text: !itm.urlText || itm.urlText == "" ? null : itm.urlText };
        img = { urls: [ itm.imageUrl ?? itm.img?.urls[0] ], title: itm.imageTitle ?? itm.img?.title };
    }
    else 
        return <React.Fragment></React.Fragment>;
   
    return <AnimatedElement>
        <div className={"img-content-card" + (displayShadow ? "" : " img-content-card--no-shadow") + (imgAlignment == "right" ? " img-content-card--img-right" : "")}>
            {sectionIntro ? <SectionIntro
                {...sectionIntro}
                removeTopSpacing
            /> : null}
            <div className="img-content-card__inner">
                <div className="img-content-card__wrapper">
                    <div className="img-content-card__img">
                        <BgImg
                            {...img}
                            mods="bg-img--absolute"
                        />
                    </div>
                    <div className="img-content-card__content">
                        <FeatureContent
                            label={label}
                            title={title}
                            content={content}
                            link={!link || !link?.url? null : [{ url: link?.url, text: link?.text ?? ButtonTitles[state.language.value].read }]}
                        />
                    </div>
                </div>
            </div>
        </div>
    </AnimatedElement>


}