import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';
import * as React from 'react';
import "./sectionIntro.scss";

export interface ISectionIntroProps {
    title: string
    content?: string
    link?: {
        url: string
        text: string
    }
    mods?: BackgroundColours
    removeTopSpacing?: boolean,
    removeBottomSpacing?: boolean,
    className?: string
};

export const SectionIntro: React.FC<ISectionIntroProps> = ({ title, content, link, mods, removeTopSpacing, removeBottomSpacing, className }) => {

    if (!title) return <React.Fragment></React.Fragment>

    return (
        <ComponentContainer bg={mods} removeTopSpacing={removeTopSpacing} removeBottomSpacing={removeBottomSpacing}>
            <div className="section-intro">
                <div className="section-intro__wrapper">
                    <div className="section-intro__inner">
                        {className ? <h2 className={className} dangerouslySetInnerHTML={{ __html: title }}></h2> : <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
                        {content ? <div className="section-intro__content">{content}</div> : ""}
                        {!!link?.text && !!link?.url && <div className="section-intro__cta">
                            <a className="btn" href={link.url}>{link.text}</a>
                        </div>}
                    </div>
                </div>
            </div>
        </ComponentContainer>
    );
}