import * as React from 'react';
import { IImgContentCardProps, ImgContentCard } from "../imgContentCard/imgContentCard";

interface ImgContentCardGroup {
    contentItems: IImgContentCardProps[]
};

export const ImgContentCardGroup: React.FC<ImgContentCardGroup> = ({ contentItems }) => {

    if (!contentItems || contentItems.length == 0) return <React.Fragment></React.Fragment>
    return <React.Fragment>
        {contentItems?.map((item, index) => {
            if (item != null)
                return <ImgContentCard {...item} key={index} />
        })}
    </React.Fragment>
}