import gql from "graphql-tag";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_BLOCK_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { SIMPLE_PAGE_CAROUSEL_FRAGMENT } from "./simple-page-card-carousel";

export const ACT_NOW_PAGE = gql`
query ActNowComponents($id: ID,  $status : Status){
  pageActNow(where: {contentItemId: $id}, status : $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    actContentCardCarousel {
      contentItems{
        ...simplePageCarouselFragment
      }
    }
    socialContentCol {
      contentItems {
        ... on socialContentCard {
          content
          label
          title
        }
      }
    }
    contentBlockCard {
      contentItems {
        ... imgContentBlockFragment
      }
    }
    featureCards {
      contentItems {
        ... imgContentCardFragment
        ... imgArticleCardFragment
        ... imgCampaignCardFragment
        ... imgFactSheetCardFragment

      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${SIMPLE_PAGE_CAROUSEL_FRAGMENT}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_CAMPAIGN_CARD_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${IMG_CONTENT_BLOCK_FRAGMENT}
${IMG_FACT_SHEET_CARD_FRAGMENT}
`;

export const ACT_NOW_META_DATA = gql`
query ActNowMetaData($id: ID, $status : Status){
  page: pageActNow(where: {contentItemId: $id}, status : $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;