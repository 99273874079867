import gql from "graphql-tag";

export const INTERNAL_FEATURE_CTA_FRAGMENT = gql`
fragment internalFeatureCtaFragment on internalFeatureCta {
    sectionIntro {
      content
      title
    }
    removeTopSpacing
    removeBottomSpacing
    mods
    link {
      contentItemIds
    }
  }
  `;