import * as React from 'react';
import { useQuery } from '@apollo/client';
import { CardGroup } from "../cardGroup/cardGroup";
import { ImgTitleContentCard } from "../imgTitleContentCard/imgTitleContentCard";
import { SiteDataContext } from '../../contexts/siteData';
import { ArticleListDocument, CampaignListDocument, FactSheetListDocument } from '../../generated/typed-document-nodes';
import { BackgroundColours } from '../componentContainer/componentContainer';

interface IRelatedContentGroupProps {
    contentItems: {
        sectionIntro?: {
            title: string
            content?: string
        }
        removeTopSpacing?: boolean,
        removeBottomSpacing?: boolean,
        mods?: BackgroundColours
        contentItemId: string
        contentType: string
    }[],
    currentPageData: {
        tags?: { termContentItemIds: string[] },
        locations?: { termContentItemIds: string[] },
        contentItemId?: string // the current content item Id to exclude
    }
};

const queryTypes = {
    "relatedArticles": ArticleListDocument,
    "relatedCampaigns": CampaignListDocument,
    "relatedFactSheets": FactSheetListDocument
};

export const RelatedContentGroup: React.FC<IRelatedContentGroupProps> = ({ contentItems, currentPageData }) => {

    if (!contentItems?.length) return null;
    const { state } = React.useContext(SiteDataContext);
    const item = contentItems[0];
    const queryParams = { culture: state.language.initials, from: 0, size: 3, excludeContentItemId: currentPageData?.contentItemId, tags: currentPageData?.tags?.termContentItemIds ?? [], location: currentPageData?.locations?.termContentItemIds ?? [] };
    const { loading, data } = useQuery(queryTypes[item.contentType], { variables: { parameters: JSON.stringify(queryParams) } });

    if (loading || !data?.items) return null;

    const relatedItems = (currentPageData.contentItemId ?
        data.items.filter(({ contentItemId }) => contentItemId != currentPageData.contentItemId) :
        data.items).filter(x => !!x);

    if (!relatedItems.length) return null;

    return (
        <CardGroup
            intro={item.sectionIntro}
            removeBottomSpacing={item.removeBottomSpacing}
            removeTopSpacing={item.removeTopSpacing}
            mods={item.mods}
        >
            {relatedItems.map((relatedItem, index) => <ImgTitleContentCard key={index} {...relatedItem} />)}
        </CardGroup>
    );
}