import { gql } from "@apollo/client";

export const SIMPLE_PAGE_CAROUSEL_FRAGMENT = gql `
fragment simplePageCarouselFragment on simplePageCardCarousel {
    content
    title
    label
    items {
        contentItems{
            contentItemId
            contentType
          }
    }
  }
`;