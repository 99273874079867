import gql from "graphql-tag";

export const DOWNLOAD_GRID_FRAGMENT= gql `
fragment downloadGridFragment on downloadGrid {
    sectionIntro {
      content
      title
    }
    items {
      contentItems {
        ... on downloadCard {
          content
          title:displayText
          file {
            urls
          }
        }
      }
    }
  }
`; 