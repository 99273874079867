import * as React from 'react';
import "./featureContent.scss";
import { AnimatedElement } from "../../controllers/animated-elements";

export interface IFeatureContentProps {
    label?: string
    title?: string
    content: string
    link?: {
        url: string
        text: string
        blank?: boolean
    }[]
    mods?: string
};

export const FeatureContent: React.FC<IFeatureContentProps> = ({ label, title, content, link, mods }) => {

    if(!content) return <React.Fragment></React.Fragment>
    return <AnimatedElement>
        <div className={"feature-content" + (mods ? ` ${mods}` : "")} >
            {label ? <h6>{label}</h6> : ""}
            {title ? <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>: ""}
            <div className="feature-content__text" dangerouslySetInnerHTML={{ __html: content }}></div>
            {link ? <div className="feature-content__cta">
                {
                    link.map((item, index) => {
                        return <a
                            className="btn"
                            target={item?.blank ? "_blank" : ""}
                            key={index}
                            href={item?.url}
                        >
                            {item?.text}
                            <span className="sr-only">{`Read more about this item ${title}`}</span>
                        </a>
                    })
                }
            </div> : ""}
        </div>
    </AnimatedElement>

}