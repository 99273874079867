import * as React from 'react';
import { Carousel } from '../carousel/carousel';
import { IMediaDownloadBlockProps, MediaDownloadBlock } from "../mediaDownloadBlock/mediaDownloadBlock";
import { SectionIntro, ISectionIntroProps } from "../sectionIntro/sectionIntro";
import "./mediaDownloadCarousel.scss";
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

interface IMediaCarouselProps {
    contentItems: {
        sectionIntro?: ISectionIntroProps
        removeTopSpacing: boolean
        removeBottomSpacing: boolean
        mods?: BackgroundColours
        items: {
            contentItems: IMediaDownloadBlockProps[]
        }
    }[]

};

export const MediaDownloadCarousel: React.FC<IMediaCarouselProps> = ({ contentItems }) => {

    if (!contentItems?.length || !contentItems[0].items) return null;
    const item = contentItems[0];

    return (
        <ComponentContainer bg={item.mods} removeTopSpacing={item.removeTopSpacing} removeBottomSpacing={item.removeBottomSpacing}>
            <div className="media-download-carousel">
                {item.sectionIntro ? <SectionIntro {...item.sectionIntro} removeTopSpacing /> : null}
                <Carousel
                    mods="carousel--no-spacing carousel--block"
                    social={true}
                >
                    {item.items.contentItems?.map((item, index) => {
                        if (item != null) return <MediaDownloadBlock {...item} key={index} />
                    })}
                </Carousel>
            </div>
        </ComponentContainer>
    );
}