import * as React from 'react';
import { SiteDataContext } from '../../contexts/siteData';
import { CardGrid } from '../cardGrid/cardGrid';
import { MiscTitles } from "../../language/misc";


export const FactSheetCardGrid: React.FC<{contentItems: DrupalParagraphFactSheet[], langcode: string}> = ({contentItems, langcode}) => {   

    if(!contentItems) return <React.Fragment></React.Fragment>;
    const { state } = React.useContext(SiteDataContext);

    const items = contentItems.map(item =>  {
        let obj = {
            title: item.title,
            content: item.body.value,
            link: {
                "__typename": item.link?.__typename,
                "title": item.link?.title,
                "url": (item.link?.url?.startsWith('/')) ? `/${langcode}${item.link?.url}` : item.link?.url,
                "internal": item.link?.internal
            },
            label: MiscTitles[state.language.value].factSheet,
            "removeBottomSpacing": true,
            "removeTopSpacing": true,
        };

        return obj;
    });

    return <CardGrid 
        compName="contentCard" 
        items={items}
        colCount={3}
    />

}