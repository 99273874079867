import * as React from 'react';
import "./downloadCard.scss";
import { Icon, Icons } from "../icon/icon";
import utilsUI from '../../core/utilsUI';
import { ButtonTitles } from "../../language/button";
import { SiteDataContext } from "../../contexts/siteData";

export interface IDownloadCardProps {
    title: string
    content: string
    file: {
        urls: string[]
    }
};

export const DownloadCard = (props: IDownloadCardProps) => {
    const { state } = React.useContext(SiteDataContext);
    const calcType = () => {
        // need to calculate the size & extension
        if(!props.file?.urls ||  props.file?.urls.length==0 ) return "";
        return props.file.urls[0].split('.').pop();
    }

    if(!props.title || !props.content || !props.file) return <React.Fragment></React.Fragment>
    return <a
        className="download-card"
        href={utilsUI.checkImgSrc(props.file?.urls)}
    >
        <h6>{props.title}</h6>
        <p>{props.content}</p>
        <div className="download-card__details">
            <div className="download-card-button">
                <Icon icon={Icons.Download} />
                {ButtonTitles[state.language.value].download}
            </div>
            {calcType()}
        </div>
    </a>

}

