import * as React from 'react';
import "./dropdown.scss";
import { Icon, Icons } from "../icon/icon";
import { v4 as uuid_v4 } from "uuid";

interface IDropdownProps {
    options: {
        title: string
        value: string
    }[]
    activeIndex?: number
    classList?: string
    hoverEffect?: boolean
    clickCallback: (clickedItem) => void
};

export const Dropdown: React.FC<IDropdownProps> = ({ options, activeIndex, classList, hoverEffect, clickCallback }) => {

    const [menuOpen, toggleMenu] = React.useState(false);
    const [currentItemIndex, updateActiveIndex] = React.useState(activeIndex ? activeIndex : 0);
    const id = uuid_v4();

    React.useEffect(() => {
        updateActiveIndex(activeIndex);
    }, [activeIndex]);

    const clickedItem = (e: React.MouseEvent) => {
        toggleMenu(false);
        clickCallback(e.currentTarget.getAttribute('data-value'));
        updateActiveIndex(parseInt(e.currentTarget.getAttribute('data-index')));
    }

    const outputBtns = () => {

        return options.map((item, index) => {

            return <li role="none" key={index}>
                <button
                    className={(currentItemIndex == index ? "active" : "")}
                    role="menuitem"
                    onClick={(e) => { clickedItem(e) }}
                    data-value={item.value}
                    data-index={index}
                >
                    {item.title}
                </button>
            </li>
        })

    }

    return <div className={"dropdown" + (menuOpen ? " active" : "") + (classList ? ` ${classList}` : "") + (hoverEffect ? "" : " dropdown--no-hover")}>
        <button 
            className="dropdown__current"
            aria-haspopup="true"
            aria-controls={"option-list-" + id}
            aria-expanded={menuOpen ? true : false}
            id={"dropdown-toggle-" + id}
            aria-label="Current selected option"
            onClick={() => { toggleMenu(!menuOpen) }}
        >
            <span>{options[currentItemIndex].title}</span>
            <Icon icon={Icons.Chevron} />
        </button>
        <ul 
            role="menu" 
            aria-label="Available options" 
            id={"option-list-" + id}
        >
            {outputBtns()}
        </ul>
    </div>
    
}