import * as React from 'react';
import "./mediaDownloadBlock.scss";
import { Icon, Icons } from "../icon/icon";
import utilsUI from '../../core/utilsUI';

export interface IMediaDownloadBlockProps {
    bgImg: { urls: string[]}
    media: { urls: string[] }
    mods ?: string
};

export const MediaDownloadBlock: React.FC<IMediaDownloadBlockProps> = ({ bgImg, media, mods }) => {

    if(!bgImg?.urls?.length || !media?.urls?.length) return null;
    const downloadUrl = media?.urls ?? bgImg?.urls;
    return <div className={"media-download-block" + (mods ? ` ${mods}` : "")}>
        <a 
            href={utilsUI.checkImgSrc(downloadUrl)}
            target="_blank"
            style={{ backgroundImage: `url(${utilsUI.checkImgSrc(bgImg?.urls)})` }}
            download="download"
        >
            <span className="media-download-block__tag">
                <span>Download</span>
                <Icon icon={Icons.Download} />
            </span>
            <span className="sr-only">Download media item</span>
        </a>
    </div>

}