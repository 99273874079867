import { gql } from "@apollo/client";

export function GET_PAGE_CONTENTS_fn(contentType: string) {

  if (!contentType)
    contentType = "NodePage";

  const qry = GET_PAGE_CONTENTS.replace("<CONTENT_TYPE>", contentType);

  return gql(qry);
}

const GET_PAGE_CONTENTS = `
  query GetPageContents($id: ID!, $langcode: String) {
    node(id: $id, langcode: $langcode) {
      ... on <CONTENT_TYPE> {
        id
        title
        description {
          value
        }
        image {
          alt
          variations(styles: HERO_BANNER) {
            url
          }
        }
        color
        date {
          time
        }
        layout {
          __typename
          ... on ParagraphAccordionsWidget {
            title
            color
            accordions {
              ... on ParagraphAccordion {
                title
                body {
                  value
                }
                boxes {
                  ... on ParagraphBox {
                    title
                    body {
                      value
                    }
                    link {
                      ... on Link {
                        title
                        url
                        internal
                      }
                    }
                    color
                  }
                }
              }
            }
          }
          __typename
          ... on ParagraphCampaignsWidget {
            campaigns {
              ... on ParagraphCampaign {
                image {
                  ... on Image {
                    url
                    alt
                  }
                }
                title
                body {
                  value
                }
                link {
                  ... on Link {
                    title
                    url
                    internal
                  }
                }
              }
            }
            color
          }
          __typename
          ... on ParagraphCardsWidget {
            title
            color
            card {
              ... on ParagraphCard {
                hasBorder
                imagePosition
                image {
                  ... on Image {
                    alt
                    variations(styles: CARD) {
                      url
                    }
                  }
                }
                text
                title
                description {
                  value
                }
                buttons {
                  ... on ParagraphButton {
                    text
                    link {
                      ... on Link {
                        title
                        url
                        internal
                      }
                    }
                    color
                  }
                }
              }
            }
          }
          __typename
          ... on ParagraphDownloadButtonWidget {
            downloadtext
            downloadfile {
              ... on File {
                url
              }
            }
            color
          }
          __typename
          ... on ParagraphEditorWidget {
            title
            body {
              value
            }
            color
          }
          __typename
          ... on ParagraphFactSheetsWidget {
            factSheets {
              ... on ParagraphFactSheet {
                text
                title
                body {
                  value
                }
                link {
                  ... on Link {
                    title
                    url
                    internal
                  }
                }
                color
              }
            }
          }
          __typename
          ... on ParagraphHeroBannersWidget {
            heroBanner {
              ... on ParagraphHeroBanner {
                image {
                  ... on Image {
                    alt
                    variations(styles: HERO_BANNER) {
                      url
                    }
                  }
                }
                title
                description {
                  value
                }
                buttons {
                  ... on ParagraphButton {
                    text
                    link {
                      ... on Link {
                        title
                        url
                        internal
                      }
                    }
                    color
                  }
                }
                color
              }
            }
          }
          __typename
          ... on ParagraphNumbersWidget {
            title
            body {
              value
            }
            number {
              ... on ParagraphNumber {
                text
                body {
                  value
                }
              }
            }
            color
          }
          __typename
          ... on ParagraphThumbnailsWidget {
            title
            body {
              value
            }
            thumbnail {
              ... on ParagraphThumbnail {
                image {
                  ... on Image {
                    alt
                    variations(styles: THUMBNAILS) {
                      url
                    }
                  }
                }
                title
                body {
                  value
                }
                date {
                  time
                }
                label
                link {
                  ... on Link {
                    title
                    url
                    internal
                  }
                }
                color
              }
            }
          }
          __typename
          ... on ParagraphYoutubeWidget {
            id
            color
            videos {
              ... on ParagraphYoutube {
                id
                videoId
              }
            }
          }
          __typename
          ... on ParagraphCarouselsWidget {
            id
            title
            description {
              value
            }
            color
            images {
              ... on ParagraphCarousel {
                id
                image {
                  alt
                  url
                }
              }
            }
          }
          __typename
          ... on ParagraphMapWidget {
            id
            body {
              value
            }
            title
            region {
              ... on ParagraphRegion {
                id
                title
                regionCode
                country {
                  ... on NodeCountry {
                    id
                    description {
                      value
                    }
                    image {
                      alt
                      variations(styles: CARD) {
                        url
                      }
                    }
                    path
                    title
                    layout {
                      ... on ParagraphBox {
                        id
                        body {
                          value
                        }
                        title
                        link {
                          internal
                          title
                          url
                        }
                        color
                      }
                    }
                  }
                }
                color
              }
            }
            color
          }
          __typename
          ... on ParagraphUpdatesWidget {
            id
            title
            mainStory {
              ... on NodeStory {
                id
              }
            }
            color
          }
          __typename
          ... on ParagraphIframeWidget {
            id
            iframe {
              ... on ParagraphIframe {
                id
                hasBorder
                src {
                  url
                }
                style
              }
            }
          }
          __typename
          ... on ParagraphHomeBannerWidget {
            id
            homeBanners {
              ... on ParagraphHomeBanner {
                id
                text
                color
              }
            }
          }
          ... on ParagraphSocialPlatformWidget {
            id
            textAbove {
              value
            }
            socialPlatforms {
              ... on ParagraphSocialPlatform {
                id
                title
                link {
                  url
                }
                color
              }
            }
          }
          ... on ParagraphCarouselsTextImageWidget {
            id
            title
            description {
              value
            }
            color
            textImages {
              ... on ParagraphCarouselTextImage {
                id
                title
                date {
                  time
                }
                description {
                  value
                }
                imageVerticalPosition
                image {
                  alt
                  variations(styles: CARD) {
                    url
                  }
                }
              }
            }
          }
          __typename
          ... on ParagraphMultiDownloadWidget {
            id
            title
            color
            downloads {
              ... on ParagraphDownloadButtonWidget {
                id
                downloadtext
                date
                color
                downloadfile {
                  url
                  mime
                  name
                }
              }
            }
          }
          ... on ParagraphBoxesWidget {
            id
            boxes {
              ... on ParagraphBox {
                id
                body {
                  value
                }
                color
                link {
                  title
                  url
                }
                title
              }
            }
            color
            title
          }
        }
        metatag {
          __typename
          ... on MetaTagValue {
            attributes {
              content
              name
            }
          }
          __typename
          ... on MetaTagProperty {
            attributes {
              content
              property
            }
          }
          __typename
          ... on MetaTagLink {
            attributes {
              href
              rel
            }
          }
        }
      }
    }
  }
`;