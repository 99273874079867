import * as React from 'react';
import "./background.scss";

export const Background: React.FC<{
    colours: string[]
    activeIndex: number
}> = ({ colours, activeIndex }) => {

    if(!colours) return <React.Fragment></React.Fragment>
    return <div 
        className="background" 
        role="presentation"
    >
        {
            colours.map((item, index) => {
                return <div 
                    role="presentation"
                    className={`background__item ${item}` + (index == activeIndex ? " active" : "")}
                    key={index} 
                ></div>
            })
        }
    </div>

}