import * as React from 'react';
import "./regionModal.scss";
import { ArrowBtn, ARROW_DIRECTION } from "../arrowBtn/arrowbtn";
import { ICountryProps } from "../map/map";
import { Dropdown } from "../dropdown/dropdown";
import { BgImg } from "../bgImg/bgImg";
import utilsUI from "../../core/utilsUI";
import { SiteDataContext } from '../../contexts/siteData';
import { CardGrid, CARD_GRID_SPACING } from "../cardGrid/cardGrid";
import { useHistory, useLocation } from 'react-router-dom';
import { MiscTitles } from "../../language/misc";

interface IRegionModalProps {
    id: string
    title: string
    countries: ICountryProps[]
    modalCallback: () => void
    popupIconStyle: boolean
    displayRelatedItems: boolean
};

const PrevBtn: React.FC<{ onClick: (direction: number) => void }> = ({ onClick }) => {
    return (
        <ArrowBtn
            ariaLabel={"Previous country"}
            onClick={() => onClick(-1)}
            classList="region-modal__prev"
            includeIcon={true}
            direction={ARROW_DIRECTION.PREVIOUS}
        />
    );
};

const NextBtn: React.FC<{ onClick: (direction: number) => void }> = ({ onClick }) => {
    return (
        <ArrowBtn
            ariaLabel={"Next country"}
            onClick={() => onClick(1)}
            classList="region-modal__next"
            includeIcon={true}
            direction={ARROW_DIRECTION.NEXT}
        />
    );
};

export const RegionModal: React.FC<IRegionModalProps> = ({ title, countries, modalCallback, popupIconStyle, displayRelatedItems }) => {
    const curScrollPosition = React.useRef(null);
    const history = useHistory();
    const location = useLocation();
    const countryId = utilsUI.getURLParameter("country");
    const selectedCountry = countryId ? countries.find(x => x.name === countryId) : countries[0];
    const countryOptions = countries.map(({ name }, index) => ({ title: name, value: `${index}` }));
    const selectedCountryIndex = countries.indexOf(selectedCountry);

    React.useEffect(() => {
        curScrollPosition.current = utilsUI.enableScrollLock();

        return () => {
            utilsUI.disableScrollLock(curScrollPosition.current);
        }
    }, []);

    const moveCountry = (direction: number) => {
        let nextIndex = selectedCountryIndex;
        if (direction < 0) {
            nextIndex = (selectedCountryIndex - 1 + countries.length) % countries.length;
        } else {
            nextIndex = (selectedCountryIndex + 1) % countries.length;
        }

        countryClickHandler(nextIndex);
    }

    const countryClickHandler = (index: number) => {
        const id = countries[index].name;
        const newSearch = utilsUI.updateQueryStringParameter(location.search, "country", id);
        history.push({
            pathname: location.pathname,
            search: newSearch
        })
    }

    const clickedOption = (index: string) => {
        countryClickHandler(parseInt(index));
    }

    const outputContents = () => {
        return <React.Fragment>
            <div className="region-modal__content">
                <div className="region-modal__content-inner">
                    <div className="region-modal__img">
                        <BgImg
                            {...selectedCountry.img}
                            mods={"bg-img--absolute"}
                        />
                    </div>
                    <div className="region-modal__region-title">
                        <h5>{selectedCountry.name}</h5>
                        {selectedCountry.subTitle ? <h6>{selectedCountry.subTitle}</h6> : null}
                    </div>
                    <div className="region-modal__text" dangerouslySetInnerHTML={{ __html: selectedCountry.content }}></div>
                    {!!displayRelatedItems && <RelatedItems locationItems={selectedCountry?.locations?.termContentItemIds} />}
                    {outputNavigation()}
                </div>
            </div>
        </React.Fragment>
    }

    const outputNavigation = () => {
        const { state } = React.useContext(SiteDataContext);
        return <div className="region-modal__controls">
            {countries.length > 1 ? <React.Fragment>
                <PrevBtn onClick={moveCountry} />
                <NextBtn onClick={moveCountry} />
                <span>{MiscTitles[state.language.value].next}&nbsp;&nbsp;|&nbsp;&nbsp;{(selectedCountryIndex + 1 >= countries.length ? countries[0].name : countries[selectedCountryIndex + 1].name)}</span>
            </React.Fragment> : null}
        </div>
    }

    return <div className={"region-modal" + (popupIconStyle ? " region-modal--icon" : "")}>
        <div className="region-modal__header">
            <div className="region-modal__title h4">{title}</div>
            <button
                role="close"
                onClick={() => { modalCallback() }}
                aria-label="Close modal"
                className="region-modal__close"
            >
                <span className="sr-only">Close modal</span>
            </button>
            {countries.length > 1 ?
                <div className="region-modal__sections">
                    <div className="region-modal__sections-inner">
                        <PrevBtn onClick={moveCountry} />
                        <Dropdown
                            options={countryOptions}
                            clickCallback={clickedOption}
                            activeIndex={selectedCountryIndex}
                            classList="region-modal__dropdown"
                            hoverEffect={false}
                        />
                        <NextBtn onClick={moveCountry} />
                    </div>
                </div> : null}
        </div>
        {outputContents()}
    </div>

}

const RelatedItems: React.FC<{ locationItems: any[] }> = ({ locationItems }) => {
    if (!locationItems?.length) return null;

    const items = locationItems.map((item) => {
        return {
            title: item.title,
            content: item.body?.value,
            link: {
                url: item.link?.url
            },
            hasBorder: true
        };
    });

    return <CardGrid
        compName="contentCard"
        spacing={CARD_GRID_SPACING.TOP}
        isFullWidth={true}
        items={items}
    />
};