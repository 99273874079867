import * as React from 'react';
import { Background } from "../background/background";

export const BackgroundTransition: React.FC<{ isPlaying: boolean }> = ({ isPlaying }) => {

    const colours = React.useRef(["bg-blue", "bg-red", "bg-orange", "bg-green"]);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const colourInterval = React.useRef(null);
    const curIndex = React.useRef(0);

    React.useEffect(() => {
        
        document.body.classList.add(colours.current[activeIndex]);
        return () => {
            document.body.classList.remove(colours.current[activeIndex]);
            clearInterval(colourInterval.current);
        }

    }, []);

    React.useEffect(() => {
        
        if(isPlaying) {
            startInterval();
        }else {
            clearInterval(colourInterval.current);
        }

    }, [isPlaying]);


    const startInterval = () => {

        colourInterval.current = setInterval(() => {
            getNextColourIndex();
        }, 15000);
    }

    const getNextColourIndex = () => {

        document.body.classList.remove(colours.current[curIndex.current]);
        curIndex.current = (curIndex.current + 1 > colours.current.length - 1) ? 0 : curIndex.current += 1;
        document.body.classList.add(colours.current[curIndex.current]);
        setActiveIndex(curIndex.current);

    }

    return <Background
        colours={colours.current}
        activeIndex={activeIndex}
    />
}