import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ContentBanner } from "../../components/contentBanner/contentBanner";
import { SiteTitles } from "../../language/head";

export const ErrorViewController: React.FC<{ title: string, message: string }> = ({ title, message }) => {

    React.useEffect(() => {

        document.body.classList.add('theme-blue');
        return () => document.body.classList.remove('theme-blue');

    }, []);

    return <React.Fragment>
        <Helmet>
            <title>{SiteTitles.english.title} - Network error</title>
            <link rel="preconnect" crossorigin href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap" rel="stylesheet" />
            <link rel="preload" as="style" href="/assets/css/latin.css" />
            <link rel="stylesheet" media="all" href="/assets/css/latin.css" />
        </Helmet>
        <ContentBanner
            title={title}
            subTitle={message}
            showHeadings={true}
        />
    </React.Fragment>

}