import * as React from 'react';
import "./figureBlock.scss";

export interface IFigureBlockProps {
    value: string
    unit?: string
    content: string
    mods?: string
};

export const FigureBlock: React.FC<IFigureBlockProps> = ({ value, unit, content, mods }) => {

    if(!value || !content) return <React.Fragment></React.Fragment>
    return <div className={"figure-block" + (mods ? ` ${mods}` : "")}>
        <div className="figure-block__value hero-heading-c2">
            {value}{unit ? unit : ""}
        </div>
        <div className="figure-block__content">
            {content}
        </div>
    </div>

}