import gql from "graphql-tag";

export const MEDIA_DOWNLOAD_CAROUSEL_FRAGMENT = gql`
fragment mediaDownloadCarouselFragment on mediaDownloadCarousel{
  sectionIntro {
    title
    content
  }
  mods
  removeBottomSpacing
  removeTopSpacing
  items {
    contentItems {
      contentItemId
      ... on downloadMediaCard{
        bgImg 
        {
          urls
        }
        media
        {
          urls
        }
      }
    }
  }
}
`;