import * as React from 'react';
import { Header } from "../components/header/header";
import { SideBar } from '../components/sideBar/sideBar';
import { Footer } from "../components/footer/footer";

export const GlobalPageContainer = (props) => {

    if (!props.pageMenu) return <React.Fragment></React.Fragment>;
  
    return <React.Fragment>
        <Header pageMenu={props.pageMenu} social={props.social} homeBanner={props.homeBanner} />
        <SideBar social={props.social} />
        <div className={"page-wrapper active"}>
            {props.children}
        </div>
        <Footer pageFooter={props.pageFooter} pageFooterMenu={props.pageFooterMenu} social={props.social} />
    </React.Fragment>

};