import gql from "graphql-tag";
import { GALLERY_FRAGMENT } from "./gallery-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { RELATED_ARTICLES_FRAGMENT } from "./related-articles-fragment";

export const ARTICLE_LIST = gql`
query ArticleList($parameters: String) {
  items: articleList(parameters: $parameters) {
    contentItemId
    contentType
    title: displayText
    thumbnail {
      urls
    }
    path
    date
    isFeature
    content
  }
}
`;


export const ARTICLE_META_DATA = gql`
query ArticleMetaData($id: ID, $status: Status) {
  page:article(where: {contentItemId: $id}, status: $status) {
   title: displayText
    content
    keywords
    path
    thumbnail {
      urls
    }
  }
}
`

export const ARTICLE_QUERY = gql`
query Article($slug :String,  $status: Status) {
  article(where: {path: $slug}, status: $status){
    contentItemId
    title: displayText
    content
    keywords
    path
    date
    tags{
      termContentItemIds
    }
    locations {
      termContentItemIds
    }
    thumbnail {
      urls
    }
    youtubeVideo: articleVideo {
      contentItems {
        contentItemId
        ... on youtubeVideo {
          img {
            urls
          }
          videoId
        }
      }
    }
   wysiwyg: htmlBody {
      html
    }
  
    galleryCarousel {
      items: contentItems {
        ... galleryFragment
      }
    }
    relatedContent {
      contentItems {
        ... relatedArticlesFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
  }
}

${GALLERY_FRAGMENT}
${RELATED_ARTICLES_FRAGMENT}
`;