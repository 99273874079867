
import * as React from 'react';
import { Carousel } from '../carousel/carousel';
import { GalleryItem, IGalleryItemProps } from "../galleryItem/galleryItem";
import { ISectionIntroProps, SectionIntro } from '../sectionIntro/sectionIntro';
import "./galleryCarousel.scss";
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

export const GalleryCarousel: React.FC<{
    items: IGalleryItemProps[]
}> = ({ items }) => {

    if (!items) return <React.Fragment></React.Fragment>
    if (items.length == 0) return <React.Fragment></React.Fragment>

    return <div className="gallery-carousel">
        <Carousel>
            {items.map((item, index) => {
                if (item != null) return <GalleryItem key={index} {...item} />
            })}
        </Carousel>
    </div>

}

interface IGalleryCarouselProps {
    contentItems: {
        sectionIntro?: ISectionIntroProps
        removeTopSpacing: boolean
        removeBottomSpacing: boolean
        mods?: BackgroundColours
        items: {
            contentItems: IGalleryItemProps[]
        }
    }[]

};

export const GalleryCarouselGroup: React.FC<IGalleryCarouselProps> = ({ contentItems }) => {
    if (!contentItems?.length) return null;
    const item = contentItems[0];
    if (!item.items?.contentItems) return null;

    return (
        <ComponentContainer bg={item.mods} removeTopSpacing={item.removeTopSpacing} removeBottomSpacing={item.removeBottomSpacing}>
            <div className="gallery-carousel">
                {item.sectionIntro ? <SectionIntro {...item.sectionIntro} removeTopSpacing /> : null}
                <Carousel mods="carousel--no-spacing">
                    {item.items?.contentItems.map((item, index) => {
                        if (item != null) return <GalleryItem key={index} {...item} />
                    })}
                </Carousel>
            </div>
        </ComponentContainer>
    );

}