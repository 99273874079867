import * as React from 'react';
import "./featureImg.scss";
import { useIsVisible } from '../../hooks/use-is-visible';
import utilsUI from '../../core/utilsUI';

interface IFeatureImgProps {
    contentItems:{
        img: {
            urls: string[]
        }
        caption?: string
    }[]   
};

export const FeatureImg: React.FC<IFeatureImgProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>;
    if (contentItems.length <1) return <React.Fragment></React.Fragment>;

    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return <div className="component-wrapper">
        <div className={"feature-img" + (isVisible ? ' is-visible' : '')} ref={ref}>
            <div className="feature-img__inner">
                <div className="feature-img__bg" style={{ backgroundImage: `url(${utilsUI.checkImgSrc(contentItems[0]?.img?.urls)})` }}>
                    <span className="sr-only">{contentItems[0].caption}</span>
                </div>
                {contentItems[0].caption ? <div className="feature-img__caption">{contentItems[0].caption}</div> : null}
            </div>
        </div>
    </div>
}