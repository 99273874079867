import gql from "graphql-tag";
import { DOWNLOAD_GRID_FRAGMENT } from "./download-grid";
import { FEATURE_IMG_FRAGMENT } from "./feature-img-fragment";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { MAP_FRAGMENT } from "./map-fragment";

export const OUR_WORK_PAGE = gql`
query OurWorkComponents($id: ID, $status: Status){
  pageOurWork(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureImg {
      contentItems {
        ... featureImgFragment
      }
    }
    map {
      contentItems {
        ... mapFragment
        }
    }
    featureContentCard {
      contentItems {
        ... imgContentCardFragment
        ... imgArticleCardFragment
        ... imgCampaignCardFragment
        ... imgFactSheetCardFragment
      }
    }

}
}

${FEATURE_IMG_FRAGMENT}
${MAP_FRAGMENT}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_CAMPAIGN_CARD_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${IMG_FACT_SHEET_CARD_FRAGMENT}
`;

/// The query exceeds the max allowed complexity fir the graphql query, 
///Therefore it has been devided in two seperate queries
///
export const OUR_WORK_PAGE_1 = gql`
query OurWorkComponents1($id: ID, $status: Status){
  pageOurWork(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
   
    downloadGrid {
      contentItems {
        ... downloadGridFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}


${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${DOWNLOAD_GRID_FRAGMENT}
`;

export const OUR_WORK_META_DATA = gql`
query OurWorkMetaData($id: ID, $status: Status){
  page: pageOurWork(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
    
 }
}
`;