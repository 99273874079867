export const Themes = {
    Orange: "theme-orange",
    Blue: 'theme-blue',
    Red: 'theme-red',
    Purple: 'theme-purple',
    Green: 'theme-green',
};

export const ThemeShapeColours = {
    "theme-orange": ["#c14a1d", "#af3e00"],
    "theme-blue": ["#297bb3", "#0d5382"],
    "theme-red": ["#540022", "#540022"],
    "theme-purple": ["#110023", "#611773"],
    "theme-green": ["#00472f", "#0c652a"],
};