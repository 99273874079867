import gql from "graphql-tag";

export const RELATED_FACT_SHEETS_FRAGMENT = gql `
fragment relatedFactSheetsFragment on relatedFactSheets {
  sectionIntro {  
    title
    content
  }
  removeBottomSpacing
  removeTopSpacing
  contentType
  contentItemId
}
`;