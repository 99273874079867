import gql from "graphql-tag";
import { DOWNLOAD_GRID_FRAGMENT } from "./download-grid";
import { FEATURE_IMG_FRAGMENT } from "./feature-img-fragment";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const CHALLENGES_PAGE = gql`
query ChallengesComponents($id: ID , $status: Status){
  pageChallenges(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureImg {
      contentItems {
        ... featureImgFragment
      }
    }
    factSheetsGrid {
      contentItems {
        ... on factSheetGrid{
           sectionIntro {
            title
            content
          }
        }
      }
    }
    greyContentRow {
      contentItems {
        ... downloadGridFragment
      }
    }
    featureCards {
      contentItems {
        ... imgContentCardFragment
        ... imgArticleCardFragment
        ... imgCampaignCardFragment
        ... imgFactSheetCardFragment

      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${FEATURE_IMG_FRAGMENT}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_CAMPAIGN_CARD_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${IMG_FACT_SHEET_CARD_FRAGMENT}
${DOWNLOAD_GRID_FRAGMENT}
`;

export const CHALLENGES_META_DATA = gql`
query ChallengesMetaData($id: ID, $status: Status){
  page: pageChallenges(where: {contentItemId: $id} , status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;