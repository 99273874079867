import * as React from 'react';
import { SiteDataContext } from "../../contexts/siteData";
import "./carouselWrapper.scss";

interface ICarouselWrapperProps {
    currentIndex: number
    children: React.ReactNode
    onTouchCallback: (direction: number) => void
    noItemPadding ?: boolean
};

export const CarouselWrapper: React.FC<ICarouselWrapperProps> = ({ currentIndex, children, onTouchCallback, noItemPadding }) => {

    let pages = React.Children.toArray(children);

    const curIndex = React.useRef(currentIndex);
    const container = React.useRef(null);
    const [xPos, updateXPos] = React.useState(0);
    const [rowWidth, updateRowWidth] = React.useState(0);
    const [touchStartPos, setTouchStartPos] = React.useState(0);
    const [curDirection, updateCurDirection] = React.useState(null);
    const { state } = React.useContext(SiteDataContext);
    
    React.useEffect(() => {
        setRowWidth();
        setCarouselPos();
        window.addEventListener('resize', setCarouselPos);
        return () => window.removeEventListener('resize', setCarouselPos);
    }, []);

    React.useEffect(() => {
        curIndex.current = currentIndex;
        calcXPos();
    },[currentIndex]);

    const setCarouselPos = () => {
        calcXPos();
        setRowWidth();
    }

    const getSlideItemWidth = () => {
        const outerWidth = container.current.clientWidth,
        outerPadding = parseInt(window.getComputedStyle(container.current, null).getPropertyValue('padding-left')) * 2,
        itemWidth = outerWidth - outerPadding;
        return itemWidth;
    }

    const setRowWidth = () => {
        updateRowWidth(pages.length * getSlideItemWidth());
    }

    const calcXPos = () => {

        const slideWidth = getSlideItemWidth();
        const xLoc = state.language.initials == "ar" ? (slideWidth * curIndex.current) : (slideWidth * curIndex.current * -1);
        updateXPos(xLoc);
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStartPos(e.touches[0].clientX);
    }

    const handleTouchMove = (e: React.TouchEvent) => {

        if(touchStartPos == -1) return;

        const touchEvent = e.touches[0],
        touchX = touchEvent.clientX;

        let deltaX = ((touchStartPos - Math.floor(touchX)));

        if(deltaX < 0) {
            updateCurDirection((state.language.initials == "ar" ? 1 : -1));
        }else if (deltaX > 0) {
            updateCurDirection((state.language.initials == "ar" ? -1 : 1));
        }

    }

    const handleTouchEnd = () => {

        onTouchCallback(curDirection);
        updateCurDirection(null);
        setTouchStartPos(-1);
    }

    if(!pages) return <React.Fragment></React.Fragment>
    return <div className={"carousel-wrapper" + (noItemPadding ? " carousel-wrapper--no-padd" : "")}>
        <div 
            className="carousel-wrapper__container"
            ref={container}
        >
            <div
                className="carousel-wrapper__slide-row"
                style={{ transform: `translateX(${xPos}px)`, width: `${rowWidth}px` }}
                onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
                onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
                onTouchEnd={() => handleTouchEnd()}
            >
                {pages.map((item, index) => {
                    return (
                        <div 
                            key={`page-${index}`} 
                            className={"carousel-wrapper__page" + (index === currentIndex ? " active" : "") + (index === currentIndex -1 ? " prev" : "") + (index === currentIndex + 1 ? " after" : "")}
                        >
                            {item}
                        </div>
                    )
                })}
            </div>
        </div>
    </div>

}