import gql from "graphql-tag";

export const SIMPLE_CONTENT_BLOCK_FRAGMENT = gql `
fragment simpleContentBlockFragment on simpleContentBlock {
  sectionIntro {  
    title
    content
  }
  mods

  removeBottomSpacing
  removeTopSpacing

}
`;