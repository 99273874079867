import gql from "graphql-tag";
import { MAP_COUNTRY_FRAGMENT } from "./map-country-fragment";

export const MAP_REGION_FRAGMENT =gql`
 fragment mapRegionFragment on mapRegion {
  region {
    contentItems {
      ... on locationRegion {
        name
        value
      }
    }
  }
  countries {
    contentItems {
      ... mapCountryFragment
    }
  }
}

${MAP_COUNTRY_FRAGMENT}
    
`;
