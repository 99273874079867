import { ComponentList } from "./componentList";
import { Themes } from "../enums/themes";
import { WHAT_WE_DO_META_DATA, WHAT_WE_DO_PAGE } from "../queries/page-what-we-do";
import { HOME_META_DATA, HOME_PAGE } from "../queries/page-home";
import { KNOW_THE_FACTS_META_DATA, KNOW_THE_FACTS_PAGE } from "../queries/page-know-the-facts";
import { ACT_NOW_META_DATA, ACT_NOW_PAGE } from "../queries/page-act-now";
import { OUR_CAMPAIGNS_META_DATA, OUR_CAMPAIGNS_PAGE } from "../queries/page-our-campaigns";
import { DONATE_META_DATA, DONATE_PAGE } from "../queries/page-donate";
import { EQUAL_RIGHTS_META_DATA, EQUAL_RIGHTS_PAGE } from "../queries/page-equal-rights";
import { CHALLENGES_META_DATA, CHALLENGES_PAGE } from "../queries/page-challenges";
import { FAQS_META_DATA, FAQS_PAGE } from "../queries/page-faqs";
import { UPDATES_META_DATA, UPDATES_PAGE } from "../queries/page-updates";
import { OUR_WORK_META_DATA, OUR_WORK_PAGE, OUR_WORK_PAGE_1 } from "../queries/page-our-work";
import { RIGHT_TO_LOVE_META_DATA, RIGHT_TO_LOVE_PAGE } from "../queries/page-right-to-love";
import { DEFINITIONS_META_DATA, DEFINITIONS_PAGE } from "../queries/page-definitions";
import { GENERAL_CONTENT_META_DATA, GENERAL_CONTENT_PAGE } from "../queries/page-general-content";
import { CONTACT_US_META_DATA, CONTACT_US_PAGE } from "../queries/page-contact-us";
import { ABOUT_US_META_DATA, ABOUT_US_PAGE } from "../queries/page-about-us";
import { ARTICLE_META_DATA, ARTICLE_QUERY } from "../queries/article";
import { CAMPAIGN_META_DATA, CAMPAIGN_QUERY } from "../queries/campaign";
import { FACT_SHEET_META_DATA, FACT_SHEET_QUERY } from "../queries/fact-sheet";
import { RESOURCES_META_DATA, RESOURCES_PAGE } from "../queries/page-resources";
import { NEWSLETTER_META_DATA, NEWSLETTER_PAGE } from "../queries/page-newsletter";

// "Home page " [DONE]
const unfeHomePage = {
    query: {
        components: HOME_PAGE,
        metaData: HOME_META_DATA
    },
    components: {
        homeBanner: ComponentList.homeBanner,
        editorWidget: ComponentList.wysiwyg,
        homeFeatureIntro: ComponentList.featureContentIntro,
        homeImgGroup: ComponentList.imgGroup,
        colourCardRow: ComponentList.colourCardRow,
        homeImgContentCard: ComponentList.imgContentCardGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        instagramCarousel: ComponentList.instagram,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
}

// "what we do" page [DONE]
const pageWhatWeDo = {
    theme: Themes.Purple,
    query: {
        components: WHAT_WE_DO_PAGE,
        metaData: WHAT_WE_DO_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        campaignCarousel: ComponentList.columnCarousel,
        whatWeDoFeatureCards: ComponentList.imgContentCardGroup,
        downloadGrid: ComponentList.downloadGrid,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "our work" page [DONE]
const pageOurWork = {
    theme: Themes.Purple,
    query: {
        components: [OUR_WORK_PAGE, OUR_WORK_PAGE_1],
        metaData: OUR_WORK_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        featureImg: ComponentList.featureImg,
        map: ComponentList.map,
        featureContentCard: ComponentList.imgContentCardGroup,
        downloadGrid: ComponentList.downloadGrid,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
}

// "know the facts" page [DONE]
const pageKnowTheFacts = {
    theme: Themes.Orange,
    query: {
        components: KNOW_THE_FACTS_PAGE,
        metaData: KNOW_THE_FACTS_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        featureImg: ComponentList.featureImg,
        figureBlockCarousel: ComponentList.figureBlockCarousel,
        thumbnails: ComponentList.imgTitleContentCardGroup,
        featureCards: ComponentList.imgContentCardGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "history of equal rights" page [DONE]
const pageEqualRights = {
    theme: Themes.Orange,
    query: {
        components: EQUAL_RIGHTS_PAGE,
        metaData: EQUAL_RIGHTS_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        map: ComponentList.map,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "challenges & solutions" page [DONE]
const pageChallenges = {
    theme: Themes.Orange,
    query: {
        components: CHALLENGES_PAGE,
        metaData: CHALLENGES_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        featureImg: ComponentList.featureImg,
        factSheetsGrid: ComponentList.factSheetCardGrid,
        greyContentRow: ComponentList.greyContentRow,
        featureCards: ComponentList.imgContentCardGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "FAQs" page [DONE]
const pageFaqs = {
    theme: Themes.Orange,
    query: {
        components: FAQS_PAGE,
        metaData: FAQS_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        termBlockRow: ComponentList.termBlockRow,
        faqs: ComponentList.accordionGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "History of right to love" page [Done]
const pageRightToLove = {
    theme: Themes.Orange,
    query: {
        components: RIGHT_TO_LOVE_PAGE,
        metaData: RIGHT_TO_LOVE_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        featureImg: ComponentList.featureImg,
        iframe: ComponentList.staticEmbed,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Definitions" page [DONE]
const pageDefinitions = {
    theme: Themes.Orange,
    query: {
        components: DEFINITIONS_PAGE,
        metaData: DEFINITIONS_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        definitionItemGroup: ComponentList.definitionItemGroup,
        featureCards: ComponentList.imgContentCardGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Act now" page [Done]
const pageActNow = {
    theme: Themes.Red,
    query: {
        components: ACT_NOW_PAGE,
        metaData: ACT_NOW_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        actContentCardCarousel: ComponentList.contentCardCarousel,
        socialContentCol: ComponentList.socialContentCol,
        contentBlockCard: ComponentList.imgContentCardGroup,
        featureCards: ComponentList.imgContentCardGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "donate" page [done]
const pageDonate = {
    theme: Themes.Red,
    query: {
        components: DONATE_PAGE,
        metaData: DONATE_META_DATA,
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        featureCards: ComponentList.imgContentCardGroup,
        iconBlockRow: ComponentList.iconBlockRow,
        featureCtaGroup: ComponentList.sectionIntroGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Article" page [done]
const article = {
    theme: Themes.Green,
    query: {
        components: ARTICLE_QUERY,
        metaData: ARTICLE_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        youtubeVideo: ComponentList.youtubeVideo,
        wysiwyg: ComponentList.wysiwyg,
        galleryCarousel: ComponentList.galleryCarousel,
        relatedContent: ComponentList.relatedContentGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Fact sheet" content page [Done]
const factSheet = {
    theme: Themes.Orange,
    query: {
        components: FACT_SHEET_QUERY,
        metaData: FACT_SHEET_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        toolbar: ComponentList.toolbar, 
        htmlContent: ComponentList.wysiwyg,
        figuresBlock: ComponentList.figureBlockCarousel,
        secondHmlcontent: ComponentList.wysiwyg,
        featureContent: ComponentList.sectionIntroGroup, 
        featureImage: ComponentList.featureImg, 
        faqs: ComponentList.accordionGroup,
        relatedContent: ComponentList.relatedContentGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Campaign content" page [DONE]
const campaign = {
    theme: Themes.Purple,
    query: {
        components: CAMPAIGN_QUERY,
        metaData: CAMPAIGN_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        youtubeVideo: ComponentList.youtubeVideo,
        youtubeVideo1: ComponentList.youtubeVideo,
        youtubeVideo2: ComponentList.youtubeVideo,
        youtubeVideo3: ComponentList.youtubeVideo,
        youtubeVideo4: ComponentList.youtubeVideo,
        content: ComponentList.wysiwyg,
        imageGalleryCarousel: ComponentList.galleryCarouselGroup,
        figuresBlock: ComponentList.figureBlockCarousel,
        simpleAccordionGroup: ComponentList.accordionGroup,
        mediaDownloadCarousel: ComponentList.mediaDownloadCarousel,
        relatedCampaigns: ComponentList.relatedContentGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock,
        heroImage:  ComponentList.featureImg,
    }
};

// "About us" page [DONE]
const pageAboutUs = {
    theme: Themes.Blue,
    query: {
        components: ABOUT_US_PAGE,
        metaData: ABOUT_US_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        editorWidget: ComponentList.wysiwyg,
        featureLogoRow: ComponentList.featureLogoRow,
        imgContentCardGroup: ComponentList.imgContentCardGroup,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Contact us" page
const pageContactUs = {
    theme: Themes.Blue,
    query: {
        components: CONTACT_US_PAGE,
        metaData: CONTACT_US_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        smallCardContentGroup : ComponentList.cardWysiwyg,
        externalLinkContentBlock: ComponentList.linkBlockRow,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Resources" page
const pageResources = {
    theme: Themes.Blue,
    query: {
        components: RESOURCES_PAGE,
        metaData: RESOURCES_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        contentBlocks: ComponentList.linkBlockRow,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Newsletter" page
const pageNewsletter = {
    theme: Themes.Blue,
    query: {
        components: NEWSLETTER_PAGE,
        metaData: NEWSLETTER_META_DATA
    },
    components: {
        newsletterForm: ComponentList.eNewsForm
    }
};


// "Privacy Policy" & "Terms of use" pages [DONE]
const pageGeneralContent = {
    theme: Themes.Blue,
    query: {
        components: GENERAL_CONTENT_PAGE,
        metaData: GENERAL_CONTENT_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        wysiwyg:  ComponentList.wysiwyg,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Updates" page [DONE]
const pageUpdates = {
    theme: Themes.Green,
    query: {
        components: UPDATES_PAGE,
        metaData: UPDATES_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        featureArticle: ComponentList.imgContentCardGroup,
        updateCardGrid: ComponentList.updateCardGrid,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

// "Our campaigns" page [DONE]
const pageOurCampaigns = {
    theme: Themes.Purple,
    query: {
        components: OUR_CAMPAIGNS_PAGE,
        metaData: OUR_CAMPAIGNS_META_DATA
    },
    components: {
        contentBanner: ComponentList.contentBanner,
        campaignCardGrid: ComponentList.campaignCardGrid,
        discoverMore: ComponentList.imgTitleContentCardGroup,
        subscriptionBlock: ComponentList.subscriptionBlock
    }
};

export const Templates = {
    homepage: unfeHomePage,
    pageOurCampaigns: pageOurCampaigns,
    pageUpdates: pageUpdates,
    pageActNow: pageActNow,
    pageKnowTheFacts: pageKnowTheFacts,
    pageOurWork: pageOurWork,
    pageWhatWeDo: pageWhatWeDo,
    pageDonate: pageDonate,
    pageEqualRights: pageEqualRights,
    pageChallenges: pageChallenges,
    pageFaqs: pageFaqs,
    pageDefinitions: pageDefinitions,
    pageRightToLove: pageRightToLove,
    pageGeneralContent: pageGeneralContent,
    pageAboutUs: pageAboutUs,
    pageContactUs: pageContactUs,
    article: article,
    campaign: campaign,
    factSheet: factSheet,
    pageResources: pageResources,
    pageNewsletter: pageNewsletter 
};

exports = {
    Templates,
    Themes
}