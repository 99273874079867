import * as React from 'react';
import { Icon, Icons } from "../icon/icon";
import "./share.scss";

export const Share: React.FC<{ noToggle?: boolean }> = ({ noToggle }) => {

    const [isActive, toggleActive] = React.useState(!!noToggle);
    const url = encodeURIComponent(window.location.href);

    return <div className={"share" + (noToggle ? " share--no-toggle" : "")}>
        {!noToggle &&
            <button
                role="button"
                onClick={() => { toggleActive(!isActive) }}
                aria-haspopup={true}
            >
                <Icon icon={Icons.Share} />
                Share
            </button>
        }
        <div className="share__tooltip" aria-label="share this page" hidden={(isActive ? false : true)}>
            <ul>
                <li>
                    <a title="Share on Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank">
                        <Icon icon={Icons.Facebook} />
                    </a>
                </li>
                <li>
                    <a title="Share on Twitter" href={`https://twitter.com/intent/tweet?url=${url}`} target="_blank">
                        <Icon icon={Icons.Twitter} />
                    </a>
                </li>
            </ul>
        </div>
    </div>
}