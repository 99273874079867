import * as React from 'react';
import utilsUI from '../../core/utilsUI';
import { Icon, Icons } from "../icon/icon";
import "./youtubeVideo.scss";
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

declare const YT: any;

export const YoutubeVideo: React.FC<{
    contentItems: {
        contentItemId: string
        img?: { urls: string[] },
        videoId: string,
        removeTopSpacing: boolean,
        removeBottomSpacing: boolean,
        mods?: BackgroundColours

    }[]
}> = ({ contentItems }) => {

    if (!contentItems?.length) return null;

    const item = contentItems[0];
    const [hasLoaded, updateLoadState] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [isPaused, setIsPaused] = React.useState(false);
    const player = React.useRef(null);
    const isLegacy = React.useRef(utilsUI.checkForIE11());

    React.useEffect(() => {
        if (!isLegacy.current) utilsUI.initYoutubeApi().then(loadVideo);
    }, []);

    const loadVideo = () => {


        if (YT && YT.Player) {
            const initVid = new YT.Player(item.contentItemId, {
                height: "100%",
                width: "100%",
                videoId: item.videoId,
                playerVars: {
                    loop: 0,
                    autoplay: 0,
                    playsinline: 0,
                    controls: 0,
                    enablejsapi: 1,
                    modestbranding: 1,
                    playlist: item.videoId,
                    origin: window.location.origin,
                    mute: 0,
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange,
                    'onError': onPlayerError
                }
            });

            player.current = initVid;
        }

    }

    const onPlayerError = (event) => {
        console.log(event);
    }

    const getVideoBg = () => {
        if (!item?.img || item.img.urls.length == 0) return `https://img.youtube.com/vi/${item.videoId}/0.jpg`;
        return `${utilsUI.checkImgSrc(item.img.urls)}`;
    }

    const onPlayerReady = () => {
        updateLoadState(true);
    }

    const onPlayerStateChange = (el) => {
        if (el.data === YT.PlayerState.PLAYING || el.data === YT.PlayerState.BUFFERING) {
            setIsPlaying(true);
            setIsPaused(false);
        }
        else if (el.data === YT.PlayerState.PAUSED) {
            setIsPaused(true);
        }
        else {
            setIsPlaying(false);
            setIsPaused(false);
        }
    };

    const playPauseVideo = () => {
        if (!player.current) return;

        if (!isPaused && isPlaying) {
            player.current.pauseVideo();
        }
        else {
            player.current.playVideo();
        }
    };

    if (!item.videoId) return <React.Fragment></React.Fragment>

    return (
        <ComponentContainer bg={item.mods} removeTopSpacing={item.removeTopSpacing} removeBottomSpacing={item.removeBottomSpacing}>
            <div className={"youtube-video" + (isLegacy.current ? " youtube-video--legacy" : "")}>
                <div className={"youtube-video__wrapper" + (hasLoaded || isLegacy.current ? " loaded" : "") + (isPlaying || isLegacy.current ? " active" : "")}>
                    {!isLegacy.current ? <React.Fragment>
                        <div
                            className="youtube-video__bg"
                            style={{ backgroundImage: `url(${getVideoBg()})` }}
                        >
                            <button
                                className="youtube-video__play-btn"
                                role="button"
                                aria-label="Play video button"
                                onClick={() => { playPauseVideo() }}
                            >
                                <Icon icon={Icons.Play} />
                            </button>
                        </div>
                        <div
                            className="youtube-video__iframe"
                            id={item.contentItemId}
                        ></div>
                    </React.Fragment> : <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${item.videoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
                </div>
            </div>
        </ComponentContainer>
    );
}