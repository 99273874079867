import { gql } from "@apollo/client";

export const FIGURES_BLOCK_FRAGMENT= gql`
fragment figuresBlockFragment on figuresBlock {
    items {
      contentItems {
        ... on figureBlock {
          content
          unit
          value
        }
      }
    }
    mods
    removeBottomSpacing
    removeTopSpacing
    sectionIntro {
      title
      content
    }
  }

`;
