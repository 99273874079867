import gql from "graphql-tag";

export const IMG_TITLE_CONTENT_GROUP_FRAGMENT = gql`
fragment imgTitleContentGroupFragment on ImgTitleContentCardGroup {
  removeTopSpacing
  sectionIntro {
    title
    content
  }
  items: cards
  {
     contentItems {
    ... on campaignCard {
      page: campaign {
        contentItems {
          contentItemId
          contentType
        }
      }
    }
    ... on articleCard {
      page: article {
        contentItems {
          contentItemId
          contentType
        }
      }
    }
    ... on pageCard {
      page {
        contentItems {
          contentItemId
          contentType
        }
      }
    }
    ... on factSheetCard {
      page: factSheet {
        contentItems {
          contentItemId
          contentType
        }
      }
    }
  }
}
}
  `;