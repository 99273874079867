import * as React from 'react';
import "./updateCard.scss";
import { BgImg } from "../bgImg/bgImg";
import { Icon, Icons } from "../icon/icon";
import utilsUI from "../../core/utilsUI";

export interface IUpdateCardProps {
    date?: string
    title: string
    content: string
    img?: {
        urls: string[]
        title?: string
    }
    url: string,
    isFeature?: boolean,
    isLarge?: boolean
    tag?: string
    mods?: string
}

export const UpdateCard: React.FC<IUpdateCardProps> = ({ date, title, content, img, url, isFeature, isLarge, tag, mods }) => {

    if(!date || !title || !content) return <React.Fragment></React.Fragment>
    const articleDate = utilsUI.formatDateString(date);

    return <a href={url} className={"update-card"
        + (isFeature ? " update-card--feature" : "")
        + (isLarge ? " update-card--large" : "")
        + (!img ? " update-card--no-img" : "")
        + (mods ? ` ${mods}` : "")
    }>
        {img ? <div className="update-card__img">
            <BgImg {...img} mods="bg-img--absolute" />
        </div> : null}
        <div className="update-card__lower">
            {date ? <h6>{articleDate}</h6> : null}
            {isLarge ? <h4>{title}</h4> : <h4 className="h5">{utilsUI.truncateWords(title, (isLarge ? 20 : 10))}</h4>}
            <div className="update-card__content">
                <p>{utilsUI.truncateWords(content, (isLarge ? 20 : 10))}</p>
            </div>
            <div className="update-card__foot">
                <span className="update-card__tag"></span>
                <div className="update-card__icon">
                    <Icon icon={Icons.Chevron} />
                </div>
            </div>
        </div>
    </a>

}