import * as React from 'react';
import "./downloadLink.scss";
import { Icon, Icons } from "../icon/icon";
import { ButtonTitles } from "../../language/button";
import { SiteDataContext } from "../../contexts/siteData";

export const DownloadLink: React.FC<{
    url: string
    text?: string
}> = ({ url, text }) => {
    const { state } = React.useContext(SiteDataContext);

    if(!url) return <React.Fragment></React.Fragment>
    return <a className="download-link" href={url}>
        <Icon icon={Icons.Download} /> {text ?? ButtonTitles[state.language.value].download}
    </a>

}