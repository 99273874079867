import gql from "graphql-tag";

export const IMG_CONTENT_CARD_FRAGMENT = gql`
fragment imgContentCardFragment on imgContentCard {
  imgAlignment
  displayShadow
  title
  content
  page{
    contentItems{
      contentItemId
      contentType
    }
  }
}`;

export const IMG_ARTICLE_CARD_FRAGMENT = gql`
fragment imgArticleCardFragment on imgArticleCard {
  imgAlignment
  displayShadow
  title
  content
  page{
    contentItems{
      contentItemId
      contentType
    }
  }
}`;

export const IMG_CAMPAIGN_CARD_FRAGMENT = gql`
fragment imgCampaignCardFragment on imgCampaignCard {
  imgAlignment
  displayShadow
  title
  content
  page{
    contentItems{
      contentItemId
      contentType
    }
  }
}`;

export const IMG_FACT_SHEET_CARD_FRAGMENT = gql`
fragment imgFactSheetCardFragment on imgFactSheetCard {
  imgAlignment
  displayShadow
  title
  content
  page{
    contentItems{
      contentItemId
      contentType
    }
  }
}`;

export const IMG_CONTENT_BLOCK_FRAGMENT = gql`
fragment imgContentBlockFragment on imgContentBlock {
  content
  img {
    urls
  }
  imgAlignment
  title
  label
  link{
    url
    text
  }
  newTab
}
`;