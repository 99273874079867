import * as React from 'react';
import "./cardWysiwyg.scss";
import { WYSIWYG } from "../wysiwyg/wysiwyg";
import { CardGrid, CARD_GRID_SPACING } from "../cardGrid/cardGrid";

interface ICardWysiwygProps {
    contentItems: {
        content?: string
        title?: string
        items: {
            contentItems: {
                page: {
                    contentItems: {
                        contentItemId
                        contentType
                        contentItem
                    }[]
                }
            }[]
        }
    }[]
};


export const CardWysiwyg: React.FC<ICardWysiwygProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if (contentItems.length == 0) return <React.Fragment></React.Fragment>

    return <div className="card-wysiwyg">
        {contentItems.map((item, index) => {
            return <div className="card-wysiwyg__wrapper" key={index}>
                <div className="card-wysiwyg__inner">
                    {item.title ? <h3>{item.title}</h3> : null}
                    {item.content ? <WYSIWYG html={`<p>${item.content}</p>`} isFullWidth={true} noPadding={true} /> : null}
                    {item.items?.contentItems?.length ? <div className="card-wysiwyg__lower">
                        <CardGrid
                            compName={"contentCard"}
                            contentItems={item.items.contentItems}
                            colCount={2}
                            isFullWidth={true}
                            spacing={CARD_GRID_SPACING.NONE}
                        />
                    </div> : null}
                </div>
            </div>
        })}
    </div>

}
