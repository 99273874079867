import * as React from 'react';
import "./linkBlock.scss";

export interface ILinkBlockProps {
    content: string;
    link: {
        url: string
        text: string
    }
    newTab?: boolean
};

export const LinkBlock: React.FC<ILinkBlockProps> = ({ content, link , newTab }) => {

    if(!content || !link) return <React.Fragment></React.Fragment>
    return <a className="link-block" href={link.url} target={newTab ? "_blank" : ""}>
        <p>{content}</p>
        <span>{link.text}</span>
    </a>

}