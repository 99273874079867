import * as React from 'react';
import "./accordionGroup.scss";
import { Accordion } from "../accordion/accordion";
import { SectionIntro } from "../sectionIntro/sectionIntro";
import { CardGrid, CARD_GRID_SPACING } from '../cardGrid/cardGrid';
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

interface IAccordionGroupProps {
    contentItems?: {
        content?: string
        title: string
        removeTopSpacing: boolean,
        removeBottomSpacing: boolean,
        mods?: BackgroundColours
        items: {
            contentItems: {
                contentItemId: string
                content?: string
                title: string
                items: {
                    contentItems: {
                        page: {
                            contentItems: {
                                contentItemId
                                contentType
                                contentItem
                            }[]
                        }
                    }[]
                }
            }[]
        }
    }[]
};

export const AccordionGroup: React.FC<IAccordionGroupProps> = ({ contentItems }) => {

    if (!contentItems?.length || !contentItems[0].items?.contentItems) return null;
    const item = contentItems[0];

    return (
        <ComponentContainer bg={item.mods} removeTopSpacing={item.removeTopSpacing} removeBottomSpacing={item.removeBottomSpacing}>
            <div className="accordion-group">
                <div className="accordion-group__inner">
                    <SectionIntro title={item.title} content={item.content} removeTopSpacing />
                    {item.items.contentItems.map((item, index) => {
                        return <Accordion {...item} key={index}>
                            {item.items?.contentItems ? <CardGrid
                                compName="contentCard"
                                spacing={CARD_GRID_SPACING.NONE}
                                isFullWidth={true}
                                contentItems={item.items?.contentItems}
                            /> : null}
                        </Accordion>
                    })}
                </div>
            </div>
        </ComponentContainer>
    );

}