import * as React from 'react';
import { Head } from "./head";
import { DynamicContentController } from "../controllers/dynamicContentController";
import { GlobalPageContainer } from "../controllers/globalPageContainer";
import { siteDataReducer } from "../reducers/siteData";
import { SiteDataContext, initSiteState } from "../contexts/siteData";
import { ErrorViewController } from "../controllers/errorViewController/errorViewController";
import { regionManager } from "../core/storageManagers";
import { SiteTitles } from "../language/head";
import { usePageType } from "../hooks/use-page-type";
import { ApolloClient, DocumentNode, HttpLink, InMemoryCache, from, useQuery } from '@apollo/client';
import { GET_PAGE_ID } from '../queries/get-page-id';
import { GET_PAGE_CONTENTS_fn } from '../queries/get-page-contents';
import { GET_MAIN_MENU } from '../queries/get-main-menu';
import { GET_FOOTER } from '../queries/get-footer';
import { GET_FOOTER_MENU } from '../queries/get-footer-menu';
import { GET_UPDATES } from '../queries/get-updates';
import { GET_SOCIAL } from '../queries/get-social';
import utilsUI from '../core/utilsUI';
import { onError } from '@apollo/client/link/error';

export const Page = (props) => {
    
    const httpLink = new HttpLink({ uri: `${utilsUI.getNewCMSUrl()}/api/graphql` })
    
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            console.error(graphQLErrors);
            const err0 = graphQLErrors[0];

            let pageLang = document.documentElement.getAttribute("lang")?.split('-')[0] ?? "en";

            if (err0 && err0["debugMessage"]?.indexOf("Invalid translation language") == 0) {
              let home = `${window.location.protocol}//${window.location.host}/${pageLang}/`;
              
              if (window.location.pathname.toLowerCase() == `/${pageLang}/`.toLocaleLowerCase()
                || window.location.pathname.toLowerCase() == `/${pageLang}`.toLocaleLowerCase()) {
                  home = `${window.location.protocol}//${window.location.host}/`;
              }
              window.location.replace(home);
            }
        }

        if (networkError) {
            console.log(networkError);
        }
    });

    const appLink = from([
        errorLink, httpLink
    ]);

    const [specificClient] = React.useState(
        () =>
          new ApolloClient({
            link: appLink,
            cache: new InMemoryCache(),
          })
    );

    const fetchPageData = async (langcode: string) => {
        const currentPath = window.location.pathname;
        let path = currentPath;

        if (currentPath.startsWith(`/${langcode}/`)) {
          path = currentPath.replace(`/${langcode}/`, `/`);
        }

        let pageResult: DrupalPageMetadata;
      
        try {
          const result = await specificClient.query({
            query: GET_PAGE_ID,
            variables: { path },
          });
      
          const entity = result.data.route?.entity;

          pageResult = {
            id: entity?.id,
            title: entity?.title,
            description: entity?.description?.value,
            langcode: langcode,
            url: path,
          };

        } catch (error) {
          console.error("GraphQL Error:", error);
          throw error;
        }
        return pageResult;
    };

    const fetchPageContents = async (id: String, langcode: String, pageType: string) => {
      if (!id) return null;
      
      let contentsResult: DrupalNode;
      let contentType = undefined;

      switch(pageType) {
        case "campaign":
          contentType = "NodeCampaign";
          break;
        case "factSheet":
          contentType = "NodeFactSheet";
          break;
        case "article":
          contentType = "NodeStory";
          break;
      }

      try {
        const result: any = await specificClient.query({
          query: GET_PAGE_CONTENTS_fn(contentType),
          variables: { id: id, langcode: langcode },
        }).catch(err => {
          console.log(err.networkError.result.errors);
        });
    
        // console.log(loading, error, data);

        contentsResult = result.data.node;

      } catch (error) {
        console.error("GraphQL Error:", error);
        throw error;
      }
      return contentsResult;
    };

    const fetchUpdates = async (id: number, langcode: String, pageType: string) => {
      if (id == null) return null;
      
      let pageResult: any;

      try {
        const result = await specificClient.query({
          query: GET_UPDATES,
          variables: { langcode },
        });

        pageResult = result.data?.allUpdates?.results;

      } catch (error) {
        console.error("GraphQL Error:", error);
        throw error;
      }
      return pageResult;
    };

    const fetchQuery = async (query: DocumentNode, langcode: String) => {
      try {
        const result: any = await specificClient.query({
          query: query,
          variables: { langcode: langcode },
        });
    
        return result;

      } catch (error) {
        console.error("GraphQL Error:", error);
        throw error;
      }
    };

    const [state, dispatch] = React.useReducer(siteDataReducer, initSiteState);

    const [pageResult, setPageResult] = React.useState<any>({id: 0});
    const [pageContent, setPageContent] = React.useState<any>(null);
    const [pageUpdates, setPageUpdates] = React.useState<any>(null);
    const [pageMenu, setPageMenu] = React.useState<any>(null);
    const [pageFooter, setFooter] = React.useState<any>(null);
    const [pageFooterMenu, setFooterMenu] = React.useState<any>(null);
    const [pageSocial, setSocial] = React.useState<any>(null);
    const [homeBanner, setHomeBanner] = React.useState<any>(false);

    React.useEffect(() => {
        regionManager.setLocalRegion(state.language);
        document.body.classList.remove('loading');
        if (state.language.bodyClass) document.body.classList.add(state.language.bodyClass);
        setHTMLLangAttribute();

        let pageLang = document.documentElement.getAttribute("lang")?.split('-')[0] ?? "en";
        fetchData(pageLang);

    }, []);

    const setHTMLLangAttribute = () => {
        const curRegion = state.language.htmlCode;
        const html = document.documentElement;
        if (!html.getAttribute("lang") || html.getAttribute("lang") != curRegion) {
            html.setAttribute("lang", curRegion);
        }
    }

    const fetchData = async (pageLang: string) => {
      try {
        fetchQuery(GET_MAIN_MENU, pageLang).then(res => {
          setPageMenu(res?.data?.menu?.items);
        });

        fetchQuery(GET_FOOTER, pageLang).then(res => {
          setFooter(res?.data?.route?.entity);
        });

        fetchQuery(GET_FOOTER_MENU, pageLang).then(res => {
          setFooterMenu(res?.data?.menu?.items);
        });

        fetchQuery(GET_SOCIAL, pageLang).then(res => {
          setSocial(res?.data?.socialPlatform);
        });

        const result = await fetchPageData(pageLang);
        setPageResult(result);

        fetchPageContents(result.id, result.langcode, props.type).then(res => {

          if (result.url.toLowerCase().endsWith('/updates')) {
            const mainStoryId = 0;

            fetchUpdates(+mainStoryId, result.langcode, props.type).then(upds => {
              setPageUpdates(upds);
            });
          } 
          // else if (result.url.toLowerCase().indexOf('/updates/') >= 0) {
          //   const mainStoryId = res.id;
          //   fetchUpdates(+mainStoryId, result.langcode, props.type).then(upds => {
          //     setPageUpdates(upds);
          //   });
          // }

          setPageContent(res);
        });
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    let hb = null;

    if (pageResult && pageContent && (pageResult.url == "/" || pageResult.url == `/${pageResult.langcode}`)) {
      hb = pageContent;
    }

    let data = usePageType(props.type, pageResult?.langcode ?? "");
    if (pageResult?.id == null) data = null;

    const outputPageContentComps = (data) => {
        if (!data) return <ErrorViewController title={`${SiteTitles[state.language.value].notFound.title}`} message={`${SiteTitles[state.language.value].notFound.content}`} />;
        return <DynamicContentController pageData={data} pageMetaData={pageResult} pageContent={pageContent} pageUpdates={pageUpdates} />;
    }

    if (data == -1) return <React.Fragment></React.Fragment>
    return <SiteDataContext.Provider value={{ state, dispatch }}>
        <Head
            contentItemId={data ? data['contentItemIds'] : null}
            contentType={data ? data['contentType'] : null}
            baseURL={process.env.SITE_URL}
            pageMetaData={pageResult}
            pageMetaTags={pageContent?.metatag}
        />
        <GlobalPageContainer pageMenu={pageMenu} pageFooter={pageFooter} pageFooterMenu={pageFooterMenu} social={pageSocial} homeBanner={hb}>
            {outputPageContentComps(data)}
        </GlobalPageContainer>
    </SiteDataContext.Provider>
}

