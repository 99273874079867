import * as React from 'react';
import { Icon, Icons } from "../icon/icon";
import "./accordion.scss";

export interface IAccordionProps {
    contentItemId: string;
    title: string
    content?: string
    children?: React.ReactNode
};

export const Accordion: React.FC<IAccordionProps> = ({ contentItemId, title, content, children }) => {

    if(!contentItemId) return <React.Fragment></React.Fragment>
    const [isExpanded, toggleExpanded] = React.useState(false);
    return <div className={"accordion" + (isExpanded ? " active" : "")}>
        <button
            onClick={() => { toggleExpanded(!isExpanded) }}
            aria-expanded={isExpanded}
            className="accordion__title h5"
            aria-controls={`${contentItemId}-content`}
            id={contentItemId}
        >
            {title}
            <Icon icon={Icons.Chevron} />
        </button>
        <div
            id={`${contentItemId}-content`}
            aria-labelledby={contentItemId}
            role="region"
            className="accordion__content"
            hidden={!isExpanded ? true : false}
        >
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            {children ? <div className="accordion__children">
                {children}
            </div> : null}
        </div>
    </div>

}