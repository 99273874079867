import gql from "graphql-tag";

export const IFRAME_FRAGMENT = gql`
fragment iframeFragment on iframe {
    sectionIntro {
      content
      title
    }
    embedCode
    description
  }
  `;