import * as React from 'react';
import "./linkBlockRow.scss";
import { LinkBlock, ILinkBlockProps } from "../linkBlock/linkBlock";
import { WYSIWYG } from "../wysiwyg/wysiwyg";

interface ILinkBlockRowProps {
    contentItems :{
        title?: string,
        content?: { html?: string}
        items:{
            contentItems :ILinkBlockProps[]
        }
    }[]
};

export const LinkBlockRow: React.FC<ILinkBlockRowProps> = ({ contentItems }) => {

    if(!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length == 0) return <React.Fragment></React.Fragment>

    return <div className="link-block-row">
        {contentItems.map((item, index) => {
            if(item != null) {
                return <div className="link-block-row__item" key={index}>
                    <div className="link-block-row__inner">
                        {item.content?.html ? <div className="link-block-row__intro">
                            {item?.title ?<h3 dangerouslySetInnerHTML={{ __html: item.title }}></h3>  :null}     
                            <WYSIWYG 
                                html={item.content?.html} 
                                isFullWidth={true} 
                                noPadding={true}
                            />
                        </div> : null}
                        {item.items ? <div className="link-block-row__links">{item.items?.contentItems?.map((curLink, curLinkIndex) => {
                            if(curLink != null) return <LinkBlock {...curLink} key={curLinkIndex} />
                        })}</div>: null}
                    </div>
                </div>
            }
        })}
    </div>
}