import gql from "graphql-tag";
import { IMG_CONTENT_CARD_GRID_FRAGMENT } from "./image-content-grid-fragment";
import { IMG_CONTENT_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { DOWNLOAD_GRID_FRAGMENT } from "./download-grid";

export const WHAT_WE_DO_PAGE = gql`
query WhatWeDoComponents($id: ID, $status: Status){
  pageWhatWeDo(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    campaignCarousel {
      contentItems {
        ... imageContentCardGridFragment 
      }
    }
    whatWeDoFeatureCards {
      contentItems {
        ...imgContentCardFragment
      }
    }
    downloadGrid {
      contentItems {
        ... downloadGridFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
  }
 }


${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${DOWNLOAD_GRID_FRAGMENT}
${IMG_CONTENT_CARD_GRID_FRAGMENT}
`;


export const WHAT_WE_DO_META_DATA = gql`
query WhatWeDoMetaData($id: ID ,  $status: Status){
  page: pageWhatWeDo(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
 }
}
`;