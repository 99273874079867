import { gql } from "@apollo/client";

export const GALLERY_FRAGMENT= gql`
fragment galleryFragment on imageItem {
    img {
        urls
    }
    content: description
    title:  displayText
}

`;

export const IMAGE_GALLERY_CAROUSEL_FRAGMENT= gql`
fragment galleryCarouselFragment on imageGalleryCarousel {
    sectionIntro {
      content
      title
    }
    mods
    removeBottomSpacing
    removeTopSpacing
    items {
        contentItems {
          ... galleryFragment
        }
    }
  }
${GALLERY_FRAGMENT}
`;
