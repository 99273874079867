import * as React from 'react';
import "./wysiwyg.scss";
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

interface IWYSIWYGProps {
    html?: string
    contentItems?: {
        html: string
        removeTopSpacing: boolean
        removeBottomSpacing: boolean
        mods?: BackgroundColours
    }[]
    isFullWidth?: boolean
    noPadding?: boolean
};

export const WYSIWYG: React.FC<IWYSIWYGProps> = ({ html, contentItems, isFullWidth, noPadding }) => {
    if (!contentItems?.length && !html) return null;
    const item = contentItems?.[0];

    const updateImgSrc = (htmlContent: string) => {
        return htmlContent.replace(/<img src="/gi, `<img src="${process.env.REACT_APP_CMS_BASE_URL_NEW}`);
    }

    const srcContent = html ? updateImgSrc(html) : updateImgSrc(item?.html || "");

    return (
        <ComponentContainer bg={item?.mods} removeTopSpacing={item?.removeTopSpacing} removeBottomSpacing={item?.removeBottomSpacing}>
            <div className={"wysiwyg" + (isFullWidth ? " wysiwyg--full-width" : "") + (noPadding ? " wysiwyg--no-padd" : "")}>
                <div className="wysiwyg__inner">
                    <div className="wysiwyg__content" dangerouslySetInnerHTML={{ __html: srcContent }}></div>
                </div>
            </div>
        </ComponentContainer>
    );

}
