import * as React from 'react';
import { Icon, Icons } from "../icon/icon";
import "./social.scss";
import { useQuery } from "@apollo/client";
import { SocialPlatformsDocument } from '../../generated/typed-document-nodes';
import { contentState } from '../../contexts/siteData';



export const Social: React.FC<{ mods?: string, onBlurHandler?: () => void, onFocusHandler?: () => void, items: any[] }> = ({ mods, onBlurHandler, onFocusHandler, items }) => {
    
    if (!items)
        return <React.Fragment></React.Fragment>;

    // console.log(items);

    let data = {
        "socialPlatform": 
            items.map(itm => {
                return {
                    "__typename": "socialPlatform",
                    "name": itm.name,
                    "url": itm.url
                };
            })
    };

    const checkBlurHander = () => {
        if (onBlurHandler) onBlurHandler();
    }

    const checkFocusHander = () => {
        if (onFocusHandler) onFocusHandler();
    }

    return <div className={"social" + (mods ? ` ${mods}` : "")}>
        <ul role="menu">
            {
                data.socialPlatform.map((item, index) => {
                    return <li key={index} role="menuitem">
                        <a
                            href={item.url}
                            onFocus={() => { checkFocusHander() }}
                            onBlur={() => { checkBlurHander() }}
                        >
                            <Icon icon={Icons[item.name]} />
                            <span className="sr-only">{item.name}</span>
                        </a>
                    </li>
                })
            }
        </ul>
    </div>
}