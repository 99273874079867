import * as React from 'react';
import { PostCardGridNew } from '../postCardGridNew/postCardGridNew';

export const UpdateCardGrid: React.FC<{updates: any}> = (updates) => {

    if(!updates || !updates["contentItems"]) return <React.Fragment></React.Fragment>;

    const langcode = updates["langcode"];

    function locDate(dtVal: string, langcode: string) {
        let dtStr = null;
        if (dtVal) {
            let dt = new Date(dtVal);
            var options: any = { year: 'numeric', month: 'long', day: 'numeric' };
            dtStr = dt.toLocaleDateString(`${langcode}-${langcode}`, options);
        }
        return dtStr;
    }

    const items = updates["contentItems"].map(item =>  {

        let obj = {
            "title": item.title,
            "content": item.description,
            "url": item.url,
            "isFeature": false,
            "isExternal": item.url?.startsWith('http'),
            "img": {
                "__typename": "MediaField",
                "urls": [
                    item.image
                ]
            },
            "date": locDate(item.date, langcode)
        };

        return obj;
    });

    return <PostCardGridNew type="article" contentItems={items} />
}