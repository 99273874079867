import utilsUI from '../../core/utilsUI';
import * as React from 'react';
import "./bgImg.scss";

export const BgImg: React.FC<{
    urls: string[]
    title?: string
    mods?: string
}> = ({ urls, title, mods }) => {

    if (!urls) return <React.Fragment></React.Fragment>;
    if(urls.length == 0) return <React.Fragment></React.Fragment>;
    return <div className={"bg-img" + (mods ? ` ${mods}` : "")} style={{ backgroundImage: `url(${utilsUI.checkImgSrc(urls)})` }}>
        <span className="sr-only">{title}</span>
    </div>

}