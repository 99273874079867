export const MiscTitles = {
    english: {
        factSheet: "Fact Sheet",
        next: "Next",
    },
    russian: {
        factSheet: "Информационный бюллетень",
        next: "Дальше",
    },
    chinese: {
        factSheet: "情况说明书",
        next: "下一页",
    },
    arabic: {
        factSheet: "بيان حقائق",
        next: "التالي",
    },
    french: {
        factSheet: "Fiche descriptive",
        next: "Suivant",
    },
    portuguese: {
        factSheet: "Ficha Informativa",
        next: "Próximo",
    },
    spanish: {
        factSheet: "Ficha Informativa",
        next: "Siguiente",
    }
};

exports = {
    MiscTitles
};