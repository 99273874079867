import * as React from 'react';
import "./colourCard.scss";
import { Icon, Icons } from "../icon/icon";
import utilsUI from '../../core/utilsUI';
import { useContentQuery } from "../../hooks/use-content-query";

interface IColourCardProps {
    contentItemId?: string
    contentType?: string
    contentItem?: any
};

export const ColourCard: React.FC<IColourCardProps> = ({ contentItemId, contentType, contentItem }) => {

    if (!contentItem) return <React.Fragment></React.Fragment>;

    var isExternal = contentItem['isExternal'] ;
    return <div className="colour-card">
        <a href={isExternal ?utilsUI.cleanPath(contentItem['url']) :contentItem['url'] } title={contentItem['title']} target={isExternal ?"_blank" :"none" }>
            <div className="colour-card__icon">
                <Icon icon={Icons.Chevron} />
            </div>
            <div className="colour-card__content">
                <h3>{contentItem['title']}</h3>
                <p>{contentItem['content']}</p>
            </div>
        </a>
    </div>

}