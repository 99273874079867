import React from 'react';
import { useIsVisible } from '../hooks/use-is-visible';

export const AnimatedElement: React.FC<{ children: React.ReactNode, classList?: string }> = ({ children, classList }) => {
    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"animated" + (isVisible ? ' is-visible' : '') + (classList ? ` ${classList}` : "")} ref={ref}>
            {children}
        </div>
    );
};