import gql from "graphql-tag";

export const CONTENT_PAGE_CARDS_FRAGMENT = gql`
fragment contentPageCardsFragment on contentPageCards {
  content
  items
  {
    contentItems {
      ... on campaignCard {
        page: campaign {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
      ... on articleCard {
        page: article {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
      ... on pageCard {
        page {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
      ... on factSheetCard {
        page: factSheet {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
    }
  }
}
  
  `;