import gql from "graphql-tag";

export const RELATED_CAMPAIGNS_FRAGMENT = gql `
fragment relatedCampaignsFragment on relatedCampaigns {
  sectionIntro {  
    title
    content
  }
  mods
  removeBottomSpacing
  removeTopSpacing
  contentType
  contentItemId
}
`;