import * as React from 'react';
import "./definitionItem.scss";
import { Icon, Icons } from "../icon/icon";

export interface IDefinitionItemProps {
    contentItemId: string
    title: string
    content: string
    children?: React.ReactNode
};

export const DefinitionItem: React.FC<IDefinitionItemProps> = ({ contentItemId, title, content, children }) => {

    const itemContent = React.useRef(null);
    const [contentActive, toggleContent] = React.useState(false);

    if(!contentItemId) return <React.Fragment></React.Fragment>

    return <div className={"definition-item" + (contentActive ? " active" : "")}>
        <div className="definition-item__inner">
            <button
                role="button"
                onClick={() => { toggleContent(!contentActive) }}
                aria-expanded={(contentActive ? "true" : "false")}
                id={contentItemId}
                aria-controls={`${contentItemId}-content`}
                className="definition-item__title h3"
            >
                {title}
                <Icon icon={Icons.Chevron} />
            </button>
            <div 
                className="definition-item__content"
                id={`${contentItemId}-content`}
                aria-labelledby={contentItemId}
                role="region"
                ref={itemContent}
                hidden={!contentActive ? true : false}
            >
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                {children ? <div className="definition-item__resources">
                    {children}
                </div> : null}
            </div>
        </div>
    </div>

}