import gql from "graphql-tag";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const OUR_CAMPAIGNS_PAGE = gql`
query OurCampaignsComponents($id: ID, $status: Status){
  pageOurCampaigns(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    campaignCardGrid: gridSettings {
      loadMore
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}

${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
`;

export const OUR_CAMPAIGNS_META_DATA = gql`
query OurCampaignsMetaDatas($id: ID, $status: Status){
  page: pageOurCampaigns(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;