import * as React from 'react';
import "./columnCarousel.scss";
import { Carousel } from "../carousel/carousel";
import { BgImg } from "../bgImg/bgImg";
import utilsUI from "../../core/utilsUI";

interface IColumnCarouselItemProps {
    mods?: string
    thumbnail: {
        urls: string[]
    }
    title: string
    content?: string

}

interface IColumnCarouselProps {
    contentItems: { 
        sectionIntro?: {
            title: string
            content?: string
        },
        items: {
            contentItems: IColumnCarouselItemProps[]
        }
    }[]
}

const ColumnCarouselItem: React.FC<IColumnCarouselItemProps> = ({ mods, title, content , thumbnail }) => {

    
    return <div className={"column-carousel__item" + (mods ? ` ${mods}` : "")}>
        <div className="column-carousel__item-img">
            <BgImg
               {... thumbnail}
            />
        </div>
        <div className="column-carousel__item-content">
            <h4>{utilsUI.truncateWords(title, 55)}</h4>
            <p>{utilsUI.truncateWords(content, 125)}</p>
        </div>
    </div>
}

export const ColumnCarousel: React.FC<IColumnCarouselProps> = ({ contentItems }) => {

    if (!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length == 0) return <React.Fragment></React.Fragment>
      
    return <div className="column-carousel">
        <div className="column-carousel__wrapper">
            <div className="column-carousel__inner">
                <div className="column-carousel__left">
                    <h2 >{contentItems[0].sectionIntro?.title}</h2>
                    {contentItems[0].sectionIntro?.content ? <p>{contentItems[0].sectionIntro?.content}</p> : null}
                </div>
                <div className="column-carousel__right">
                    <div className="column-carousel__right-inner">
                        <Carousel
                            mods="carousel--no-spacing"
                        >
                            {contentItems[0].items?.contentItems.map((item, index) => {
                                return <ColumnCarouselItem
                                    {...item}
                                    key={index}
                                    mods={(index % 2 == 0 ? " even" : "")}
                                />
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>

}