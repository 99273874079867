import * as React from 'react';
import "./termBlockRow.scss";
import { SectionIntro, ISectionIntroProps } from "../sectionIntro/sectionIntro";
import { TermBlock, ITermBlockProps } from "../termBlock/termBlock";
import { Toolbar } from '../toolbar/toolbar';


interface ITermBlockRowProps {
    contentItems: {
        sectionIntro: ISectionIntroProps
        items: { contentItems: ITermBlockProps[] }
        toolbar?:
        {
            file?: {
                urls: string[]
            }
            share?: boolean
        }
    }[]
}

export const TermBlockRow: React.FC<ITermBlockRowProps> = ({ contentItems }) => {
    
    if (!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length == 0) return <React.Fragment></React.Fragment>

    return <div className="component-wrapper">
        <div className="term-block-row">

            {contentItems[0].sectionIntro ? <SectionIntro
                {...contentItems[0].sectionIntro}
                removeTopSpacing
            /> : null}

            <div className="term-block-row__inner">
                {contentItems[0].items?.contentItems.map((item, index) => {
                    if(item != null) {
                        return <div className="term-block-row__item" key={index}>
                            <TermBlock {...item} />
                        </div>
                    }
                })}
                {contentItems[0].toolbar? <Toolbar  {... contentItems[0].toolbar} /> : null }
            </div>

        </div>
    </div>
}