import gql from "graphql-tag";
import { ACCORDION_FRAGMENT, SIMPLE_ACCORDION_FRAGMENT } from "./accordion-fragment";

export const ACCORDION_GROUP_FRAGMENT = gql`
fragment accordionGroupFragment on accordionGroup {
  title: displayText
  items {
    contentItems {
      ... accordionFragment 
    }
  }
}
${ACCORDION_FRAGMENT}
`;

export const SIMPLE_ACCORDION_GROUP_FRAGMENT = gql`
fragment simpleAccordionGroupFragment on simpleAccordionGroup {
  title: displayText
  mods
  removeBottomSpacing
  removeTopSpacing
  items {
    contentItems {
      ... simpleAccordionFragment 
    }
  }
}
${SIMPLE_ACCORDION_FRAGMENT}
`;