import utilsUI from "./utilsUI";

/*
    Manage local storage of dictionary IDs
*/
class DictionaryManager {

    dataStore: null;

    getLocalDictionaryData = () => {
        if (this.dataStore) return this.dataStore;
        const dictionary = localStorage.getItem('unfeDictionary');
        if (!dictionary) return false;
        const data = JSON.parse(dictionary);
        this.dataStore = data;

        return this.dataStore;
    }

    checkDataDateRefresh = () => {

        const dictionary = this.getLocalDictionaryData();
        if (!dictionary) return true;

        // get current date stamp (milliseconds)
        // get length of single day (milliseconds)
        // retrieve saved date from local storage (value in milliseconds)
        // subtract saved date from current date & convert to positive number
        // if difference is greater than or equal to length of day, build a new dictionary

        const currentDay = new Date().getTime();
        const oneDay = (1 * 24 * 60 * 60 * 1000);
        const savedDate = dictionary['date'] * 1;
        let diffDate = currentDay - savedDate;
        diffDate = diffDate < 0 ? diffDate * -1 : diffDate;

        if (diffDate >= oneDay) return true;
        return false;

    }

    buildDictionary = (data: any) => {

        let dictionary = {};

        if (!data) return null;

        if (this.checkDataDateRefresh()) {

            data.map((menuitem) => {
                menuitem.contentItems.map((item) => {
                    const itemId = item['content']['contentItemIds'][0];
                    const contentItem = item['content']['contentItems'][0];
                    if (itemId && contentItem) {
                        const newPath = `/${menuitem["localization"]["culture"] == "en" ? "" : menuitem["localization"]["culture"] + "/"}${(!contentItem['path']) ? "" : utilsUI.cleanPath(contentItem['path'])}`;
                        dictionary[itemId] = {
                            path: newPath,
                            contentType: contentItem['contentType'],
                            title: item['displayText']
                        };
                        const temp = recursiveDictionarySearch(item.subNav?.nav, newPath);
                        dictionary = { ...dictionary, ...temp };
                    }
                });
            });

            dictionary['date'] = String(new Date().getTime());
            this.setDictionaryData(dictionary);
        }


    }

    setDictionaryData = (data: any) => {
        localStorage.removeItem('unfeDictionary');
        localStorage.setItem('unfeDictionary', JSON.stringify(data));
    }

    getDictionaryData = () => {
        const dictionary = this.getLocalDictionaryData();
        if (!dictionary) return null;
        return dictionary;
    };

    getItemById = (contentId: string) => {
        const dictionary = this.getLocalDictionaryData();
        if (!dictionary) return null;
        return dictionary[contentId];
    }

};


const recursiveDictionarySearch = (data: any, parentPath: string) => {

    let dictionary = {};
    if (data) {

        for (let i = 0; i < data.length; i++) {

            const itemId = data[i]['content']['contentItemIds'][0];
            const contentItem = data[i]['content']['contentItems'][0];
            if (itemId && contentItem) {
                const newPath = `${parentPath}/${utilsUI.cleanPath(contentItem['path'])}`;
                dictionary[itemId] = {
                    path: newPath,
                    contentType: contentItem['contentType'],
                    title: data[i]['displayText']
                };

                if (data[i].subNav) dictionary = { ...dictionary, ...recursiveDictionarySearch(data[i].subNav?.contentItems, newPath) };
            }

        }
    }

    return dictionary;

}


/*
    Manage local storage of current region
*/
class RegionManager {

    setLocalRegion = (region: any) => {
        localStorage.removeItem('currentLang');
        localStorage.setItem('currentLang', JSON.stringify(region));
    }

    getLocalRegion = () => {
        const region = localStorage.getItem('currentLang');
        if (!region) return null;
        return JSON.parse(region);
    }

}

export const dictionaryManager = new DictionaryManager();
export const regionManager = new RegionManager();

exports = {
    regionManager,
    dictionaryManager
};