export const SiteTitles = {
    english: {
        title: "UN Free & Equal",
        notFound: {
            title: "404: Page Not Found",
            content: "The page you are looking for doesn't exist, no longer exists or has been moved."
        }
    },
    russian: {
        title: "Кампания ООН «Свободные и равные»",
        notFound: {
            title: "Ошибка 404: Страница не найдена",
            content: "The page you are looking for doesn't exist, no longer exists or has been moved."
        }
    },
    chinese: {
        title: "联合国自由与平等运动",
        notFound: {
            title: "404 not found",
            content: "The page you are looking for doesn't exist, no longer exists or has been moved."
        }
    },
    arabic: {
        title: "مبادرة الأمم المتحدة ”أحرار ومتساوون”",
        notFound: {
            title: "404 not found",
            content: "The page you are looking for doesn't exist, no longer exists or has been moved."
        }
    },
    french: {
        title: "Libres et égaux Nations Unies",
        notFound: {
            title: "404: page non trouvée",
            content: "The page you are looking for doesn't exist, no longer exists or has been moved."
        }
    },
    portuguese: {
        title: "UN Free & Equal",
        notFound: {
            title: "404 not found",
            content: "The page you are looking for doesn't exist, no longer exists or has been moved."
        }
    },
    spanish: {
        title: "Libres e Iguales",
        notFound: {
            title: "Error 404 - Página no encontrada",
            content: "La página que busca no existe o se ha movido."
        }
    }
};

exports = {
    SiteTitles
};