import * as React from 'react';
import "./instagram.scss";
import { Social } from "../social/social";
import { Carousel } from "../carousel/carousel";
import { InstagramItem } from "../instagramItem/instagramItem";
import { InstagramQueryDocument } from '../../generated/typed-document-nodes';
import { useQuery } from '@apollo/client';
import { contentState } from '../../contexts/siteData';

interface IInstagramProps {
    contentItems: {
        account: string
        title: string
    }[]
};

const feedData = {
    url: "https://graph.instagram.com/me/media?fields=",
    fields: "caption,media_url,media_type,permalink,timestamp,username"
};

export const Instagram: React.FC<IInstagramProps> = ({ contentItems }) => {
    
    const [imgs, appendImgs] = React.useState(null);
    const [currentIndex, updateIndex] = React.useState(0);
    const { loading, data } = useQuery(InstagramQueryDocument,  { variables: {  status: contentState } });
    const photosCalled = React.useRef(false);
    const token = React.useRef(null);
    const offlineFeeds = React.useRef(null);

    const getPhotos = () => {

        if(!photosCalled.current) {

            photosCalled.current = true;
            let url = `${feedData.url}${feedData.fields}&access_token=${token.current}`;

            fetch(url)
            .then((resp) => {
                if (resp.status == 200) {
                    return resp.json();
                }
                return resp.json().then(Promise.reject.bind(Promise));
            })
            .then((results) => {
                if (results.data) updateImgs(results.data);
            })
            .catch((err) => {
                console.log(err);
                appendImgs(offlineFeeds.current);
            });
        }

    }

    const updateImgs = (data: any) => {

        let payload = [];

        for(let i = 0; i < data.length; i++){
            if(i > 20) break;
            const curItem = data[i];
            payload.push({
                src: curItem.media_url,
                title: `Instagram ${curItem.media_type} + ${curItem.username} - ${curItem.timestamp}`,
                mediaType: curItem.media_type
            });
        }
            
        appendImgs(payload);

    }


    const carouselIndexCallback = (index: number) => {
        updateIndex(index);
    }

    const outputCarousel = () => {
        return <Carousel 
            mods="carousel--no-spacing"
            curIndexCallback={carouselIndexCallback}
        >
            {imgs.map((item, index) => {
                return <InstagramItem
                    key={index}
                    {...item}
                    url={item['url'] ? item['url'] : item['src']}
                    isActive={index == currentIndex ? true : false}
                />
            })}
        </Carousel>
    }

    if (!contentItems) return <React.Fragment></React.Fragment>
    if(contentItems.length == 0) return <React.Fragment></React.Fragment>
    
    if (loading || !data) return <React.Fragment></React.Fragment>
    if (!data.instagram || data.instagram.length == 0) return <React.Fragment></React.Fragment>;
    token.current = data.instagram[0].token;
    offlineFeeds.current = data.instagram[0].imgs;
    {getPhotos()}

    if ((imgs == null || imgs.length == 0)) return <React.Fragment></React.Fragment>
    return <div className="instagram">
        <div className="instagram__inner">
            <div className="instagram__left">
                <h3>{contentItems[0].title}</h3>
                <h5>{contentItems[0].account}</h5>
                <div className="instagram__platforms">
                    <p>Follow us</p>
                    <Social items={[]} />
                </div>
            </div>
            <div className="instagram__right">
                <div className="instagram__right-inner">
                    <div className="instagram__carousel">
                        {imgs != null ? outputCarousel() : null}
                    </div>
                    <div className="instagram__platforms">
                        <p>Follow us</p>
                        <Social items={[]} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}