
import * as React from 'react';
import { useApolloClient } from "@apollo/client";
import { ContentViewController } from "./contentViewController/contentViewController";
import { Templates } from '../controllers/templates';
import { contentState } from '../contexts/siteData';

function mapThumbnail(thumbnail: DrupalParagraphThumbnail, langcode: string) {
    if (!thumbnail) return null;
    
    let newObj: any = {};
    Object.assign(newObj, thumbnail);

    if (!thumbnail.link) {
        if (thumbnail.buttons && thumbnail.buttons.length > 0) {

            let link: DrupalLink = {
                "__typename": "n/a",
                "internal": thumbnail.buttons[0].link?.internal ?? true,
                "title": thumbnail.buttons[0].text,
                "url": thumbnail.buttons[0].link?.url,
            };
            newObj.link = link;
        } else {
            let link: DrupalLink = {
                "__typename": "n/a",
                "internal": true,
                "title": null,
                "url": null
            };
            newObj.link = link;
        }
    }

    return {
        "url": ((newObj.link?.internal ?? true) && newObj?.link?.url) ? `/${langcode}${newObj?.link?.url}` : (newObj?.link?.url ?? null),
        "urlText": newObj.link?.title,
        "isExternal": !(newObj?.link?.internal ?? true),
        "img": thumbnail?.image ? {
            "urls": [ thumbnail?.image?.variations[0]?.url ],
            "title": thumbnail?.image?.alt
        } : null,
        "title": thumbnail?.title,
        "label": newObj.label,
        "date": newObj.date?.time,
        "content": thumbnail?.body?.value ?? thumbnail?.description?.value,
        "color": newObj.color,
    };
}

function mapCampaign (ddata: DrupalNode, compIndex: any, pageMetaData: DrupalPageMetadata): any {    
    const ParagraphEditorWidget = compIndex["ParagraphEditorWidget"];
    const ParagraphEditorWidget1 = compIndex["ParagraphEditorWidget1"];
    const ParagraphEditorWidget2 = compIndex["ParagraphEditorWidget2"];
    const ParagraphEditorWidget3 = compIndex["ParagraphEditorWidget3"]; 
    const ParagraphEditorWidget4 = compIndex["ParagraphEditorWidget4"]; 
    const ParagraphYoutubeWidget = compIndex["ParagraphYoutubeWidget"];
    const ParagraphYoutubeWidget1 = compIndex["ParagraphYoutubeWidget1"];
    const ParagraphYoutubeWidget2 = compIndex["ParagraphYoutubeWidget2"];
    const ParagraphYoutubeWidget3 = compIndex["ParagraphYoutubeWidget3"];
    const ParagraphCarouselsWidget = compIndex["ParagraphCarouselsWidget"];
    const ParagraphHeroBannersWidget = compIndex["ParagraphHeroBannersWidget"];
    const ParagraphAccordionsWidget = compIndex["ParagraphAccordionsWidget"];
    const ParagraphThumbnailsWidget = compIndex["ParagraphThumbnailsWidget"];

    let videos = ddata.layout[ParagraphYoutubeWidget]?.videos ?? [];
    let videos1 = ddata.layout[ParagraphYoutubeWidget1]?.videos ?? [];
    let videos2 = ddata.layout[ParagraphYoutubeWidget2]?.videos ?? [];
    let videos3 = ddata.layout[ParagraphYoutubeWidget3]?.videos ?? [];

    const vColor = ddata.layout[ParagraphYoutubeWidget]?.color;
    const vColor1 = ddata.layout[ParagraphYoutubeWidget1]?.color;
    const vColor2 = ddata.layout[ParagraphYoutubeWidget2]?.color;
    const vColor3 = ddata.layout[ParagraphYoutubeWidget3]?.color;

    function mapVideo(orderNo: any, video: any, color: any) {
        if (!video || !video.videoId) return null;

        return {
            "orderNo": orderNo,
            "componentType": "youtubeVideo",
            "contentItems": [{
                    "__typename": "youtubeVideo",
                    "contentItemId": video.videoId,
                    "img": {
                        "__typename": "MediaField",
                        "urls": []
                    },
                    "videoId": video.videoId,
                    "mods": color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true
                }
            ]
        };
    };

    return {
        "contentBanner": {
            "orderNo": -2,
            "__typename": "PageMetaData",
            "title": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.title ?? ddata.title,
            "subTitle": ddata.description?.value,
            "label": null,
            "heroImageUrl": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.image?.variations[0]?.url,
            "showHeadings": true,
            "buttons": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.buttons,
        },
        "heroImage": (ddata.image?.variations[0]?.url) ? {
            "orderNo": -1,
            "componentType": "heroImage",
            "contentItems": [
                {
                    "__typename": "heroImage",
                    "contentItemId": "n/a",
                    "img": {
                        "__typename": "MediaField",
                        "urls": [
                            ddata.image?.variations[0]?.url
                        ]
                    }
                }
            ]
        } : null,
        "youtubeVideo": mapVideo(ParagraphYoutubeWidget, videos[0], vColor),
        "youtubeVideo01": mapVideo(ParagraphYoutubeWidget, videos[1], vColor),
        "youtubeVideo02": mapVideo(ParagraphYoutubeWidget, videos[2], vColor),
        "youtubeVideo03": mapVideo(ParagraphYoutubeWidget, videos[3], vColor),
        "youtubeVideo1": mapVideo(ParagraphYoutubeWidget1, videos1[0], vColor1),
        "youtubeVideo11": mapVideo(ParagraphYoutubeWidget1, videos1[1], vColor1),
        "youtubeVideo12": mapVideo(ParagraphYoutubeWidget1, videos1[2], vColor1),
        "youtubeVideo13": mapVideo(ParagraphYoutubeWidget1, videos1[3], vColor1),
        "youtubeVideo2": mapVideo(ParagraphYoutubeWidget2, videos2[0], vColor2),
        "youtubeVideo21": mapVideo(ParagraphYoutubeWidget2, videos2[1], vColor2),
        "youtubeVideo22": mapVideo(ParagraphYoutubeWidget2, videos2[2], vColor2),
        "youtubeVideo23": mapVideo(ParagraphYoutubeWidget2, videos2[3], vColor2),  
        "youtubeVideo3": mapVideo(ParagraphYoutubeWidget3, videos3[0], vColor3),
        "youtubeVideo31": mapVideo(ParagraphYoutubeWidget3, videos3[1], vColor3),
        "youtubeVideo32": mapVideo(ParagraphYoutubeWidget3, videos3[2], vColor3),
        "youtubeVideo33": mapVideo(ParagraphYoutubeWidget3, videos3[3], vColor3),  
        "content": {
            "orderNo": ParagraphEditorWidget,
            "componentType": "content",
            "contentItems": [
                {
                    "__typename": "content",
                    "contentItemId": "n/a",
                    "html": ddata.layout[ParagraphEditorWidget]?.body?.value,
                    "mods": ddata.layout[ParagraphEditorWidget]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true
                }
            ]
        },
        "mediaDownloadCarousel": {
            "orderNo": ParagraphCarouselsWidget,
            "componentType": "mediaDownloadCarousel",
            "contentItems": [
                {
                    "__typename": "mediaDownloadCarousel",
                    "contentItemId": "n/a",
                    "sectionIntro": {
                        "__typename": "SectionIntro",
                        "title": null,
                        "content": null
                    },
                    "mods": null,
                    "removeBottomSpacing": false,
                    "removeTopSpacing": false,
                    "items": {
                        "__typename": "BagPart",
                        "contentItems": 
                            ddata.layout[ParagraphCarouselsWidget]?.images?.map(img => {
                                return {
                                    "__typename": "downloadMediaCard",
                                    "contentItemId": "n/a",
                                    "bgImg": {
                                        "__typename": "MediaField",
                                        "urls": [
                                            img.image?.url
                                        ]
                                    },
                                    "media": {
                                        "__typename": "MediaField",
                                        "urls": [
                                            img.image?.url
                                        ]
                                    }
                                };
                            }),
                    }
                }
            ]
        },
        "content1": {
            "orderNo": ParagraphEditorWidget1,
            "componentType": "content",
            "contentItems": [
                {
                    "__typename": "content",
                    "contentItemId": "n/a",
                    "html": ddata.layout[ParagraphEditorWidget1]?.body?.value,
                    "mods": ddata.layout[ParagraphEditorWidget1]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true
                }
            ]
        },
        "content2": {
            "orderNo": ParagraphEditorWidget2,
            "componentType": "content",
            "contentItems": [
                {
                    "__typename": "content",
                    "contentItemId": "n/a",
                    "html": ddata.layout[ParagraphEditorWidget2]?.body?.value,
                    "mods": ddata.layout[ParagraphEditorWidget2]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true
                }
            ]
        },
        "content3": {
            "orderNo": ParagraphEditorWidget3,
            "componentType": "content",
            "contentItems": [
                {
                    "__typename": "content",
                    "contentItemId": "n/a",
                    "html": ddata.layout[ParagraphEditorWidget3]?.body?.value,
                    "mods": ddata.layout[ParagraphEditorWidget3]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true
                }
            ]
        },
        "content4": {
            "orderNo": ParagraphEditorWidget4,
            "componentType": "content",
            "contentItems": [
                {
                    "__typename": "content",
                    "contentItemId": "n/a",
                    "html": ddata.layout[ParagraphEditorWidget4]?.body?.value,
                    "mods": ddata.layout[ParagraphEditorWidget4]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true
                }
            ]
        },
        "__typename": "campaign",
        "contentItemId": "n/a",
        "title": ddata.title,
        "description": ddata.description?.value,
        "path": `${pageMetaData.langcode}${pageMetaData.url}`,
        "keywords": null,
        "isFeature": false,
        "simpleAccordionGroup": {
            "orderNo": ParagraphAccordionsWidget,
            "__typename": "simpleAccordionGroup",
            "contentItems": [
                {
                    "__typename": "simpleAccordionGroupFragment",
                    "title": ddata.layout[ParagraphAccordionsWidget]?.title,
                    "mods": ddata.layout[ParagraphAccordionsWidget]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true,
                    "items": {
                        "__typename": "BagPart",
                        "contentItems": 
                            ddata.layout[ParagraphAccordionsWidget]?.accordions?.map(acc => {
                                return {
                                    "__typename": "simpleAccordion",
                                    "contentItemId": "n/a",
                                    "title": acc.title,
                                    "content": acc.body?.value
                                };
                            })
                    }
                }
            ]
        },
        "discoverMore": {
            "orderNo": ParagraphThumbnailsWidget,
            "__typename": "BagPart",
            "colour": null,
            "contentItems": [
                {
                    "__typename": "ImgTitleContentCardGroup",
                    "removeTopSpacing": true,
                    "removeBottomSpacing": true,
                    "sectionIntro": {
                        "__typename": "SectionIntro",
                        "title": ddata.layout[ParagraphThumbnailsWidget]?.title,
                        "content": ddata.layout[ParagraphThumbnailsWidget]?.body?.value,
                        "removeTopSpacing": true,
                        "removeBottomSpacing": false,
                        "className": null,
                    },
                    "items": {
                        "__typename": "BagPart",
                        "contentItems": ddata.layout[ParagraphThumbnailsWidget]?.thumbnail?.map(t => {
                            const tmb = mapThumbnail(t, pageMetaData.langcode);
                            if (!tmb) return null;

                            return {
                                "__typename": "pageCard",
                                "page": {
                                    "__typename": "ContentPickerField",
                                    "contentItems": [
                                        {
                                            "contentItemId": null,
                                            "contentType": "pageOurCampaigns",
                                            "contentItem": tmb
                                        }
                                    ]
                                }
                            };
                        })?.filter(t => t != null)
                    }
                }
            ]
        },
        "subscriptionBlock": {
            "__typename": "subscriptionBlock",
            "displaySubscription": true
        }
    };
}

function locDate(dtVal: string, langcode: string) {
    let dtStr = null;
    if (dtVal) {
        let dt = new Date(dtVal);
        var options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        dtStr = dt.toLocaleDateString(`${langcode}-${langcode}`, options);
    }
    return dtStr;
}

function mapArticle (ddata: DrupalNode, compIndex: any, pageMetaData: DrupalPageMetadata, pageUpdates: any[]): any {
    const ParagraphEditorWidget = compIndex["ParagraphEditorWidget"];
    const ParagraphYoutubeWidget = compIndex["ParagraphYoutubeWidget"];
    const ParagraphCardsWidget = compIndex["ParagraphCardsWidget"];

    // console.log(compIndex);

    return {
        "contentBanner": {
            "title": ddata.title,
            "subTitle": ddata.description?.value,
            "label": locDate(ddata.date?.time, pageMetaData.langcode),
            "isShort": true,
            "showHeadings": true
        },
        "__typename": "article",
        "contentItemId": "n/a",
        // "title": "TODO: rootTitle",
        // "content": ddata.description?.value,
        // "keywords": null,
        "path": `${pageMetaData.langcode}${pageMetaData.url}`,
        // "date": "TODO: rootDate",
        "tags": {
            "__typename": "TaxonomyField",
            "termContentItemIds": []
        },
        "locations": {
            "__typename": "TaxonomyField",
            "termContentItemIds": [
                "4j6jzvjfymabr2f77jtb2b8swg"
            ]
        },
        "thumbnail": (ddata.image?.variations[0]?.url) ? {
            "__typename": "MediaField",
            "urls": [
                ddata.image?.variations[0]?.url
            ]
        } : null,
        "youtubeVideo": (ddata.layout[ParagraphYoutubeWidget]?.videos[0]?.videoId) ? {
            "__typename": "BagPart",
            "contentItems": [
                {
                    "__typename": "youtubeVideo",
                    "contentItemId": "n/a",
                    "img": {
                        "__typename": "MediaField",
                        "urls": [
                            ddata.image?.variations[0]?.url
                        ]
                    },
                    "videoId": ddata.layout[ParagraphYoutubeWidget]?.videos[0]?.videoId
                }
            ]
        } : null,
        "wysiwyg": {
            "__typename": "HtmlBodyPart",
            "html": ddata.layout[ParagraphEditorWidget]?.body?.value
        },
        "galleryCarousel": ddata.layout[ParagraphCardsWidget]?.card ? {
            "__typename": "BagPart",
            "items": 
                ddata.layout[ParagraphCardsWidget]?.card?.map(crd => {
                    return {
                        img: {
                            urls: [
                                crd.image?.variations[0]?.url
                            ],
                        },
                        title: crd.title,
                        content: crd.description?.value
                    };
                })
        }: null,
        // "relatedContent": {
        //     "__typename": "BagPart",
        //     "contentItems": [
        //         {
        //             "__typename": "relatedArticles",
        //             "sectionIntro": {
        //                 "__typename": "SectionIntro",
        //                 "title": "Discover more",
        //                 "content": null
        //             },
        //             "removeBottomSpacing": true,
        //             "removeTopSpacing": true,
        //             "contentType": "relatedArticles",
        //             "contentItemId": "49eg4n7c2m5bwzkefn6t95r6nt"
        //         }
        //     ]
        // },
        "subscriptionBlock": {
            "__typename": "subscriptionBlock",
            "displaySubscription": true
        }
    };
}

function mapFactSheet (ddata: DrupalNode, compIndex: any, pageMetaData: DrupalPageMetadata): any {
    const ParagraphDownloadButtonWidget = compIndex["ParagraphDownloadButtonWidget"];
    const ParagraphEditorWidget = compIndex["ParagraphEditorWidget"];
    const ParagraphEditorWidget1 = compIndex["ParagraphEditorWidget1"];
    const ParagraphNumbersWidget = compIndex["ParagraphNumbersWidget"];
    const ParagraphAccordionsWidget = compIndex["ParagraphAccordionsWidget"];
    const ParagraphHeroBannersWidget = compIndex["ParagraphHeroBannersWidget"];

    return {
        // "contentBanner": {
        //     "title": ddata.title,
        //     "subTitle": ddata.description.value,
        //     "label": null,
        //     "isShort": true,
        //     "showHeadings": true
        // },
        "contentBanner": {
            "__typename": "PageMetaData",
            "title": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.title ?? ddata.title,
            "subTitle": ddata.description?.value,
            "label": null,
            "heroImageUrl": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.image?.variations[0]?.url,
            "showHeadings": true,
            "buttons": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.buttons,
        },
        "title": ddata.title,
        "description": ddata.description.value,
        "path": `${pageMetaData.langcode}${pageMetaData.url}`,
        "toolbar": {
            "__typename": "toolbar",
            "share": false,
            "file": {
                "__typename": "MediaField",
                "urls": [
                    ddata.layout[ParagraphDownloadButtonWidget]?.downloadfile?.url
                ]
            }
        },
        "htmlContent": {
            "__typename": "BagPart",
            "contentItems": [
                {
                    "__typename": "content",
                    "html": ddata.layout[ParagraphEditorWidget]?.body?.value
                }
            ]
        },
        "figuresBlock": {
            "__typename": "BagPart",
            "contentItems": [
                {
                    "__typename": "figuresBlock",
                    "items": {
                        "__typename": "BagPart",
                        "contentItems": 
                            ddata.layout[ParagraphNumbersWidget]?.number?.map(num => {
                                return {
                                    "__typename": "figureBlock",
                                    "content": num.body?.value,
                                    "unit": "", //"%",
                                    "value": num.text
                                };
                            })                       
                    },
                    "mods": ddata.layout[ParagraphNumbersWidget]?.color,
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true,
                    "sectionIntro": {
                        "__typename": "SectionIntro",
                        "title": null,
                        "content": null
                    }
                }
            ]
        },
        "secondHmlcontent": (ddata.layout[ParagraphEditorWidget1]?.body?.value) ? {
            "__typename": "BagPart",
            "contentItems": [
                {
                    "__typename": "content",
                    "html": ddata.layout[ParagraphEditorWidget1]?.body?.value
                }
            ]
        } : null,
        "featureContent": {
            "__typename": "BagPart",
            "contentItems": []
        },
        "featureImage": (ddata.image?.variations[0]?.url) ? {
            "componentType": "heroImage",
            "contentItems": [
                {
                    "__typename": "heroImage",
                    "contentItemId": "n/a",
                    "img": {
                        "__typename": "MediaField",
                        "urls": [
                            ddata.image?.variations[0]?.url
                        ]
                    }
                }
            ]
        } : null,
        "faqs": {
            "__typename": "BagPart",
            "contentItems": [
                {
                    "__typename": "simpleAccordionGroup",
                    "title": ddata.layout[ParagraphAccordionsWidget]?.title,
                    "mods": ddata.layout[ParagraphAccordionsWidget]?.color,     //TODO!!!
                    "removeBottomSpacing": true,
                    "removeTopSpacing": true,
                    "items": {
                        "__typename": "BagPart",
                        "contentItems": 
                            ddata.layout[ParagraphAccordionsWidget]?.accordions?.map(acc => {
                                return {
                                    "__typename": "simpleAccordion",
                                    "contentItemId": "n/a",
                                    "title": acc.title,
                                    "content": acc.body?.value
                                };
                            })
                    }
                }
            ]
        },
        "relatedContent": {
            "__typename": "BagPart",
            "contentItems": []
        },
        "subscriptionBlock": {
            "__typename": "subscriptionBlock",
            "displaySubscription": true
        }
    };
}

function mapDrupal2Orchard (ddata: DrupalNode, compIndex: any, langcode: string, pageType: string, pageUpdates: any = null): OrchardPageModel {
    const ParagraphHeroBannersWidget = compIndex["ParagraphHeroBannersWidget"];
    const ParagraphFactSheetsWidget = compIndex["ParagraphFactSheetsWidget"];
    let ParagraphCardsWidget = compIndex["ParagraphCardsWidget"];
    let ParagraphCardsWidget1 = compIndex["ParagraphCardsWidget1"];
    let ParagraphThumbnailsWidget = compIndex["ParagraphThumbnailsWidget"];
    let ParagraphThumbnailsWidget1 = compIndex["ParagraphThumbnailsWidget1"];
    const ParagraphMapWidget = compIndex["ParagraphMapWidget"];
    const ParagraphCampaignsWidget = compIndex["ParagraphCampaignsWidget"];
    const ParagraphEditorWidget = compIndex["ParagraphEditorWidget"];
    const ParagraphEditorWidget1 = compIndex["ParagraphEditorWidget1"];
    const ParagraphCarouselsTextImageWidget = compIndex["ParagraphCarouselsTextImageWidget"];
    const ParagraphMultiDownloadWidget = compIndex["ParagraphMultiDownloadWidget"];
    const ParagraphNumbersWidget = compIndex["ParagraphNumbersWidget"];
    const ParagraphIframeWidget = compIndex["ParagraphIframeWidget"];
    const ParagraphAccordionsWidget = compIndex["ParagraphAccordionsWidget"];
    const ParagraphBoxesWidget = compIndex["ParagraphBoxesWidget"];

    if (pageType == "KnowTheFactsComponents") {
        ParagraphThumbnailsWidget = compIndex["ParagraphThumbnailsWidget1"];
        ParagraphThumbnailsWidget1 = compIndex["ParagraphThumbnailsWidget"];
    } else if (pageType == "ActNowComponents") {
        ParagraphCardsWidget = compIndex["ParagraphCardsWidget1"];
        ParagraphCardsWidget1 = compIndex["ParagraphCardsWidget"];
    }

    // console.log(compIndex);

    function mapPage(pageName: string): OrchardPageChallenges {
        return {
            "__typename": pageName,
            "displayText": ddata.title,
            "contentType": pageName,
            "contentItemId": "n/a",
            "contentBanner": {
                "__typename": "PageMetaData",
                "title": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.title ?? ddata.title,
                "subTitle": ddata.description?.value,
                "label": null,
                "heroImageUrl": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.image?.variations[0]?.url,
                "showHeadings": pageName == "homepage" ? false : true,
                "buttons": ddata.layout[ParagraphHeroBannersWidget]?.heroBanner[0]?.buttons,
                "langcode": langcode
            },
            "editorWidget": (ddata.layout[ParagraphEditorWidget]?.body?.value) ? {
                "__typename": "HtmlBodyPart",
                "html": ddata.layout[ParagraphEditorWidget]?.body?.value
            }: null,
            "featureImg": {
                "__typename": "BagPart", 
                "contentItems": 
                    (ddata.image?.variations[0]?.url)
                    ?   [
                            {
                                "__typename": "featureImg",
                                "caption": null,
                                "img": {
                                    "__typename": "MediaField",
                                    "urls": [
                                        ddata.image?.variations[0]?.url
                                    ]
                                }
                            }
                        ]
                    : []
            },
            "factSheetsGrid": {
                "__typename": "BagPart",
                "contentItems": ddata.layout[ParagraphFactSheetsWidget]?.factSheets,
                "langcode": langcode
            },
            "campaignCardGrid": {
                "__typename": "BagPart",
                "contentItems": ddata.layout[ParagraphCampaignsWidget]?.campaigns,
                "langcode": langcode
            },
            "figureBlockCarousel": {
                "langcode": langcode,
                "__typename": "BagPart",
                "contentItems": [
                    {
                        "__typename": "figuresBlock",
                        "items": {
                            "contentItems": 
                                ddata.layout[ParagraphNumbersWidget]?.number.map(nm => {
                                    return {
                                        "__typename": "figureBlock",
                                        "content": nm.body?.value,
                                        "unit": null,
                                        "value": nm.text
                                    };
                                }),
                        },
                        "mods": ddata.layout[ParagraphNumbersWidget]?.color,
                        "removeBottomSpacing": true,
                        "removeTopSpacing": true,
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "title": ddata.layout[ParagraphNumbersWidget]?.title,
                            "content": ddata.layout[ParagraphNumbersWidget]?.body?.value,
                        }
                    }
                ]
            },
            "thumbnails": {
                "__typename": "BagPart",
                "contentItems": [
                    {
                        "__typename": "ImgTitleContentCardGroup",
                        "removeTopSpacing": true,
                        "removeBottomSpacing": true,
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "title": ddata.layout[ParagraphThumbnailsWidget1]?.title,
                            "content": ddata.layout[ParagraphThumbnailsWidget1]?.body?.value,
                            "removeTopSpacing": true,
                            "removeBottomSpacing": false,
                            "className": null,
                        },
                        "items": {
                            "__typename": "BagPart",
                            "contentItems": ddata.layout[ParagraphThumbnailsWidget1]?.thumbnail?.map(t => {
                                const tmb = mapThumbnail(t, langcode);
                                if (!tmb) return null;

                                return {
                                    "__typename": "pageCard",
                                    "page": {
                                        "__typename": "ContentPickerField",
                                        "contentItems": [
                                            {
                                                "contentItemId": null,
                                                "contentType": "pageOurCampaigns",
                                                "contentItem": tmb
                                            }
                                        ]
                                    }
                                };
                            })?.filter(t => t != null)
                        }
                    }
                ]
            },
            "contentBlockCard": {
                "langcode": langcode,
                "contentItems":
                    mapFeatureCards(ddata.layout[ParagraphCardsWidget1]?.card, "pageDefinitions", ddata.layout[ParagraphCardsWidget1]?.title)
            },
            "featureCards": {
                "__typename": "BagPart",
                "contentItems":
                    mapFeatureCards(ddata.layout[ParagraphCardsWidget]?.card, "pageDefinitions", ddata.layout[ParagraphCardsWidget]?.title)
            },
            "campaignCarousel": {
                "contentItems": [
                    {
                        "__typename": "imageContentCardGrid",
                        "items": {
                            "__typename": "BagPart",
                            "contentItems": ddata.layout[ParagraphCarouselsTextImageWidget]?.textImages?.map(ti => {
                                return {
                                    "__typename": "imageContent",
                                    "thumbnail": {
                                        "__typename": "MediaField",
                                        "urls": [
                                            ti?.image?.variations[0].url
                                        ]
                                    },
                                    "title": ti?.title,
                                    "content": ti?.description?.value
                                };
                            })
                        },
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "content": ddata.layout[ParagraphCarouselsTextImageWidget]?.description?.value,
                            "title": ddata.layout[ParagraphCarouselsTextImageWidget]?.title
                        }
                    }
                ],
                "langcode": langcode
            },
            "whatWeDoFeatureCards": {
                "langcode": langcode,
                "contentItems":
                    mapFeatureCards(ddata.layout[ParagraphCardsWidget]?.card)
            },
            "downloadGrid": (ddata.layout[ParagraphMultiDownloadWidget]?.downloads) ? {
                "langcode": langcode,
                "contentItems": [
                    {
                        "__typename": "downloadGrid",
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "content": ddata.layout[ParagraphMultiDownloadWidget]?.body?.value,
                            "title": ddata.layout[ParagraphMultiDownloadWidget]?.title
                        },
                        "items": {
                            "__typename": "ContentPickerField",
                            "contentItems":
                                ddata.layout[ParagraphMultiDownloadWidget]?.downloads?.map(dl => {
                                    return {
                                        "__typename": "downloadCard",
                                        "content": dl.downloadtext,
                                        "title": dl.date,
                                        "file": {
                                            "__typename": "MediaField",
                                            "urls": [
                                                dl.downloadfile?.url
                                            ]
                                        }
                                    };
                                })
                        }
                    }
                ]
            } : null,
            "iframe": ddata.layout[ParagraphIframeWidget]?.iframe?.src?.url ? {
                "langcode": langcode,
                "__typename": "BagPart",
                "contentItems": [
                    {
                        "__typename": "iframe",
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "content": null,
                            "title": null
                        },
                        "embedCode": `<iframe src=\"${ddata.layout[ParagraphIframeWidget]?.iframe?.src?.url}\" 
                                        frameborder=\"${ddata.layout[ParagraphIframeWidget]?.iframe?.hasBorder ? 1 : 0}\" 
                                        style=\"${ddata.layout[ParagraphIframeWidget]?.iframe?.style}\">`,
                        "description": null
                    },
                ]
            } : null,
            "faqs": {
                "langcode": langcode,
                "__typename": "BagPart",
                "contentItems": 
                [
                    {
                        "__typename": "simpleAccordionGroup",
                        "title": ddata.layout[ParagraphAccordionsWidget]?.title,
                        "mods": ddata.layout[ParagraphAccordionsWidget]?.color,     //TODO!!!
                        "removeBottomSpacing": true,
                        "removeTopSpacing": true,
                        "items": {
                            "__typename": "BagPart",
                            "contentItems": 
                                ddata.layout[ParagraphAccordionsWidget]?.accordions?.map(acc => {
                                    return {
                                        "__typename": "accordion",
                                        "contentItemId": "n/a",
                                        "title": acc.title,
                                        "content": acc.body?.value,
                                        "items": {
                                            "__typename": "BagPart",
                                            "contentItems":
                                                acc.boxes && acc.boxes.length ? mapFeatureCards(acc.boxes) : []
                                        }
                                    };
                                })
                        }
                    }
                ]
            },
            "definitionItemGroup": {
                "langcode": langcode,
                "__typename": "BagPart",
                "contentItems": 
                    ddata.layout[ParagraphAccordionsWidget]?.accordions?.map(acc => {
                        return {
                            "__typename": "accordion",
                            "contentItemId": "n/a",
                            "title": acc.title,
                            "content": acc.body?.value,
                            "items": {
                                "__typename": "BagPart",
                                "contentItems":
                                    acc.boxes && acc.boxes.length ? mapFeatureCards(acc.boxes) : []
                            }
                        };
                    })
            },
            "featureArticle": (pageUpdates && pageUpdates.length > 0) ? {
                "langcode": langcode,
                "__typename": "BagPart",
                "contentItems": [
                    {
                        "__typename": "imgArticleCard",
                        "imgAlignment": "right",
                        "displayShadow": false,
                        "title": null,
                        "content": null,
                        "page": {
                            "__typename": "ContentPickerField",
                            "contentItems": [
                                {
                                    "__typename": "article",
                                    "contentItemId": "n/a",
                                    "contentType": "article",
                                    "contentItem": {
                                        "title": pageUpdates[0]?.title,
                                        "content": pageUpdates[0]?.description,
                                        "label": locDate(pageUpdates[0]?.date, langcode),
                                        "url": pageUpdates[0]?.url,
                                        "imageUrl": pageUpdates[0]?.image,
                                    }
                                }
                            ]
                        }
                    }
                ]
            } : null,
            "updateCardGrid": {
                "langcode": langcode,
                "__typename": "GridSettings",
                "loadMore": false,
                "contentItems": pageUpdates
            },
            "map": {
                "__typename": "BagPart",
                "contentItems": [
                  {
                    "__typename": "unfeMap",
                    "sectionIntro": {
                      "__typename": "SectionIntro",
                      "title": ddata.layout[ParagraphMapWidget]?.title,
                      "content": ddata.layout[ParagraphMapWidget]?.body?.value,
                    },
                    "popupIconStyle": false,
                    "displayRelatedItems": true,
                    "regions": {
                      "__typename": "BagPart",
                      "contentItems": mapRegions(ddata.layout[ParagraphMapWidget]?.region)
                    }
                  }
                ]
            },
            "contentBlocks": {
                "__typename": "BagPart",
                "contentItems": [
                    {
                        "__typename": "externalLinkCardGroup",
                        "title": null,
                        "content": {
                            "__typename": "HtmlBodyPart",
                            "html": ddata.layout[ParagraphEditorWidget]?.body?.value
                        },
                        "items": {
                            "__typename": "BagPart",
                            "contentItems": []
                        }
                    }
                ]
            },
            "discoverMore": {
                "__typename": "BagPart",
                "colour": pageName == "homepage" ? "transparent" : null,
                "contentItems": [
                    {
                        "__typename": "ImgTitleContentCardGroup",
                        "removeTopSpacing": true,
                        "removeBottomSpacing": true,
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "title": ddata.layout[ParagraphThumbnailsWidget]?.title,
                            "content": ddata.layout[ParagraphThumbnailsWidget]?.body?.value,
                            "removeTopSpacing": true,
                            "removeBottomSpacing": false,
                            "className": pageName == "homepage" ? "h3 smaller-heading" : null,
                        },
                        "items": {
                            "__typename": "BagPart",
                            "contentItems": ddata.layout[ParagraphThumbnailsWidget]?.thumbnail?.map(t => {
                                const tmb = mapThumbnail(t, langcode);
                                if (!tmb) return null;

                                return {
                                    "__typename": "pageCard",
                                    "page": {
                                        "__typename": "ContentPickerField",
                                        "contentItems": [
                                            {
                                                "contentItemId": null,
                                                "contentType": "pageOurCampaigns",
                                                "contentItem": tmb
                                            }
                                        ]
                                    }
                                };
                            })?.filter(t => t != null)
                        }
                    }
                ]
            },
            "colourCardRow": {
                "__typename": "BagPart",
                "contentItems": [
                    {
                        "__typename": "ImgTitleContentCardGroup",
                        "removeTopSpacing": true,
                        "sectionIntro": {
                            "__typename": "SectionIntro",
                            "title": ddata.layout[ParagraphBoxesWidget]?.title,
                            "content": null
                        },
                        "items": {
                            "__typename": "BagPart",
                            "contentItems": 
                                ddata.layout[ParagraphBoxesWidget]?.boxes?.map(t => {
                                    const tmb = mapThumbnail(t, langcode);
                                    if (!tmb) return null;

                                    return {
                                        "__typename": "pageCard",
                                        "page": {
                                            "__typename": "ContentPickerField",
                                            "contentItems": [
                                                {
                                                    "__typename": "pageActNow",
                                                    "contentItemId": null,
                                                    "contentType": "pageActNow",
                                                    "contentItem": tmb
                                                }
                                            ]
                                        }
                                    };
                                })
                        }
                    }
                ]
            },
            "homeImgContentCard": {
                "__typename": "BagPart",
                "contentItems": 
                    mapFeatureCards(ddata.layout[ParagraphCardsWidget]?.card, "campaign", ddata.layout[ParagraphCardsWidget]?.title)
            },
            "wysiwyg": {
                "__typename": "HtmlBodyPart",
                "html": ddata.layout[ParagraphEditorWidget]?.body?.value
            },
            "smallCardContentGroup": {
                "__typename": "BagPart",
                "contentItems": [{
                    "content": ddata.layout[ParagraphEditorWidget]?.body?.value,
                    "title": ddata.layout[ParagraphEditorWidget]?.title,
                    "items": {
                        "contentItems":
                            ddata.layout[ParagraphBoxesWidget]?.boxes?.map(t => {
                                const tmb = mapThumbnail(t, langcode);
                                if (!tmb) return null;

                                return {
                                    "__typename": "campaignCard",
                                    "page": {
                                        "__typename": "ContentPickerField",
                                        "contentItems": [
                                            {
                                                "__typename": "pageActNow",
                                                "contentItemId": null,
                                                "contentType": "pageActNow",
                                                "contentItem": tmb
                                            }
                                        ]
                                    }
                                };
                            })
                    }
                }]
            },
            // "instagramCarousel": {
            //     "__typename": "BagPart",
            //     "contentItems": [
            //         {
            //             "__typename": "instagramContentCarousel",
            //             "account": "@unfe",
            //             "title": "Instagram"
            //         }
            //     ]
            // },
            "subscriptionBlock": {
                "__typename": "subscriptionBlock",
                "displaySubscription": true
            }
        };

    }

    function mapFeatureCards(arr: any[], type: string = "pageDefinitions", title: string = null) {
        if (!arr) return [];

        return arr.map(crd => {
            return {
                "__typename": "imgContentCard",
                "imgAlignment": crd.imagePosition?.toLocaleLowerCase() ?? "left",
                "displayShadow": crd.hasBorder ?? false,
                "title": crd.title,
                "content": crd.description?.value,
                "text": crd.text,
                "sectionIntro": {
                    "__typename": "SectionIntro",
                    "title": title,
                    "content": null
                },
                "page": {
                    "__typename": "ContentPickerField",
                    "contentItems": [
                        {
                            "__typename": type,
                            "contentItemId": null,
                            "contentType": type,
                            "contentItem": mapThumbnail(crd, langcode)
                        }
                    ]
                }
            };
        });
    }

    function mapCountries(countries: RegionCountry[]): any[] {
        return countries.map(country => {
            return {
                "__typename": "mapCountry",
                "content": country.description?.value,
                "img": {
                    "__typename": "MediaField",
                    "urls": [
                        country.image?.variations[0]?.url
                    ]
                },
                "country": {
                    "__typename": "ContentPickerField",
                    "contentItems": [{
                        "__typename": "country",
                        "name": country.title?.trim(),
                        "locations": {
                            "__typename": "TaxonomyField",
                            "termContentItemIds": 
                                country.layout?.filter(l => l.title && l.link?.url)
                        },
                        "displayRelatedItems": []
                    }]
                }
            }
        });
    }

    function mapRegions(regions: DrupalParagraphRegion[]): any[] {
        if (!regions)
            return [];

        return regions.map(region => {
            return {
                "__typename": "mapRegion",
                "region": {
                    "__typename": "ContentPickerField",
                    "contentItems": [
                        {
                            "__typename": "locationRegion",
                            "name": region.title,
                            "value": region.regionCode
                        }
                    ]
                },
                "countries": {
                    "__typename": "BagPart",
                    "contentItems": mapCountries(region.country)
                }
            }
        });
    }

    let orchardData: OrchardPageModel = {
        "data": {
        },
        "loading": false,
        "networkStatus": 7
    };

    if (pageType === "ChallengesComponents") {
      orchardData.data.pageChallenges = [ mapPage("pageChallenges") ];
    } else if (pageType === "OurWorkComponents") {
      orchardData.data.pageOurWork = [ mapPage("pageOurWork") ];
    } else if (pageType === "OurCampaignsComponents") {
      orchardData.data.pageOurCampaigns = [ mapPage("pageOurCampaigns") ];
    } else if (pageType === "WhatWeDoComponents") {
      orchardData.data.pageWhatWeDo = [ mapPage("pageWhatWeDo") ];
    } else if (pageType === "KnowTheFactsComponents") {
      orchardData.data.pageKnowTheFacts = [ mapPage("pageKnowTheFacts") ];
    } else if (pageType === "ActNowComponents") {
      orchardData.data.pageActNow = [ mapPage("pageActNow") ];
    } else if (pageType === "RightToLoveComponents") {
      orchardData.data.pageRightToLove = [ mapPage("pageRightToLove") ];
    } else if (pageType === "FAQSComponents") {
      orchardData.data.pageFaqs = [ mapPage("pageFaqs") ];
    } else if (pageType === "DefinitionsComponents") {
      orchardData.data.pageDefinitions = [ mapPage("pageDefinitions") ];
    } else if (pageType === "UpdatesComponents") {
      orchardData.data.pageUpdates = [ mapPage("pageUpdates") ];
    } else if (pageType === "HomeComponents") {
      orchardData.data.homepage = [ mapPage("homepage") ];
    } else if (pageType === "ContactUsComponents") {
      orchardData.data.pageContactUs = [ mapPage("pageContactUs") ];
    } else if (pageType === "ResourcesComponents") {
      orchardData.data.pageResources = [ mapPage("pageResources") ];
    } else if (pageType === "GeneralContentComponents") {
        orchardData.data.pageGeneralContent = [ mapPage("pageGeneralContent") ];
    }

    return orchardData;
}

export const DynamicContentController: React.FC<{ pageData: any, pageMetaData: DrupalPageMetadata, pageContent: any, pageUpdates: any }> = ({ pageData, pageMetaData, pageContent, pageUpdates }) => {
    if (!pageMetaData || !pageContent) return null;

    const client = useApolloClient();
    const [components, setComponents] = React.useState(null);

    function getIndex(layout: any[]) {
        let compIndex = {};
        layout.forEach((l, ix) => {
            let key = l.__typename;
            let count = 0;

            // Find the next available key
            while (compIndex.hasOwnProperty(key + (count || ''))) {
                count++;
            }

            // Assign the index to the available key
            compIndex[key + (count || '')] = ix;
        });
        return compIndex;
    }

    React.useEffect(() => {
        if (pageData.components) {

            if (pageData.contentType == "factSheet") {

                // console.log(pageData.components);
                // console.log(pageContent);

                let comps = mapFactSheet(pageContent, getIndex(pageContent.layout), pageMetaData);

                // console.log(comps);
                setComponents(comps);

            } else if (pageData.contentType == "campaign") {

                // console.log(pageData.components);
                // console.log(pageContent);

                let comps = mapCampaign(pageContent, getIndex(pageContent.layout), pageMetaData);

                // console.log(comps);
                // console.log(pageData.components);

                setComponents(comps);
                // setComponents(pageData.components);
            }
            else if (pageData.contentType == "article") {

                // console.log(pageData.components);
                // console.log(pageContent);

                let comps = mapArticle(pageContent, getIndex(pageContent.layout), pageMetaData, pageUpdates);

                // console.log(comps);
                // console.log(pageData.components);

                setComponents(comps);
            }
            else {
                // console.log(pageData.contentType);

                setComponents(pageData.components);
            }
        }
        else {
            const query = Templates[pageData.contentType]?.query?.components;
            if (!query) {
                console.log("Cannot find the query for the page : ", pageData.contentType);
            }

            const variables = { id: pageData.contentItemIds, status: contentState };
            const queriesArray = Array.isArray(query) ? query : [query];
            const pageType = queriesArray[0]?.definitions[0]?.name?.value;

            // console.log(pageType);

            if (pageType === "ChallengesComponents" 
                || pageType === "OurWorkComponents"
                || pageType === "OurCampaignsComponents"
                || pageType === "WhatWeDoComponents"
                || pageType === "KnowTheFactsComponents"
                || pageType == "UpdatesComponents"
                || pageType == "ActNowComponents"
                || pageType == "RightToLoveComponents"
                || pageType == "FAQSComponents"
                || pageType == "DefinitionsComponents"
                || pageType == "HomeComponents"
                || pageType == "ContactUsComponents"
                || pageType == "GeneralContentComponents"
                || pageType == "ResourcesComponents"
            ) {
                if (!pageContent) return null;
                
                // console.log(pageType);

                const result: OrchardPageModel = mapDrupal2Orchard(pageContent, getIndex(pageContent.layout), pageMetaData.langcode, pageType, pageUpdates);

                // console.log(result);

                let results: OrchardPageModel[] = [ result ];

                const allComponents = results.map(x => x.data[pageData.contentType][0]).reduce((obj, item) => ({ ...obj, ...item }), {});
                setComponents(allComponents);

            } else {
                // console.log(pageType);

                Promise.all(queriesArray.map(x => client.query({ query: x, variables })))
                    .then(results => {                        
                        
                        console.log(results[0]);

                        const allComponents = results.map(x => x.data[pageData.contentType][0]).reduce((obj, item) => ({ ...obj, ...item }), {});
                        setComponents(allComponents);
                    });
            }
        }
    }, [pageData]);

    if (!components) return null;

    return <ContentViewController
        template={Templates[pageData.contentType]}
        pageData={components}
        theme={Templates[pageData.contentType].theme}
    />

}