import { AccordionGroup } from "../components/accordionGroup/accordionGroup";
import { BackgroundTransition } from "../components/backgroundTransition/backgroundTransition";
import { ContentBanner } from "../components/contentBanner/contentBanner";
import { ColourCardRow } from "../components/colourCardRow/colourCardRow";
import { ColumnCarousel } from "../components/columnCarousel/columnCarousel";
import { ContentCard } from "../components/contentCard/contentCard";
import { ContentCardCarousel } from "../components/contentCardCarousel/contentCardCarousel";
import { DefinitionItemGroup } from "../components/definitionItemGroup/definitionItemGroup";
import { DefinitionItem } from "../components/definitionItem/definitionItem";
import { DownloadCard } from "../components/downloadCard/downloadCard";
import { DownloadGrid } from "../components/downloadGrid/downloadGrid";
import { FeatureContent } from "../components/featureContent/featureContent";
import { FeatureContentIntro } from '../components/featureContentIntro/featureContentIntro';
import { FeatureCtaRow } from '../components/featureCtaRow/featureCtaRow';
import { FeatureImg } from '../components/featureImg/featureImg';
import { FeatureLogoRow } from '../components/featureLogoRow/featureLogoRow';
import { FigureBlockCarousel } from "../components/figureBlockCarousel/figureBlockCarousel";
import { Footer } from "../components/footer/footer";
import { GreyContentRow } from "../components/greyContentRow/greyContentRow";
import { GalleryCarousel, GalleryCarouselGroup } from "../components/galleryCarousel/galleryCarousel";
import { Header } from "../components/header/header";
import { HomeBanner } from "../components/homeBanner/homeBanner";
import { IconBlockRow } from "../components/iconBlockRow/iconBlockRow";
import { ImgContentCardGroup } from "../components/imgContentCardGroup/imgContentCardGroup";
import { ImgContentCard } from "../components/imgContentCard/imgContentCard";
import { ImgGroup } from "../components/imgGroup/imgGroup";
import { ImgTitleContentCard } from "../components/imgTitleContentCard/imgTitleContentCard";
import { ImgTitleContentCardGroup } from "../components/imgTitleContentCardGroup/imgTitleContentCardGroup";
import { Instagram } from "../components/instagram/instagram";
import { LinkBlockRow } from "../components/linkBlockRow/linkBlockRow";
import { Map } from "../components/map/map";
import { MediaDownloadCarousel } from "../components/mediaDownloadCarousel/mediaDownloadCarousel";
import { CampaignCardGrid } from "../components/campaignCardGrid/campaignCardGrid";
import { SectionIntroGroup } from "../components/sectionIntroGroup/sectionIntroGroup";
import { SectionIntro } from "../components/sectionIntro/sectionIntro";
import { SideBar } from "../components/sideBar/sideBar";
import { CardGrid } from "../components/cardGrid/cardGrid";
import { CardWysiwyg } from "../components/cardWysiwyg/cardWysiwyg";
import { SocialContentCol } from "../components/socialContentCol/socialContentCol";
import { StaticEmbed } from "../components/staticEmbed/staticEmbed";
import { TermBlockRow } from "../components/termBlockRow/termBlockRow";
import { Toolbar } from "../components/toolbar/toolbar";
import { UpdateCardGrid } from "../components/updateCardGrid/updateCardGrid";
import { YoutubeVideo } from "../components/youtubeVideo/youtubeVideo";
import { WYSIWYG } from "../components/wysiwyg/wysiwyg";
import { FactSheetCardGrid } from "../components/factSheetCardGrid/factSheetCardGrid";
import { ENewsForm } from "../components/eNewsForm/eNewsForm";
import { RelatedContentGroup } from "../components/relatedContentGroup/relatedContentGroup";
import { ImageHero } from "../components/imgHero/imgHero";

export const ComponentList = {
    accordionGroup: AccordionGroup,
    backgroundTransition: BackgroundTransition,
    campaignCardGrid: CampaignCardGrid,
    columnCarousel: ColumnCarousel,
    contentBanner: ContentBanner,
    colourCardRow: ColourCardRow,
    contentCard: ContentCard,
    contentCardCarousel: ContentCardCarousel,
    definitionItem: DefinitionItem,
    definitionItemGroup: DefinitionItemGroup,
    downloadCard: DownloadCard,
    downloadGrid: DownloadGrid,
    eNewsForm:ENewsForm,
    factSheetCardGrid:FactSheetCardGrid,
    featureContent: FeatureContent,
    featureContentIntro: FeatureContentIntro,
    subscriptionBlock: FeatureCtaRow,
    featureImg: FeatureImg,
    featureLogoRow: FeatureLogoRow,
    figureBlockCarousel: FigureBlockCarousel,
    footer: Footer,
    galleryCarousel: GalleryCarousel,
    galleryCarouselGroup: GalleryCarouselGroup,
    greyContentRow: GreyContentRow,
    greyPageContentRow: GreyContentRow,
    header: Header,
    homeBanner: HomeBanner,
    iconBlockRow: IconBlockRow,
    imgContentCardGroup: ImgContentCardGroup,
    imgContentCard: ImgContentCard,
    imgGroup: ImgGroup,
    imgTitleContentCard: ImgTitleContentCard,
    imgTitleContentCardGroup: ImgTitleContentCardGroup,
    instagram: Instagram,
    linkBlockRow: LinkBlockRow,
    map: Map,
    mediaDownloadCarousel: MediaDownloadCarousel,
    sectionIntroGroup: SectionIntroGroup,
    sectionIntro: SectionIntro,
    sideBar: SideBar,
    cardGrid: CardGrid,
    cardWysiwyg: CardWysiwyg,
    relatedContentGroup: RelatedContentGroup,
    socialContentCol: SocialContentCol,
    staticEmbed: StaticEmbed,
    termBlockRow: TermBlockRow,
    toolbar: Toolbar,
    updateCardGrid: UpdateCardGrid,
    youtubeVideo: YoutubeVideo,
    wysiwyg: WYSIWYG,
    imageHero: ImageHero
};