import * as React from 'react';
import { PostCardGridNew } from '../postCardGridNew/postCardGridNew';

export const CampaignCardGrid: React.FC<{contentItems: DrupalParagraphFactSheet[], langcode: string}> = ({contentItems, langcode}) => {

    if(!contentItems) return <React.Fragment></React.Fragment>;

    const items = contentItems.map(item =>  {
        let obj = {
            "title": item.title,
            "content": item.body?.value,
            "url": (item.link?.url?.startsWith('/')) ? `/${langcode}${item.link?.url}` : item.link?.url,
            "isFeature": false,
            "isExternal": !item.link?.internal,
            "img": {
                "__typename": "MediaField",
                "urls": [
                    item.image?.url ?? ""
                ]
            },
            "date": "2023-01-01"    //TODO???
        };

        return obj;
    });

    return <PostCardGridNew
        type="campaign"
        contentItems={items}
    />
}