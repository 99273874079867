import { Breakpoints } from "./breakpoints";

class UtilsUI {

    youtubeVideos: Array<Function> = [];
    youtubeReady: any;

    checkForIE11 = (): boolean => {
        return /Trident\/|MSIE/.test(window.navigator.userAgent);
    }

    initYoutubeApi = () => {
        if (!this.youtubeReady) {

            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

            this.youtubeReady = new Promise((resolve) => {
                window.onYouTubeIframeAPIReady = () => {
                    resolve(window.YT);
                }
            });

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        return this.youtubeReady;
    };

    updateQueryStringParameter = (queryString: string, key: string, value: string) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = queryString.indexOf('?') !== -1 ? "&" : "?";
        if (queryString.match(re)) {
            return queryString.replace(re, value === '' ? '' : '$1' + key + "=" + value + '$2');
        }
        else {
            return value === '' ? queryString : queryString + separator + key + "=" + value;
        }
    };

    isScreen = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
        return window.matchMedia(`(min-width: ${Breakpoints[size]})`).matches;
    };

    getUrlPartitions = (locPath: string) => {
        const partitions = locPath.split('/').filter((el) => { el != ""; return el });
        return partitions;

    }

    includeInNav = item => item.contentType == "unfeNavItem" && item.content && item.content.contentItems && item.content.contentItems.length > 0;

    cleanPath = str => {
        return str?.trim().replace(/^(\/)*(en|fr|ar|zh|pt|ru|es)(\/)+/g, '');
    }

    chunkArray = (myArray, chunk_size) => {

        if (!myArray) return;
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
            const myChunk = myArray.slice(index, index + chunk_size);
            tempArray.push(myChunk);
        }

        return tempArray;
    }

    getUrlWithRegion = (currentRegion: string, url: string) => {
        if (!url) return url;
        let updatedURL = this.cleanPath(url);
        updatedURL = (updatedURL[0] != "/" ? `/${updatedURL}` : updatedURL);
        if (currentRegion == "en") return updatedURL;

        return `/${currentRegion}${updatedURL}`;
    }

    truncateWords = (str, no_words) => {
        let strSplit = str?.split(" ")
        if(strSplit.length <= no_words) {
            return(str);
        }
        return `${strSplit.splice(0, no_words).join(" ")}...`;
    }

    toggleTabStatus = (status: boolean) => {

        const tabRegion = document.querySelectorAll('.tab-region-toggle');

        for (let i = 0; i < tabRegion.length; i++) {

            const curParent = tabRegion[i];
            const links = curParent.querySelectorAll('a, button');

            for (let k = 0; k < links.length; k++) {

                const curLink = links[k];

                if (status) {
                    curLink.setAttribute('tabindex', '-1');
                } else {
                    curLink.removeAttribute('tabindex');
                }
            }

        }

    }

    checkImgSrc = (urls) => {

        if (!urls) return null;
        const pathLoc = typeof (urls) == "object" ? urls[0] : urls;

        if (pathLoc?.search(`http`) == 0) return pathLoc;

        if (pathLoc?.search(`${process.env.REACT_APP_CMS_BASE_URL_NEW}`) == -1) return `${process.env.REACT_APP_CMS_BASE_URL_NEW}${pathLoc}`;
        return pathLoc;
    }

    getNewCMSUrl = () => {        
        return process.env.REACT_APP_CMS_BASE_URL_NEW;
    }

    mergePostItemList = (currentItemList: any, data: any) => {

        if (currentItemList == null) return [...data];
        let items = [...currentItemList];

        data.map((curItem) => {

            let found = false;
            for (let i = 0; i < items.length; i++) {
                if (items[i].contentItemId == curItem.contentItemId) found = true;
            }

            if (!found) items.push(curItem);
        })

        return items;
    }

    formatPostData = (data: any) => {

        const items = data.map(item => {
            if (item != null) {
                let temp = {
                    title: item.title,
                    content: item.content,
                    url: item.external?.isExternal ? item.external?.url : item.path,
                    isFeature: item.isFeature,
                    isExternal: item.external?.isExternal,
                    img: item.thumbnail
                };

                if (item.date) temp['date'] = item.date;

                return temp;
            }
        });

        return items;
    }

    formatDateString = (date: string) => {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const articleDate = new Date(date);
        if (!isNaN(articleDate.getTime())) return `${articleDate.getDate()} ${monthNames[articleDate.getMonth()]} ${articleDate.getFullYear()}`;
        return date;

    }

    enableScrollLock = () => {
        const curScrollPosition = window.pageYOffset;
        const $body = document.querySelector('body');

        $body.style.overflow = 'hidden';
        $body.style.position = 'fixed';
        $body.style.top = `-${curScrollPosition}px`;
        $body.style.width = '100%';
        return curScrollPosition;
    }

    disableScrollLock = (scrollPos: number) => {
        const $body = document.querySelector('body');

        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPos);
    }

    getURLParameter(parameter: string, url?: string) {
        if (!url) url = window.location.search;
        parameter = parameter.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + parameter + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return '';
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

}

let utilsUI = new UtilsUI();
export default utilsUI;