import gql from "graphql-tag";

export const ICON_BLOCK_ROW_FRAGMENT = gql`
fragment iconBlockRowFragment on IconBlockRow {
    content
    title
    items: contentItems {
      ... on iconBlock {
        content
        bgImg {
          urls
        }
      }
    }
  }`;