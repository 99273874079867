import { gql } from "@apollo/client";

export const CONTENT_INTRO_FRAGMENT = gql `
fragment contentIntroFragment on contentIntro {
    content
    links {
      contentItemIds
    }
    label
    title
    isLanding
  }
`;