import * as React from 'react';
import "./figureBlockCarousel.scss";
import { FigureBlock, IFigureBlockProps } from "../figureBlock/figureBlock";
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";
import { Carousel } from "../carousel/carousel";
import { BackgroundColours, ComponentContainer } from '../componentContainer/componentContainer';

interface IFigureBlockCarouselProps {
    contentItems: {
        sectionIntro?: ISectionIntroProps
        items: {
            contentItems: IFigureBlockProps[]
        }
        mods?: BackgroundColours
        removeTopSpacing: boolean
        removeBottomSpacing: boolean
    }[]
}

export const FigureBlockCarousel: React.FC<IFigureBlockCarouselProps> = ({ contentItems }) => {

    if (!contentItems?.length) return null;

    const item = contentItems[0];
    const items = item.items?.contentItems;
    if (!items?.length) return null;

    return (
        <ComponentContainer bg={item.mods} removeTopSpacing={item.removeTopSpacing} removeBottomSpacing={item.removeBottomSpacing}>
            <div className="figure-block-carousel">
                {item.sectionIntro ? <SectionIntro
                    {...item.sectionIntro}
                    removeTopSpacing
                /> : null}
                <Carousel
                    mods="carousel--no-spacing"
                    startAtIndex={1}
                >
                    {items.map((item, index) => {
                        if (item != null) return <FigureBlock {...item} key={index} />
                    })}
                </Carousel>
            </div>
        </ComponentContainer>
    );

}