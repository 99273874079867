import * as React from 'react';
import "./header.scss";
import { Icon, Icons } from "../icon/icon";
import { Social } from "../social/social";
import { Nav } from "../nav/nav";
import { HeaderBtn } from "./headerBtn";
import { NavAccessibility } from '../../components/navAccessibility/navAccessibility';
import { Dropdown } from "../dropdown/dropdown";
import { SiteDataContext, languages } from "../../contexts/siteData";
import { languageAction } from "../../actions/language";
import utilsUI from "../../core/utilsUI";
import { getLangOptionList } from "../../core/langData";
import { ScrollDirection, useScrollDirection } from '../../hooks/use-scroll-direction';
import { ButtonTitles } from "../../language/button";

export const Header: React.FC<{pageMenu: any, social: any, homeBanner: any}> =  ({pageMenu, social, homeBanner}) => {

    if (!pageMenu || !social) return <React.Fragment></React.Fragment>

    let currentIndex = 0;
    let banner = null;
    let banners = null;

    const [mobileActive, toggleMobileActive] = React.useState(false);
    const [hidden, isHidden] = React.useState(true);
    const { state, dispatch } = React.useContext(SiteDataContext);
    const header = React.useRef(null);
    const headerLower = React.useRef(null);
    const langList = React.useRef(getLangOptionList());
    const homeUrl = state.language.initials == "en" ? "/" : `/${state.language.initials}/`;
    const unfeLogoText = `UNFEtext${state.language.initials.toUpperCase()}`
    const curScrollPosition = React.useRef(null);
    const scrollDirection = useScrollDirection({initialDirection: ScrollDirection.Top});
    const [isPlaying, setIsPlaying] = React.useState<any>(false);

    if (homeBanner) {
        const hb = homeBanner.layout?.find(l => l.__typename == "ParagraphHomeBannerWidget");

        if (hb) {
            banners = hb.homeBanners;

            if (!isPlaying) {
                if (document.getElementById("b_text"))
                    document.getElementById("b_text").innerText = banners[0].text;

                setInterval(() => {
                    currentIndex++;
                    if (currentIndex > hb.homeBanners.length - 1)
                        currentIndex = 0;

                    banner = banners[currentIndex];

                    if (document.getElementById("b_text"))
                        document.getElementById("b_text").innerText = banner.text;
                }, 5000);

                setIsPlaying(true);
            }
        }
    }

    React.useEffect(() => {

        setTimeout(() => {
            isHidden(false);
        }, 400);

        return () => utilsUI.toggleTabStatus(false);

    }, []);

    const toggleMenu = () => {

        if (!mobileActive){
            curScrollPosition.current = utilsUI.enableScrollLock();
            utilsUI.toggleTabStatus(true);
        }else {
            utilsUI.disableScrollLock(curScrollPosition.current);
            utilsUI.toggleTabStatus(false);
        }

        toggleMobileActive(!mobileActive);
    }

    const clickedItem = (selectedLang) => {
        const regionUrl = utilsUI.getUrlWithRegion(languages[selectedLang].initials, `${window.location.pathname}`);
        document.body.classList.add('loading');
        setTimeout(() => {
            //dispatch(languageAction(selectedLang)); // dont need to do this as the location change that comes next interrupts this and when the page is loaded the language is fetched from the url anyway
            window.location.href = regionUrl;
        }, 600);
    }

    function getLang() {
        return <Dropdown
                    options={langList.current}
                    clickCallback={clickedItem}
                    classList="header__language"
                    hoverEffect={true}
                    activeIndex={state.language.index}
                />
    }

    return <React.Fragment>
        <NavAccessibility />
        <header 
            className={"header top" + (mobileActive ? ' active' : "") + (hidden ? " hide" : "") + (scrollDirection === ScrollDirection.Top ? "" : " is-sticky")}
            ref={header}
        >
            <div className="header__top">
                <div className="header__left tab-region-toggle" style={{ minWidth: "max-content"}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <a href={homeUrl} style={{ maxWidth: "100px" }}>
                                <Icon icon={Icons.UNFEicon} />
                                <span><Icon icon={Icons[unfeLogoText]} /></span>
                                <span className="sr-only">UNFE Logo</span>
                            </a>
                        </div>
                        <div>
                            <label
                                id="b_text">
                            </label>
                        </div>
                    </div>
                </div>
                <div style={{ width: "60%" }}>&nbsp;</div>
                <div className="header__right" style={{ width: "15%", minWidth: "max-content" }}>
                    <div className='header__top'>
                        <div className='header__left' style={{ width: "48%", minWidth: "max-content" }}>
                            <Dropdown
                                options={langList.current}
                                clickCallback={clickedItem}
                                classList="header__language"
                                hoverEffect={true}
                                activeIndex={state.language.index}
                            />
                        </div>
                        <div id="hdr_space" className='header__center' style={{ minWidth: "2em" }}>&nbsp;</div>
                        <div className='header__right' style={{ width: "48%", minWidth: "max-content" }}>
                            <HeaderBtn
                                id="main-nav-btn"
                                aria={{
                                    label: "Open mobile menu",
                                    controls: "main-navigation",
                                    popup: "true"
                                }}
                                classList="header__mobile-btn"
                                clickCallback={toggleMenu}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </HeaderBtn>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                className="header__lower"
                ref={headerLower}
            >
                <div className="header__menu-wrap">                    
                    <Nav pageMenu={pageMenu} langItem={getLang()} />
                </div>
                <div className="header__actions">
                    <div><a href={(state.language.initials != "en") ? `/${state.language.initials}/act-now` : "/act-now"} className="header__action-btn">{ButtonTitles[state.language.value].action}</a></div>
                    <Social items={social} />
                </div>
            </div>
        </header>
    </React.Fragment>
}