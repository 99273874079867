import * as React from 'react';
import "./homeBanner.scss";
import { useIsVisible } from "../../hooks/use-is-visible";

interface IHomeBannerProps {
    slides: {title :string}[]
    clickCallback ?: (boolean) => void
};

export const HomeBanner: React.FC<IHomeBannerProps> = ({ slides, clickCallback }) => {

    const interval = React.useRef(null);
    const timeout: number = 2000;
    const banner = React.useRef(null);
    const [currentIndex, updateIndex] = React.useState(0);
    const [isPlaying, updatePlayStatus] = React.useState(false);
    const isVisible = useIsVisible(banner);

    React.useEffect(() => {
    
        if(slides.length > 1 && slides.length <= 5) {
            interval.current = setInterval(transitionSlides.bind(this), timeout);
            
            return () => {
                clearInterval(interval.current);
            }
        }

    }, [currentIndex]);


    const transitionSlides = () => {
        const nextIndex = (currentIndex >= (slides.length -1)) ? 0 : (currentIndex + 1);
        updateIndex(nextIndex);
    }

    const togglePlay = () => {

        updatePlayStatus(!isPlaying);
        clickCallback(isPlaying);
        
        if(!isPlaying){
            clearInterval(interval.current);
        }else {
            interval.current = setInterval(transitionSlides, timeout);
        }
    }

    
    if(!slides) return <React.Fragment></React.Fragment>
    return <div 
        className={"home-banner" + (isVisible ? " loaded" : "")}
        ref={banner}
    >
        {
            slides.map((item, index) => {
                return <div 
                    key={index} 
                    className={"home-banner__item" + ((index == currentIndex) ? " active" : "")}
                >
                    <h1 className="hero-heading-c1">{item.title}</h1>
                </div>
            })
        }
        {/* <button 
            role="button"
            onClick={() => { window.scroll(0, banner.current.clientHeight) }}
            aria-label="Scroll down"
            className="home-banner__scroll"
        ></button>
        {clickCallback ? <button
            role="button"
            aria-label="Play/Pause background transition"
            className={"home-banner__pause-play" + (isPlaying ? " paused" : "")}
            onClick={() => { togglePlay() }}
        ></button> : ""} */}
    </div>

}