import gql from "graphql-tag";
import { FEATURE_LOGO_ROW_FRAGMENT } from "./feature-logo-row-fragment";
import { IMG_CONTENT_BLOCK_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const ABOUT_US_PAGE = gql`
query AboutUsComponents($id: ID, $status : Status){
  pageAboutUs(where: {contentItemId: $id}, status : $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    featureLogoRow {
      contentItems {
        ... featureLogoRowFragment
      }
    }
    imgContentCardGroup {
      contentItems {
        ... imgContentBlockFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
  }
}  

${IMG_CONTENT_BLOCK_FRAGMENT}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${FEATURE_LOGO_ROW_FRAGMENT}
`;

export const ABOUT_US_META_DATA = gql`
query AboutUsMetaData($id: ID, $status : Status){
  page: pageAboutUs(where: {contentItemId: $id}, status : $status) {
    contentItemId
    pageMetaData{
      title
      description
      keywords
      label
      thumbnail{
        urls
      }
    }
  }
}

`;