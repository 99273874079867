import gql from "graphql-tag";
import { ACCORDION_GROUP_FRAGMENT } from "./accordion-group-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";
import { SIMPLE_CONTENT_GRID_FRAGMENT } from "./simple-content-grid-fragment";

export const FAQS_PAGE = gql`
query FAQSComponents($id: ID, $status: Status){
  pageFaqs(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    termBlockRow {
      contentItems {
        ... simpleContentGridFragment
      }
    }
    faqs {
      contentItems {
        ... accordionGroupFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${ACCORDION_GROUP_FRAGMENT}
${SIMPLE_CONTENT_GRID_FRAGMENT}
`;

export const FAQS_META_DATA = gql`
query FAQSMetaData($id: ID, $status: Status){
  page: pageFaqs(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;