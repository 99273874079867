import gql from "graphql-tag";
import { ACCORDION_FRAGMENT } from "./accordion-fragment";
import { IMG_ARTICLE_CARD_FRAGMENT, IMG_CAMPAIGN_CARD_FRAGMENT, IMG_CONTENT_CARD_FRAGMENT, IMG_FACT_SHEET_CARD_FRAGMENT } from "./img-content-card-fragment";
import { IMG_TITLE_CONTENT_GROUP_FRAGMENT } from "./img-title-content-group-fragment";

export const DEFINITIONS_PAGE = gql`
query DefinitionsComponents($id: ID, $status: Status){
  pageDefinitions(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    definitionItemGroup {
      contentItems {
        ... accordionFragment
      }
    }
    featureCards {
      contentItems {
        ...imgContentCardFragment
        ... imgArticleCardFragment
        ... imgCampaignCardFragment
        ... imgFactSheetCardFragment
      }
    }
    discoverMore {
      contentItems {
        ... imgTitleContentGroupFragment
      }
    }
    subscriptionBlock {
      displaySubscription
    }
}
}
${IMG_TITLE_CONTENT_GROUP_FRAGMENT}
${IMG_CONTENT_CARD_FRAGMENT}
${IMG_ARTICLE_CARD_FRAGMENT}
${IMG_CAMPAIGN_CARD_FRAGMENT}
${IMG_FACT_SHEET_CARD_FRAGMENT}
${ACCORDION_FRAGMENT}
`;


export const DEFINITIONS_META_DATA = gql`
query DefinitionsMetaData($id: ID, $status: Status){
  page: pageDefinitions(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;