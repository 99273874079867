import * as React from 'react';
import './carousel.scss';
import { ArrowBtn, ARROW_DIRECTION } from "../arrowBtn/arrowbtn";
import { CarouselWrapper } from "../carouselWrapper/carouselWrapper";
import { AnimatedElement } from "../../controllers/animated-elements";
import UtilsUI from "../../core/utilsUI";
import { Share } from '../../components/share/share';

interface ICarouselProps {
    children: React.ReactNode[]
    social?: boolean
    mods?: string
    noItemPadding?: boolean
    startAtIndex?: number
    curIndexCallback?: (index) => void;
};

export const Carousel: React.FC<ICarouselProps> = ({ children, social, mods, noItemPadding, startAtIndex, curIndexCallback }) => {

    const checkSize = () => {
        if (startAtIndex && UtilsUI.isScreen('xl')) return startAtIndex;
        return 0;
    }

    const [currentIndex, setCurrentIndex] = React.useState(checkSize());

    React.useEffect(() => {

        if (curIndexCallback) curIndexCallback(currentIndex);

    }, [currentIndex]);

    const updateIndex = (direction: number) => {
        const nextIndex = currentIndex + direction;
        if (nextIndex < checkSize()) return;
        if (nextIndex >= (children.length - checkSize())) return;
        setCurrentIndex(nextIndex);
    }

    if (!children) return <React.Fragment></React.Fragment>
    return <AnimatedElement classList="carousel-animation">
        <div className={"carousel" + (mods ? ` ${mods}` : "")}>
            <CarouselWrapper
                currentIndex={currentIndex}
                onTouchCallback={updateIndex}
                noItemPadding={noItemPadding}
            >
                {children}
            </CarouselWrapper>

            <div className="carousel__controls">
                <ArrowBtn
                    ariaLabel={"Previous item"}
                    classList={"carousel__prev" + (currentIndex - 1 < 0 ? " disabled" : "")}
                    onClick={() => updateIndex(-1)}
                    includeIcon={true}
                    direction={ARROW_DIRECTION.PREVIOUS}
                />
                <ArrowBtn
                    ariaLabel={"Next item"}
                    classList={"carousel__next" + (currentIndex + 1 >= children.length ? " disabled" : "")}
                    onClick={() => updateIndex(1)}
                    includeIcon={true}
                    direction={ARROW_DIRECTION.NEXT}
                />
            </div>

            {social ? <div className="carousel__lower"><Share noToggle={true} /></div> : null}
        </div>
    </AnimatedElement>
}