
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ContentItem": [
      "GenericTag",
      "Homepagebanner",
      "IconBlockRow",
      "ImgTitleContentCardGroup",
      "LocationTag",
      "Taxonomy",
      "accordion",
      "accordionGroup",
      "article",
      "articleCard",
      "banner",
      "campaign",
      "campaignCard",
      "content",
      "contentExternalLinkCard",
      "contentIntro",
      "contentPageCards",
      "contentPageCardsGroup",
      "country",
      "definitionItem",
      "downloadCard",
      "downloadGrid",
      "downloadMediaCard",
      "externalFeatureCta",
      "externalLinkCardGroup",
      "factSheet",
      "factSheetCard",
      "factSheetGrid",
      "featureImg",
      "featureLogoRow",
      "figureBlock",
      "figuresBlock",
      "footer",
      "heroImage",
      "homepage",
      "iconBlock",
      "iframe",
      "imageContent",
      "imageContentCardGrid",
      "imageGalleryCarousel",
      "imageItem",
      "imgArticleCard",
      "imgCampaignCard",
      "imgContentBlock",
      "imgContentCard",
      "imgFactSheetCard",
      "imgGroup",
      "instagram",
      "instagramContentCarousel",
      "instagramFeed",
      "internalFeatureCta",
      "locationRegion",
      "mapCountry",
      "mapRegion",
      "mediaDownloadCarousel",
      "newsletter",
      "pageAboutUs",
      "pageActNow",
      "pageCard",
      "pageChallenges",
      "pageContactUs",
      "pageDefinitions",
      "pageDonate",
      "pageEqualRights",
      "pageFaqs",
      "pageGeneralContent",
      "pageKnowTheFacts",
      "pageNewsletter",
      "pageOurCampaigns",
      "pageOurWork",
      "pageResources",
      "pageRightToLove",
      "pageUpdates",
      "pageWhatWeDo",
      "relatedArticles",
      "relatedCampaigns",
      "relatedFactSheets",
      "simpleAccordion",
      "simpleAccordionGroup",
      "simpleContentBlock",
      "simpleContentCard",
      "simpleContentGrid",
      "simplePageCardCarousel",
      "socialContentCard",
      "socialPlatform",
      "subheader",
      "subscription",
      "unfeContentItem",
      "unfeHomePage",
      "unfeMap",
      "unfeNavItem",
      "unfeNavigation",
      "youtubeVideo"
    ]
  }
};
      export default result;
    