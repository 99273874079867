import { languages } from "../contexts/siteData";

export const getLangByInitials = (langInitials: string) => {

    for (const [key, value] of Object.entries(languages)) {

        if (languages[key].initials == langInitials) {
            return languages[key];
        }

    }

};

export const getLangOptionList = () => {

    let options = [];

    for (const [key, value] of Object.entries(languages)) {
        const temp = {
            title: languages[key].label,
            value: languages[key].value
        };

        options.push(temp);
    }

    return options;

};

export const getLangculture = () => {
    let list = [];
    for (const [key, value] of Object.entries(languages)) {
        list.push(languages[key].initials);
    }
    return list;
};

exports = {
    getLangByInitials,
    getLangOptionList,
    getLangculture
};