import * as React from 'react';
import "./campaignCard.scss";
import { BgImg } from '../bgImg/bgImg';
import { Icon, Icons } from "../icon/icon";
import utilsUI from '../../core/utilsUI';

export interface ICampaignCardProps {
    title: string
    content: string
    img: {
        urls: string[]
        title?: string
    }
    theme?: string
    url: string,
    isFeature?: boolean,
    isExternal?: boolean,
    isLarge?: boolean
    mods?: string
};

export const CampaignCardThemes = ["campaign-card--purple", "campaign-card--red", "campaign-card--blue", "campaign-card--green", "campaign-card--orange"];

export const CampaignCard: React.FC<ICampaignCardProps> = ({ title, content, img, theme, url, isFeature, isExternal, isLarge, mods }) => {

    if (!img || !title || !content) return null;
    
    return (
        <a href={url} className={
            "campaign-card"
            + (theme && !isFeature ? ` campaign-card--${theme}` : "")
            + (isFeature ? " campaign-card--feature" : "")
            + (isLarge ? " campaign-card--large" : "")
            + (mods ? ` ${mods}` : "")
        }
            target={isExternal ? "_blank" : null} >
            <div className="campaign-card__img">
                <BgImg {...img} mods="bg-img--absolute" />
            </div>
            <div className="campaign-card__lower">
                {isLarge ? <h4>{title}</h4> : <h4 className="h5">{utilsUI.truncateWords(title, (isLarge ? 20 : 10))}</h4>}
                <div className="campaign-card__content">
                    <p>{utilsUI.truncateWords(content, (isLarge ? 20 : 10))}</p>
                </div>
                <div className="campaign-card__icon">
                    <span><Icon icon={Icons.Chevron} /></span>
                </div>
            </div>
        </a>
    );

}