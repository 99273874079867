export const ButtonTitles = {
    english: {
        action: "Take Action",
        load: "Load More",
        read: "Read More",
        download: "Download"
    },
    russian: {
        action: "Действуйте",
        load: "Загрузить еще",
        read: "Читать далее",
        download: "Скачать"
    },
    chinese: {
        action: "行动起来",
        load: "下载更多",
        read: "阅读更多",
        download: "下载"
    },
    arabic: {
        action: "بادر للتغيير",
        load: "استعراض المزيد",
        read: "لقراءة المزيد",
        download: "تحميل"
    },
    french: {
        action: "Passez à l’action",
        load: "Afficher plus",
        read: "En savoir plus",
        download: "Télécharger"
    },
    portuguese: {
        action: "Tome uma atitude",
        load: "Carregar mais",
        read: "Saiba mais",
        download: "Baixar"
    },
    spanish: {
        action: "Qué hacer",
        load: "Ver más",
        read: "Leer más",
        download: "Descargar"
    }
};

exports = {
    ButtonTitles
};