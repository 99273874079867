import * as React from 'react';
import "./termBlock.scss";

export interface ITermBlockProps {
    title: string
    content: string
};

export const TermBlock: React.FC<ITermBlockProps> = ({ title, content }) => {

    if(!title || !content) return <React.Fragment></React.Fragment>
    return <div className="term-block">
        <h4>{title}</h4>
        <p>{content}</p>
    </div>

}