import * as React from 'react';
import "./eNewsForm.scss";

const url = "https://gmail.us20.list-manage.com/subscribe/post?u=e006b038f55a5ed0f4bf95d6b&amp;id=3d0e5a9214";

const messages = {
    english: {
        description: "Stand up for equal rights & fair treatment for Lesbian, Gay, Bi, Trans & Intersex people everywhere",
        disclaimer: "By submitting your email address above you agree to the Free & Equal terms of use and privacy policy.",
        submit: "Subscribe"
    },
    russian: {
        description: "Отстаивать равные права и справедливое отношение к лесбиянкам, геям, трансгендерам и интерсекс людям повсюду",
        disclaimer: "bпредоставляя свой электронной адрес в форме выше, вы соглашаетесь с условиями использования и политикой конфиденциальности веб-сайта кампании «свободные и равные»",
        submit: "ПОДПИСАТЬСЯ"
    },
    chinese: {
        description: "支持平等权利及公平对待为世界上所有的男女同性恋，双性恋，跨性别及间性人",
        disclaimer: "提交你的上述电子邮件地址意味着你同意自由与平等运动的使用规定和隐私政策."
    },
    arabic: {
        description: "أيد وادعم حقوق متساوية ومعاملة عادل للأشخاص المثليين والمثليات ومزدوجي التوجه الجنسي ومغايري الهوية الجنسانية وثنائيي الجنس في كل مكان",
        disclaimer: "بإعطائك عنوان بريدك الإلكتروني أعلاه توافق على شروط الاستخدام وسياسة الخصوصية المعمول بها في مبادرة ”أحرار متساوون“.",
        submit: "الاشتراك"
    },
    french: {
        description: "défendez l’égalité des droits et le traitement équitable pour les personnes Lesbiennes, Gay, Bi, Trans et Intersex partout",
        disclaimer: "En saisissant votre adresse électronique ci-dessus, vous acceptez les conditions d’utilisation et la politique de confidentialité de la campagne « libres et égaux ».",
        submit: "S’Inscrire"
    },
    portuguese: {
        description: "Levante-se pela igualdade de direitos e tratamento justo para Lésbicas, Gays, Bissexuais, Pessoas Trans e Intersex",
        disclaimer: "Ao inscrever seu endereço de email acima, você concorda com os termos de uso & política de privacidade da livres & iguais.",
        submit: "Subscribe"
    },
    spanish: {
        description: "Defiende igualdad de derechos y trato justo por las personas Lesbians, Gays, Bi, Trans y Intersex personas en todas partes",
        disclaimer: "Al proporcionarnos tu correo electrónico aceptas las condiciones de utilización y la política de privacidad de libres e iguales.",
        submit: "Unete"
    }
};

interface IENewsForm {
    contentItems?: {
        description: string
        disclaimer: string
        submit?: string
    }[]
};


export const ENewsForm: React.FC<IENewsForm> = ({ contentItems }) => {

    if (!contentItems || contentItems.length == 0) return <React.Fragment></React.Fragment>

    const formUnstyled = `
    <!-- Begin Mailchimp Signup Form -->
    <div id="mc_embed_signup" style="width:100%;">
    <form action="${url}" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll" style="width:100%;">
        
    <div class="mc-field-group enews-form__row">
        <label for="mce-FNAME">First Name </label>
        <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" placeholder="First name">
    </div>
    <div class="mc-field-group enews-form__row">
        <label for="mce-EMAIL">Email Address </label>
        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Email address">
    </div>
    <div class="mc-field-group enews-form__row">
        <label for="mce-COUNTRY">Country </label>
        <input type="text" value="" name="COUNTRY" class="required" id="mce-COUNTRY" placeholder="Country">
    </div>

        <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_e006b038f55a5ed0f4bf95d6b_3d0e5a9214" tabindex="-1" value=""></div>
        
        <div class="enews-form__disclaimer">
                        <p>By submitting your email address above you agree to the Free & Equal terms of use and privacy policy.</p>
                    </div>
        
        <div class="clear" style="text-align:center;"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button btn"></div>
        </div>
    </form>
    </div>
    
    <!--End mc_embed_signup-->
`;

    return (
        <React.Fragment>
            <div className="enews-form enews-form--spacing-bottom">
                <div className="enews-form__inner">
                    <div className="enews-form__wrapper">
                        <div className="enews-form__intro">
                            <p>{contentItems[0].description}</p>
                        </div>
                        <form action={url} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
                            <div className="enews-form__row">
                                <label htmlFor="enews-form__name" className="sr-only"></label>
                                <input type="text" id="enews-form__name" placeholder="First name" name="FNAME" />
                            </div>
                            <div className="enews-form__row">
                                <label htmlFor="enews-form__email" className="sr-only"></label>
                                <input type="text" id="enews-form__email" placeholder="Email address" name="EMAIL" />
                            </div>
                            <div className="enews-form__row">
                                <label htmlFor="enews-form__country" className="sr-only"></label>
                                <input type="text" id="enews-form__country" placeholder="Country" name="COUNTRY" />
                            </div>

                            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                                <input type="text" name="b_e006b038f55a5ed0f4bf95d6b_3d0e5a9214" tabIndex={-1} />
                            </div>

                            <div className="enews-form__disclaimer">
                                <p>{contentItems[0].disclaimer}</p>
                            </div>
                            <div className="enews-form__row enews-form__row--submit">
                                <button type="submit" className="btn">{contentItems[0].submit ?? "Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <div className="enews-form enews-form--spacing-bottom">
                <div className="enews-form__inner">
                    <div className="enews-form__wrapper">
                        <div className="enews-form__intro">
                            <p>{contentItems[0].description}</p>
                        </div>


                        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: formUnstyled }} />



                    </div>
                </div>
            </div> */}
        </React.Fragment>
    );

}