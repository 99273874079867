import gql from "graphql-tag";

export const MAP_COUNTRY_FRAGMENT =gql`
 fragment mapCountryFragment on mapCountry {
  content
  img {
    urls
  }
  subTitle
  country {
    contentItems {
      ... on country {
        name
        locations {
          termContentItemIds
        }
      }
    }
  }
}

 
`;
