import { dictionaryManager } from '../../core/storageManagers';
import * as React from 'react';
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";
import { BackgroundColours } from '../componentContainer/componentContainer';

interface ISectionIntroGroupProps {
    contentItems: {
        sectionIntro: ISectionIntroProps,
        removeTopSpacing: boolean,
        removeBottomSpacing: boolean,
        mods?: BackgroundColours,
        link?: { url?: string, text?: string, contentItemIds?: string[] }
    }[]
}

export const SectionIntroGroup: React.FC<ISectionIntroGroupProps> = ({ contentItems }) => {

    if (!contentItems?.length) return null;

    const items = contentItems.map((item) => {

        var cta = { url: item.link?.url, text: item.link?.text };
        if (item.link?.contentItemIds?.length) {
            let page = dictionaryManager.getItemById(item.link.contentItemIds[0]);
            cta = { url: page["path"], text: page["title"] }
        }

        return {
            ...item.sectionIntro,
            link: cta,
            removeTopSpacing: item.removeTopSpacing,
            removeBottomSpacing: item.removeBottomSpacing,
            mods: item.mods
        };
    });

    if (!items) return <React.Fragment></React.Fragment>

    return <React.Fragment>
        {items.map((item, index) => {
            if (item != null) return <SectionIntro {...item} key={index} />
        })}
    </React.Fragment>

}