import * as React from 'react';
import "./cardGrid.scss";
import { ComponentList } from "../../controllers/componentList";
import { IDownloadCardProps } from "../downloadCard/downloadCard";
import { IContentCardProps } from "../contentCard/contentCard";
import { ISectionIntroProps, SectionIntro } from "../sectionIntro/sectionIntro";

export enum CARD_GRID_SPACING {
    TOP = "card-grid--spacing-top",
    BOTTOM = "card-grid--spacing-bottom",
    NONE = "card-grid--no-spacing",
};

interface ICardGridProps {
    intro?: ISectionIntroProps
    compName: string
    contentItems?: {
        page: {
            contentItems: {
                contentItemId
                contentType
                contentItem
            }[]
        }
    }[]
    items?: IDownloadCardProps[] | IContentCardProps[]
    spacing?: CARD_GRID_SPACING,
    isFullWidth?: boolean
    colCount?: number
};

export const CardGrid: React.FC<ICardGridProps> = ({ intro, compName, contentItems, items, colCount, isFullWidth, spacing }) => {

    spacing = CARD_GRID_SPACING.NONE;

    const outputItems = () => {

        if(contentItems) {
            return contentItems.filter(card => card.page?.contentItems?.length > 0).map((card, index) => {
                if (card.page?.contentItems[0] != null) {
                    const Comp = ComponentList[compName];
                    return <div className="card-grid__item" key={index}>
                        <Comp
                            queryData={{
                                contentItemId: card.page?.contentItems[0].contentItemId,
                                contentType: card.page?.contentItems[0].contentType,
                                contentItem: card.page?.contentItems[0].contentItem,
                            }}
                        />
                    </div>
                }
            })
        }else {
            return (items as Array<IDownloadCardProps | IContentCardProps>)?.map((item, index) => {
                if (item != null) {
                    const Comp = ComponentList[compName];
                    return <div className="card-grid__item" key={index}>
                        {compName == "downloadCard" ? <Comp {...item} /> : <Comp cardProps={item} />}
                    </div>
                }
            })

        }

    }

    const getCols = () => {

        switch(colCount){

            case 2:
                return " card-grid--two-col";

            case 3:
                return " card-grid--three-col"
                
            case 0:
            case 1:
            default:
                return "";
        }

    }

    return <div className={"card-grid" 
        + getCols() 
        + (isFullWidth ? " card-grid--full-width" : "")
        + (spacing ? ` ${spacing}` : "")
    }>
        {intro ? <SectionIntro
            {...intro}
            removeTopSpacing
        /> : null}
        <div className="card-grid__inner">
            <div className="card-grid__wrapper">
                {outputItems()}
            </div>
        </div>
    </div>

}