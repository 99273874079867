import * as React from 'react';
import "./socialContentCol.scss";
import { Social } from "../social/social";
import { FeatureContent } from "../featureContent/featureContent";

interface ISocialContentColProps {
    contentItems: {
        label?: string
        title?: string
        content: string
        links?: {
            contentItems: {
                url: string
                text: string
            }
        }[]
    }[]
}

export const SocialContentCol: React.FC<ISocialContentColProps> = ({ contentItems }) => {

    if(!contentItems) return <React.Fragment></React.Fragment> 
    if(contentItems.length ==0) return <React.Fragment></React.Fragment>

    return <div className="component-wrapper">
        <div className="component-wrapper__inner">
            <div className="social-content-col">
                <div className="social-content-col__icons">
                    {/* <Social items={[]} /> */}
                </div>
                <div className="social-content-col__content">
                    <FeatureContent
                        {...contentItems[0]}
                    />
                </div>
            </div>
        </div>
    </div>

}