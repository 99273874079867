import gql from "graphql-tag";

export const  SIMPLE_CONTENT_GRID_FRAGMENT =gql`
fragment simpleContentGridFragment on simpleContentGrid {
    sectionIntro {
      content
      title
    }
    toolbar{
      share
      file
      {
          urls
      }
    }
    items {
      contentItems {
        ... on simpleContentCard {
            content
            title
        }
      }
    }
  }`;