import gql from "graphql-tag";

export const SIMPLE_ACCORDION_FRAGMENT = gql`
fragment simpleAccordionFragment on simpleAccordion {
  contentItemId
  title: displayText
  content
}`;

export const ACCORDION_FRAGMENT = gql`
fragment accordionFragment on accordion {
  contentItemId
  title: displayText
  content
  items {
    contentItems {
      ... on campaignCard {
        page: campaign {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
      ... on articleCard {
        page: article {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
      ... on pageCard {
        page {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
      ... on factSheetCard {
        page: factSheet {
          contentItems {
            contentItemId
            contentType
          }
        }
      }
    }
  }
}`;