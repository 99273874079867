import "intersection-observer";
import "babel-polyfill";
import "isomorphic-fetch";
import * as React from 'react';
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import UtilsUI from "./core/utilsUI";
import { Page } from "./controllers/page";
import { checkToken, setToken, launchApollo } from "./core/token";
import { ErrorViewController } from "./controllers/errorViewController/errorViewController";

import TagManager from 'react-gtm-module'; 
const tagManagerArgs = {
    gtmId: process.env.GTM_ID
} 
TagManager.initialize(tagManagerArgs);


var client;

const App = () => {

    if (client) {
        return (
            <ApolloProvider client={client}>
                <Router>
                    <Switch>
                        <Route path="/auth/">
                            <div></div>
                        </Route>
                        <Route path="/deauth/">
                            <div></div>
                        </Route>
                        <Route path="/delete/">
                            <div></div>
                        </Route>
                        <Route path="/error-cms">
                            <ErrorViewController title="Network error" message="An error occurred in contacting this site. We're working on fixing this issue. Please try again later." />
                        </Route>
                        <Route path="**/updates/(.+)">
                            <Page type="article" />
                        </Route>
                        <Route path="**/what-we-do/our-campaigns/(.+)" >
                            <Page type="campaign" />
                        </Route>
                        <Route path="**/know-the-facts/challenges-solutions/(.+)" >
                            <Page type="factSheet" />
                        </Route>
                        <Route path="*">
                            <Page type="page" />
                        </Route>
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }

    return <div></div>

}

function initReactDOM() {
    if (UtilsUI.checkForIE11()) document.body.classList.add('ie-11');
    ReactDOM.render(<App />, document.getElementById("root"));
    /*
        GTM Code
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-W49TRXC');
    */
}

window.onload = () => {
    client = launchApollo();
    initReactDOM();
}