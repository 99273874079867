import gql from "graphql-tag";

export const GENERAL_CONTENT_PAGE = gql`
query GeneralContentComponents($id: ID, $status: Status){
  pageGeneralContent(where: {contentItemId: $id}, status: $status) {
    displayText
    contentType
    contentItemId
    contentBanner: pageMetaData{
      title
      subTitle:description
      label
    }
    wysiwyg: htmlBody {
      html
    }
    subscriptionBlock {
      displaySubscription
    }
  }
}

`;  

export const GENERAL_CONTENT_META_DATA = gql`
query GeneralContentMetaData($id: ID, $status: Status){
  page: pageGeneralContent(where: {contentItemId: $id}, status: $status) {
    contentItemId
    pageMetaData{
      title
      description
      label
      keywords
      thumbnail{
        urls
      }
    }
  }
}

`;
