import * as React from 'react';
import "./navItem.scss";
import { Icon, Icons } from "../../components/icon/icon";
import { useLocation } from 'react-router-dom';
import utilsUI from "../../core/utilsUI";
import { SiteDataContext } from '../../contexts/siteData';

interface INavItemProps {
    id: string
    title: string
    url: string
    parent?: string
    isCurrent?: boolean
    nav?: {
        id: string
        title: string
        url: string
        parent?: string
        isCurrent?: boolean
    }[]
};

export const NavItem: React.FC<INavItemProps> = ({ id, title, url, parent, isCurrent, nav }) => {

    const location = useLocation();
    const [isActive, toggleActive] = React.useState(false);
    const urlPartitions = utilsUI.getUrlPartitions(location.pathname);
    const {state} = React.useContext(SiteDataContext);

    if(!title || !url) return <React.Fragment></React.Fragment>
    return <li className={"nav-item" + (isCurrent ? " current-item" : "")} role="none">
        <a href={utilsUI.getUrlWithRegion(state.language.initials, url)} role="menuitem">{title}</a>
        {nav && nav.length ? 
        
        <React.Fragment>
            <button
                role="none"
                aria-haspopup="true"
                aria-label="Expand sub menu button"
                aria-controls={id + "-sub-menu"}
                id={id + "-sub-btn"}
                className={(isActive ? "sub-active" : "")}
                onClick={() => { toggleActive(!isActive) }}
            >
                <Icon icon={Icons.Chevron} />
            </button>
            <ul role="menu" id={id + "-sub-menu"} aria-labelledby={id + "-sub-btn"}>
                {
                    nav.map((item, index) => {
                        let path = item['content']['contentItems'][0]['path'];
                        if (!path.endsWith('/'))
                            path = `${path}/`;
                        const subUrl = utilsUI.cleanPath(path);
                        return <NavItem
                            key={index}
                            id={item['content']['contentItemIds'][0]}
                            url={(subUrl) ? `${parent}/${subUrl}` : `${parent}`}
                            title={item['displayText']}
                            nav={item['subNav'] ? item['subNav']['contentItems'] : false}
                            parent={`${parent}/${subUrl}`}
                            isCurrent={urlPartitions[urlPartitions.length - 1] == subUrl || urlPartitions.includes(subUrl) ? true : false}
                        />
                    })
                }
            </ul>
        </React.Fragment> : null}
    </li>

}