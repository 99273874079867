import * as React from 'react';
import { DefinitionItem } from "../definitionItem/definitionItem";
import { CardGrid, CARD_GRID_SPACING } from "../cardGrid/cardGrid";
import { SiteDataContext } from '../../contexts/siteData';

interface IDefinitionGroupProps {
    contentItems: {
        contentItemId: string
        title: string
        content: string
        items?: {
            contentItems: {
                page: {
                    contentItems: {
                        contentItemId
                        contentType
                        contentItem
                    }[]
                }
            }[]
        }
    }[]
}

export const DefinitionItemGroup: React.FC<IDefinitionGroupProps> = ({ contentItems }) => {
    const { state } = React.useContext(SiteDataContext);
    const letterRegex = /[\p{Letter}\p{Mark}]+/gu;

    let itemsForSort = contentItems.map((element, index) => ({...element, titleOnlyLetters: contentItems[index].title.match(letterRegex).toString()}))

    // taking language from the page data
    // then sort on that base
    itemsForSort.sort(function(a, b) {
        return Intl.Collator(state.language.initials).compare(a.titleOnlyLetters, b.titleOnlyLetters)
    });

    if (!contentItems) return <React.Fragment></React.Fragment>
    return <div className="component-wrapper">
        {itemsForSort.map((item, index) => {
                {
                    // console.log(item.items.contentItems)
                }
            return <DefinitionItem {...item} key={index}>
                {item.items?.contentItems.length ? <CardGrid
                    compName="contentCard"
                    spacing={CARD_GRID_SPACING.NONE}
                    isFullWidth={true}
                    contentItems={...item.items?.itemsForSort}
                /> : null}
            </DefinitionItem>
        })}
    </div>

}