import * as React from 'react';
import "./sideBar.scss";
import { Social } from "../social/social";
import { ScrollDirection, useScrollDirection } from '../../hooks/use-scroll-direction';
import { SiteDataContext } from "../../contexts/siteData";
import { useLocation, useRouteMatch } from 'react-router-dom';
import utilsUI from "../../core/utilsUI";
import { ButtonTitles } from "../../language/button";

export const SideBar: React.FC<{social: any}> = ({social}) => {

    // No action button for act now page
    const location = useLocation();
    const urlPartitions = utilsUI.getUrlPartitions(location.pathname);
    const lastPath = urlPartitions[urlPartitions.length - 1];
    const isActNowPage = lastPath === "act-now";
    const isCampaignPage = useRouteMatch("**/what-we-do/our-campaigns/(.+)");

    const { state } = React.useContext(SiteDataContext);
    const scrollDirection = useScrollDirection({ thresholdPixels: 20 });
    const [isVisible, toggleVisibility] = React.useState(false);
    const sideBar = React.useRef<HTMLDivElement>();

    const startClickAnimation = (e: React.MouseEvent) => {

        const actionUrl = state.language.initials == "en" ? "/act-now" : `/${state.language.initials}/act-now`;
        e.currentTarget.classList.add('active');

        setTimeout(() => {
            window.location.href = actionUrl;
        }, 1000);

    }

    const focusItem = () => {
        toggleVisibility(true);
    }

    const blurItem = () => {
        toggleVisibility(false);
    }

    if (isCampaignPage) return null;

    return <div
        className={"side-bar tab-region-toggle" + ((scrollDirection === ScrollDirection.Down) ? "" : " hide") + (isVisible ? " focus" : "")}
        ref={sideBar}
    >
        <Social
            mods="social--vertical"
            onFocusHandler={focusItem}
            onBlurHandler={blurItem}
            items={social}
        />
        {!isActNowPage && <div className="side-bar__action">
            <button
                onClick={(e) => { startClickAnimation(e) }}
                className={"side-bar__btn"}
                onFocus={() => { focusItem() }}
                onBlur={() => { blurItem() }}
            >
                {ButtonTitles[state.language.value].action}
            </button>
        </div>}
    </div>


}