import * as React from 'react';
import { CardGroup } from "../cardGroup/cardGroup";
import { ImgTitleContentCard } from "../imgTitleContentCard/imgTitleContentCard";
import { BackgroundColours } from '../componentContainer/componentContainer';

interface IImgTitleGroupProps {
    contentItems: {
        sectionIntro?: {
            title: string
            content?: string
            className?: string
        },
        removeTopSpacing?: boolean, // TODO: do we need mods and removeBottomSpacing?
        removeBottomSpacing?: boolean, // TODO: do we need mods and removeBottomSpacing?
        items:
        {
            contentItems: {
                page: {
                    contentItems: {
                        contentItemId
                        contentType
                    }[]
                }
            }[]
        }
    }[],
    colour?: string
};


export const ImgTitleContentCardGroup: React.FC<IImgTitleGroupProps> = ({ contentItems, colour }) => {

    if (!contentItems?.length) return null;

    const item = contentItems[0];
    if (!item.items?.contentItems?.length) return null;

    const items = item.items.contentItems.map(card => card.page?.contentItems[0]).filter(x => !!x);
    if (!items) return null;

    let mods = BackgroundColours.Grey;
    if (colour == "transparent")
        mods = BackgroundColours.Transparent;

    return (
        <CardGroup
            intro={item.sectionIntro}
            removeTopSpacing={item.removeTopSpacing}
            removeBottomSpacing={item.removeBottomSpacing}
            mods={mods}
        >
            {items.map((item, index) => <ImgTitleContentCard key={index} {...item} />)}
        </CardGroup>
    );
}
