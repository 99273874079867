import gql from "graphql-tag";

export const  IMG_CONTENT_CARD_GRID_FRAGMENT =gql`
fragment imageContentCardGridFragment  on imageContentCardGrid {
  items {
    contentItems {
      ... on imageContent {
        thumbnail {
          urls
        }
        title
        content
      }
    }
  }
  sectionIntro {
    content
    title
  }
}`;