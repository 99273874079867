import gql from "graphql-tag";
import { SIMPLE_ACCORDION_GROUP_FRAGMENT } from "./accordion-group-fragment";
import { FIGURES_BLOCK_FRAGMENT } from "./figures-block-fragment";
import { IMAGE_GALLERY_CAROUSEL_FRAGMENT } from "./gallery-fragment";
import { MEDIA_DOWNLOAD_CAROUSEL_FRAGMENT } from "./media-download-fragment";
import { RELATED_CAMPAIGNS_FRAGMENT } from "./related-campaigns-fragment";

export const CAMPAIGN_LIST = gql`
query CampaignList($parameters: String) {
  items :campaignList(parameters: $parameters) {
    contentItemId
    contentType
    title: displayText
    date
    content: description
    isFeature
    thumbnail{
      urls
    }
    path
    external: externalContent {
      url: externalUrl
      isExternal
    }
  }
}
`;

export const CAMPAIGN_META_DATA = gql`
query CampaignMetaData($id: ID,  $status: Status) {
  page:campaign(where: {contentItemId: $id}, status :$status) {
    contentItemId
    title: displayText
    description
    path
    keywords
    external: externalContent {
      url: externalUrl
      isExternal
    }
    thumbnail {
      urls
    }
  }
}
`;

export const CAMPAIGN_QUERY = gql`
query Campaign($slug :String, $status: Status) {
  campaign(where: {path: $slug}, status: $status){
    contentItemId
    title: displayText
    date
    description
    path
    keywords
    isFeature
    external: externalContent {
      url: externalUrl
      isExternal
    }
    thumbnail {
      urls
    }
    tags{
      termContentItemIds
    }
    locations {
      termContentItemIds
    }
    components {
      contentItems {
        contentItemId
        ... on youtubeVideo {
          img {
            urls
          }
          videoId
          mods
          removeBottomSpacing
          removeTopSpacing

        }
        ... on content {
          html
          mods
          removeBottomSpacing
          removeTopSpacing
        }
        ... on heroImage {
          img {
            urls
          }
        }
        ... figuresBlockFragment
        ... simpleAccordionGroupFragment
        ... mediaDownloadCarouselFragment
        ... relatedCampaignsFragment
        ... galleryCarouselFragment 

      }
    }
    subscriptionBlock {
      displaySubscription
    }
  }
}
${FIGURES_BLOCK_FRAGMENT}
${MEDIA_DOWNLOAD_CAROUSEL_FRAGMENT}
${SIMPLE_ACCORDION_GROUP_FRAGMENT}
${RELATED_CAMPAIGNS_FRAGMENT}
${IMAGE_GALLERY_CAROUSEL_FRAGMENT}
`;
