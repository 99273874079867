import * as React from 'react';
import "./contentCard.scss";
import { Icon, Icons } from "../icon/icon";
import { useContentQuery } from "../../hooks/use-content-query";

interface IPageCardProps {
    queryData?: {
        contentType: string
        contentItemId: string
        contentItem: any
    }
    cardProps?: IContentCardProps
};

export interface IContentCardProps {
    title: string
    content: string
    label?: string
    link: {
        url: string
        text?: string
    }
    hasBorder?: boolean
};

const PageContentCard: React.FC<IContentCardProps> = ({ title, content, label, link, hasBorder }) => {

    return <div className={"content-card" + (hasBorder ? ` content-card--no-label` : "")}>
        <a href={link.url} title={link.text ? link.text : title}>
            {label ? <h6>{label}</h6> : ""}
            <h4>{title}</h4>
            <p>{content}</p>
            <div className="content-card__icon">
                <Icon icon={Icons.Chevron} />
            </div>
        </a>
    </div>

}

export const ContentCard: React.FC<IPageCardProps> = ({ queryData, cardProps }) => {

    if (queryData?.contentItem) {
        let ci = queryData.contentItem;

        return <PageContentCard
            title={ci.title}
            content={ci.content}
            label={ci.label}
            link={{ url: ci.url }}
            hasBorder={true}
        />;
    }
    else if (queryData) {
        if(!queryData.contentItemId || !queryData.contentType) return <React.Fragment></React.Fragment>
        const contentQuery = useContentQuery(queryData.contentItemId, queryData.contentType);
        if (contentQuery == null) return <React.Fragment></React.Fragment>;
        return <PageContentCard
            title={contentQuery['title']}
            content={contentQuery['content']}
            label={contentQuery['label']}
            link={{ url: contentQuery['url'] }}
            hasBorder={true}
        />

    } else if(cardProps) {
        return <PageContentCard
            {...cardProps}
        />
    }

    return <React.Fragment></React.Fragment>
    

}
