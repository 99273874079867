import * as React from 'react';
import { CardGrid } from "../cardGrid/cardGrid";
import { ISectionIntroProps } from "../sectionIntro/sectionIntro";
import { IDownloadCardProps } from "../downloadCard/downloadCard";

interface IDownloadGridProps {
    contentItems: {
        items: {
            contentItems: IDownloadCardProps[]
        }
        sectionIntro: ISectionIntroProps
    }[] 
};

export const DownloadGrid: React.FC<IDownloadGridProps> = ({ contentItems }) => {

    const buildCardData = () => {
        let cards = [];
        contentItems[0].items?.contentItems.map((item) => {
            cards.push({...item});
        })
        return cards;

    }

    if(!contentItems || contentItems.length == 0) return <React.Fragment></React.Fragment>
    
    const cardData = buildCardData();

    return <CardGrid
        compName={"downloadCard"}
        intro={contentItems[0].sectionIntro}
        items={cardData}
        colCount={3}
    />

}